import { DeviceType } from '../meters/meter-types'

/**
 * Alarm 'enum' indicating too big flow
 */
export const TOO_BIG_FLOW: AlarmType = {
  alarmKey: 'tooBigFlow',
  translationKey: 'too_big_flow',
  enabledKey: 'tooBigFlowEnabled',
  companyAlarmType: 'TOO_BIG_FLOW'
}

/**
 * Alarm 'enum' indicating too backward flow
 */
export const BACKWARD_FLOW: AlarmType = {
  alarmKey: 'backwardFlow',
  translationKey: 'backward_flow',
  enabledKey: 'backwardFlowEnabled',
  companyAlarmType: 'BACKWARD_FLOW'
}

/**
 * Alarm 'enum' indicating magnetic fraud
 */
export const MAGNETIC_FRAUD: AlarmType = {
  alarmKey: 'magneticFraud',
  translationKey: 'magnetic_fraud',
  enabledKey: 'magneticFraudEnabled',
  companyAlarmType: 'MAGNETIC_FRAUD'
}

/**
 * Alarm 'enum' indicating optical fraud
 */
export const OPTICAL_FRAUD: AlarmType = {
  alarmKey: 'opticalFraud',
  translationKey: 'optical_fraud',
  enabledKey: 'opticalFraudEnabled',
  companyAlarmType: 'OPTICAL_FRAUD'
}

/**
 * Alarm 'enum' indicating module has been removed
 */
export const MODULE_REMOVED: AlarmType = {
  alarmKey: 'moduleRemoved',
  translationKey: 'module_removed',
  enabledKey: 'moduleRemovedEnabled',
  companyAlarmType: 'MODULE_REMOVED'
}

/**
 * Alarm 'enum' indicating battery warning
 */
export const BATTERY_WARNING: AlarmType = {
  alarmKey: 'batteryWarning',
  translationKey: 'battery_warning',
  enabledKey: 'batteryWarningEnabled',
  companyAlarmType: 'BATTERY_WARNING'
}

/**
 * Alarm 'enum' indicating leakage
 */
export const LEAKAGE: AlarmType = {
  alarmKey: 'leakage',
  translationKey: 'leakage',
  enabledKey: 'leakageEnabled',
  companyAlarmType: 'LEAKAGE'
}

/**
 * Alarm 'enum' indicating hardware error
 */
export const HARDWARE_ERROR: AlarmType = {
  alarmKey: 'hardwareError',
  translationKey: 'hardware_error',
  enabledKey: 'hardwareErrorEnabled',
  companyAlarmType: 'HARDWARE_ERROR'
}

/**
 * Alarm 'enum' indicating switch error
 */
export const SWITCH_ERROR: AlarmType = {
  alarmKey: 'switchError',
  translationKey: 'switch_error',
  enabledKey: 'switchErrorEnabled',
  companyAlarmType: 'SWITCH_ERROR'
}

/**
 * Alarm 'enum' indicating fraud detected
 */
export const FRAUD_DETECTED: AlarmType = {
  alarmKey: 'fraudDetected',
  translationKey: 'fraud_detected',
  enabledKey: 'fraudDetectedEnabled',
  companyAlarmType: 'FRAUD_DETECTED'
}

/**
 * Alarm 'enum' indicating reset detected
 */
export const RESET_DETECTED: AlarmType = {
  alarmKey: 'resetDetected',
  translationKey: 'reset_detected',
  enabledKey: 'resetDetectedEnabled',
  companyAlarmType: 'RESET_DETECTED'
}

/**
 * Alarm 'enum' indicating flowLimit surpassed
 */
export const FLOW_LIMIT: AlarmType = {
  alarmKey: 'flowLimit',
  translationKey: 'flow_limit',
  enabledKey: 'flowLimitEnabled',
  companyAlarmType: 'FLOW_LIMIT'
}

/**
 * Alarm 'enum' indicating temperature alarm detected
 */
export const TEMPERATURE: AlarmType = {
  alarmKey: 'temperature',
  translationKey: 'temperature',
  enabledKey: 'temperatureEnabled',
  companyAlarmType: 'TEMPERATURE'
}

/**
 * Alarm 'enum' indicating freeze detected
 */
export const FREEZE_DETECTED: AlarmType = {
  alarmKey: 'freezeDetected',
  translationKey: 'freeze_detected',
  enabledKey: 'freezeDetectedEnabled',
  companyAlarmType: 'FREEZE_DETECTED'
}

/**
 * Alarm 'enum' indicating empty tube detected
 */
export const EMPTY_TUBE_DETECTED: AlarmType = {
  alarmKey: 'emptyTubeDetected',
  translationKey: 'empty_tube_detected',
  enabledKey: 'emptyTubeDetectedEnabled',
  companyAlarmType: 'EMPTY_TUBE_DETECTED'
}

/**
 * Record of all alarm types by alarm key
 */
export const AlarmTypes: Record<AlarmKey, AlarmType> = {
  tooBigFlow: TOO_BIG_FLOW,
  backwardFlow: BACKWARD_FLOW,
  magneticFraud: MAGNETIC_FRAUD,
  opticalFraud: OPTICAL_FRAUD,
  moduleRemoved: MODULE_REMOVED,
  batteryWarning: BATTERY_WARNING,
  leakage: LEAKAGE,
  hardwareError: HARDWARE_ERROR,
  switchError: SWITCH_ERROR,
  fraudDetected: FRAUD_DETECTED,
  resetDetected: RESET_DETECTED,
  flowLimit: FLOW_LIMIT,
  temperature: TEMPERATURE,
  freezeDetected: FREEZE_DETECTED,
  emptyTubeDetected: EMPTY_TUBE_DETECTED
}

/**
 *  alarm type 'enum'
 *  @property alarmKey - key used for this alarm type
 *  @property enabledKey - key used in alarm settings for this alarm type
 *  @property translationKey - key to find translations for this alarm type
 *  @property companyAlarmType - key that backend understands when setting company alarms
 */
export interface AlarmType {
  alarmKey: AlarmKey
  enabledKey: SettingKey
  translationKey: string
  companyAlarmType: CompanyAlarmKey
}

/**
 * key used in alarm settings for this alarm type
 */
export type SettingKey =
    'tooBigFlowEnabled' |
    'backwardFlowEnabled' |
    'magneticFraudEnabled' |
    'opticalFraudEnabled' |
    'moduleRemovedEnabled' |
    'batteryWarningEnabled' |
    'leakageEnabled' |
    'hardwareErrorEnabled' |
    'switchErrorEnabled' |
    'fraudDetectedEnabled' |
    'resetDetectedEnabled' |
    'flowLimitEnabled' |
    'temperatureEnabled' |
    'freezeDetectedEnabled' |
    'emptyTubeDetectedEnabled'

/**
 * key used in loraStatus for this alarm type
 */
export type AlarmKey =
    'tooBigFlow' |
    'backwardFlow' |
    'magneticFraud' |
    'opticalFraud' |
    'moduleRemoved' |
    'batteryWarning' |
    'leakage' |
    'hardwareError' |
    'switchError' |
    'fraudDetected' |
    'resetDetected' |
    'flowLimit' |
    'temperature' |
    'freezeDetected' |
    'emptyTubeDetected'

/**
 * key used in backend alarm settings fiddling endpoint for this alarm type
 */
export type CompanyAlarmKey =
    'TOO_BIG_FLOW' |
    'BACKWARD_FLOW' |
    'MAGNETIC_FRAUD' |
    'OPTICAL_FRAUD' |
    'MODULE_REMOVED' |
    'BATTERY_WARNING' |
    'LEAKAGE' |
    'HARDWARE_ERROR' |
    'SWITCH_ERROR' |
    'FLOW_LIMIT' |
    'FRAUD_DETECTED' |
    'RESET_DETECTED' |
    'TEMPERATURE' |
    'FREEZE_DETECTED' |
    'EMPTY_TUBE_DETECTED'

/**
 * @returns translation key for description of this alarm
 */
export const getDescTranslationKey = (alarmType: AlarmType): string => {
  return ('alarm_description.' + alarmType.translationKey)
}

/**
 * @returns translation key for name of this alarm
 */
export const getNameTranslationKey = (alarmType: AlarmType): string => {
  return ('alarm_types.' + alarmType.translationKey)
}

/**
 * Map of device type to list of it's supported alarms
 */
export const supportedAlarmsByType = new Map<DeviceType, AlarmType[]>([
  [
    DeviceType.ADEUNIS,
    [
      BATTERY_WARNING,
      SWITCH_ERROR,
      HARDWARE_ERROR,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.ADEUNIS_v3,
    [
      BATTERY_WARNING,
      SWITCH_ERROR,
      HARDWARE_ERROR,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.BMETERS_COMBO,
    [
      TOO_BIG_FLOW,
      BACKWARD_FLOW,
      BATTERY_WARNING,
      LEAKAGE,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.BMETERS_LORA_PULSE,
    [
      FRAUD_DETECTED,
      RESET_DETECTED,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.HYDROSONIC_M1,
    [
      FLOW_LIMIT,
      MODULE_REMOVED,
      BATTERY_WARNING,
      LEAKAGE,
      FRAUD_DETECTED,
      TOO_BIG_FLOW,
      BACKWARD_FLOW,
      RESET_DETECTED,
      FREEZE_DETECTED,
      EMPTY_TUBE_DETECTED
    ]
  ],
  [
    DeviceType.BMETERS_v2,
    [
      MAGNETIC_FRAUD,
      MODULE_REMOVED,
      OPTICAL_FRAUD,
      LEAKAGE,
      BACKWARD_FLOW,
      BATTERY_WARNING,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.LORA,
    [
      TOO_BIG_FLOW,
      BACKWARD_FLOW,
      MAGNETIC_FRAUD,
      OPTICAL_FRAUD,
      MODULE_REMOVED,
      BATTERY_WARNING,
      LEAKAGE,
      FLOW_LIMIT
    ]
  ],
  [
    DeviceType.SIGFOX,
    [
      FLOW_LIMIT
    ]
  ]
])

/**
 * Used to get AlarmType by it's key
 * @param key alarmKey
 * @returns AlarmType matching the AlarmKey
 */
export const getAlarmTypeByKey = (key: string): AlarmType => {
  return AlarmTypes[key]
}

/**
 * Used to check if string is an alarm key
 * @param key maybe AlarmKey
 * @returns boolean if key is alarmKey
 */
export const isAlarmKey = (key: string): boolean => {
  return AlarmTypes[key] !== undefined
}
