/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type AnyAction } from 'redux'
import { minimumLength } from '../../components/common/search-bar/SearchBar'
import { searchMeters } from '../../utils/meter-utils'
import {
  AddActiveFilterAction,
  AddActiveLabelAction,
  CustomerFilterChangedAction,
  MetersReceivedAction,
  NewMeterValuesUpdateSuccessAction,
  RemoveActiveFilterAction,
  RemoveActiveLabelFilterAction,
  SearchMetersDataAction,
  SearchStringChangedAction,
  SetAlarmsFilterAction,
  UpdateLabelFilterChangedAction
} from '../meter-table/meter-table-actions'
import { type Meter } from '../meters/meter-types'
import { GetStoredDataAction } from './data-actions'
import { type DataState } from './data-types'

const initialState: DataState = {
  meters: [],
  alarmsFilter: false,
  customerFilter: 'all',
  idFilter: '',
  filteredMeters: [],
  values: {},
  selectionData: [{}],
  activeFilters: [],
  searchText: '',
  labelFilters: []
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function updateFiltered(
  meters: Meter[],
  alarmsFilter: boolean,
  customerFilterId: string,
  labelFilters: string[],
  idFilter: string,
  searchText: string) {
  let updatedMeters = JSON.parse(JSON.stringify(meters))

  if (alarmsFilter) {
    updatedMeters = updatedMeters.filter(meter =>
      meter.leakageWarningActive === true ||
      meter.temperatureAlertActive === true ||
      (meter.isLora && meter.latestLoraStatus !== null &&
        Object.values(meter.latestLoraStatus).filter(e => !!e).length > 0)
    )
  }
  if (customerFilterId !== 'all') {
    updatedMeters = updatedMeters.filter(c => {
      return c.company && c.company.id === customerFilterId
    })
  }

  if (labelFilters.length > 0) {
    updatedMeters = updatedMeters.filter(meter =>
      meter.labels.some((label: string) => labelFilters.includes(label))) // Meters type does not have "labels" array
  }

  if (idFilter !== null && idFilter !== undefined) {
    updatedMeters = updatedMeters.filter(meter =>
      meter.sigfoxId.toLowerCase().includes(idFilter.toLowerCase()))
  }
  if (searchText.length >= minimumLength) {
    return searchMeters(searchText, updatedMeters)
  }
  return updatedMeters
}

export default function dataReducer(state: DataState = initialState, action: AnyAction): DataState {
  if (GetStoredDataAction.match(action)) {
    return {
      ...state,
      values: action.payload.data
    }
  }

  if (RemoveActiveLabelFilterAction.match(action)) {
    const newLabelFilters = state.labelFilters.filter((i) => i !== action.payload.data)
    return {
      ...state,
      labelFilters: newLabelFilters
    }
  }
  if (AddActiveLabelAction.match(action)) {
    return {
      ...state,
      labelFilters: [...state.labelFilters, action.payload.data]
    }
  }
  if (RemoveActiveFilterAction.match(action)) {
    const newFilters = state.activeFilters.filter((i) => i !== action.payload.data)
    return {
      ...state,
      activeFilters: newFilters
    }
  }
  if (AddActiveFilterAction.match(action)) {
    return {
      ...state,
      activeFilters: [...state.activeFilters, action.payload.data]
    }
  }

  if (SetAlarmsFilterAction.match(action)) {
    const filtered = updateFiltered(state.meters, action.payload.data, state.customerFilter, state.labelFilters, state.idFilter, state.searchText)
    return {
      ...state,
      filteredMeters: filtered,
      alarmsFilter: action.payload.data
    }
  }

  if (SearchStringChangedAction.match(action)) {
    return {
      ...state,
      searchText: action.payload
    }
  }

  if (SearchMetersDataAction.match(action)) {
    const filtered = updateFiltered(state.meters, state.alarmsFilter, state.customerFilter, state.labelFilters, state.idFilter, action.payload)
    return {
      ...state,
      filteredMeters: filtered
    }
  }

  if (CustomerFilterChangedAction.match(action)) {
    const filtered = updateFiltered(state.meters, state.alarmsFilter, action.payload.customer.id, state.labelFilters, state.idFilter, state.searchText)
    return {
      ...state,
      filteredMeters: filtered,
      customerFilter: action.payload.customer.id
    }
  }

  if (UpdateLabelFilterChangedAction.match(action)) {
    // This is not taking anything from the payload. Is this purposly like this?
    const filtered = updateFiltered(state.meters, state.alarmsFilter, state.customerFilter, state.labelFilters, state.idFilter, state.searchText)
    return {
      ...state,
      filteredMeters: filtered
    }
  }

  if (MetersReceivedAction.match(action)) {
    const filtered = updateFiltered(action.payload.meters, state.alarmsFilter, state.customerFilter, state.labelFilters, state.idFilter, state.searchText)

    return {
      ...state,
      meters: action.payload.meters,
      filteredMeters: filtered
    }
  }

  if (NewMeterValuesUpdateSuccessAction.match(action)) {
    const updated = state.meters.map((m) => {
      if (m.id === action.payload.id) {
        return action.payload
      }
      return m
    })
    const filtered = updateFiltered(updated, state.alarmsFilter, state.customerFilter, state.labelFilters, state.idFilter, state.searchText)

    return {
      ...state,
      meters: updated,
      filteredMeters: filtered
    }
  }
  return state
}
