import { AppBar, Chip, TableCell } from '@mui/material'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const SharedInputStyle = css`
    border: 1px solid #6C7C9C;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
`
const SharedSettingsButtonStyle = css`
    padding: 10px 20px;
    font-weight: 600;
    border-radius : 5px;
    margin: 16px 0;
    &:hover {
        cursor: pointer;
        filter: brightness(120%);
    }
`
export const StyledAppBar = styled(AppBar)`
    background: #D2DDEC;
`
export const StyledTitle = styled.p`
    color: #12263F;
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px
`
export const StyledLabel = styled(Chip)`
    margin: 0 5px 0 0;
    background-color: #6e84a3;
    color: #333333;
    filter: brightness(140%);
    font-family: Roboto;
    font-weight: 600;
    max-width: 80px;
`
export const StyledLabelFullWidth = styled(Chip)`
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #6e84a3;
    color: #333333;
    filter: brightness(140%);
    font-family: Roboto;
    font-weight: 600;
`
export const StyledSettingsTableCell = styled(TableCell)`
    color: #6e84a3;
    font-weight: 600;
`
export const StyledFieldsText = styled.p`
    color: #12263f;
    font-weight: 600;
    font-size: 12px;
`
export const StyledBaseReading = styled.p`
    font-size: 10px;
    color: #12263F;
    padding-left: 16px;
`
export const StyledBaseReadingTextField = styled.input`
    ${SharedInputStyle}
    max-width: 120px;
    max-height: 25px;
`
export const StyledLabelSettingField = styled.input`
    ${SharedInputStyle}
    width: 290px;
    height: 90px;
`
export const SettingsGeneralButton = styled.button`
    ${SharedSettingsButtonStyle}
    color: #2c7be5;
    background: #fff;
    border: 1px solid #2c7be5;
`
export const SettingsGeneralDisabledButton = styled.button`
    ${SharedSettingsButtonStyle}
    color: rgba(0, 0, 0, 0.38);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.38);
    pointer-events: none;
`
export const SettingsSaveButton = styled.button`
    ${SharedSettingsButtonStyle}
    color: #fff;
    background: #2c7be5;
    border: none;
`
export const SettingsDeleteButton = styled.button`
    ${SharedSettingsButtonStyle}
    color: #eb4034;
    background: #fff;
    border: 1px solid #eb4034;
`
export const SettingsTabTableCell = styled(TableCell)`
    color: #12263f;
    font-size: 10px;
`
