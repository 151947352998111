import moment from 'moment'
import { API_URL } from '../constants/urls'
import {
  type CompanyAlarm,
  type DownloadFileForAdminPayload,
  type DownloadFileForUserPayload,
  type DownloadFileWithinSpecificDatesForAdminPayload,
  type DownloadFileWithinSpecificDatesPayload,
  type SetCompanyAlarmBody
} from '../redux/companies/companies-types'
import { FLOW_LIMIT } from '../redux/alarm-settings/alarm-types'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class CompaniesApi {
  // jscpd:ignore-start
  static async downloadFileForUser(idToken: string, payload: DownloadFileForUserPayload): Promise<string | { error: string } > {
    const formattedDate = moment(payload.downloadDateSelected).format('YYYY-MM-DD')
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await fetch(`${API_URL}/company/downloadForUser/${formattedDate}?type=${payload.downloadType}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      })
      return await response.text()
    } catch (error: any) {
      return ({ error: error.message })
    }
  }
  // jscpd:ignore-end

  // jscpd:ignore-start
  static async downloadFileWithinSpecificDates(idToken: string, payload: DownloadFileWithinSpecificDatesPayload): Promise<string | { error: string }> {
    const formattedStartDate = moment(payload.startDateSelected).format('YYYY-MM-DD')
    const formattedEndDate = moment(payload.endDateSelected).format('YYYY-MM-DD')
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await fetch(`${API_URL}/company/downloadForUser/${formattedStartDate}/${formattedEndDate}?type=${payload.downloadType}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      })
      return await response.text()
    } catch (error: any) {
      return ({ error: error.message })
    }
  }
  // jscpd:ignore-end

  // jscpd:ignore-start
  static async downloadFileWithinSpecificDatesForAdmin(idToken: string, payload: DownloadFileWithinSpecificDatesForAdminPayload): Promise<string | { error: string }> {
    const formattedStartDate = moment(payload.startDateSelected).format('YYYY-MM-DD')
    const formattedEndDate = moment(payload.endDateSelected).format('YYYY-MM-DD')
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await fetch(`${API_URL}/company/${payload.companyId}/downloadForUserAdmin/${formattedStartDate}/${formattedEndDate}?type=${payload.downloadType}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      })
      return await response.text()
    } catch (error: any) {
      return ({ error: error.message })
    }
  }
  // jscpd:ignore-end

  // jscpd:ignore-start
  static async downloadFileForAdminUser(idToken: string, payload: DownloadFileForAdminPayload): Promise<string | { error: string }> {
    const formattedDate = moment(payload.downloadDateSelected).format('YYYY-MM-DD')
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await fetch(`${API_URL}/company/${payload.companyId}/downloadForUserAdmin/${formattedDate}?type=${payload.downloadType}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      })
      return await response.text()
    } catch (error: any) {
      return ({ error: error.message })
    }
  }
  // jscpd:ignore-end

  static async setCompanyAlarm(idToken: string, payload: CompanyAlarm): Promise<string | { error: string }> {
    const companyKey = payload.alarmType?.companyAlarmType || null

    let body: SetCompanyAlarmBody = {
      alarmType: companyKey,
      state: payload.state
    }

    if (payload.alarmType === FLOW_LIMIT && payload.maxFlowPerHour !== undefined) {
      body = {
        ...body,
        maxFlowPerHour: payload.maxFlowPerHour
      }
    }

    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await fetch(`${API_URL}/company/${payload.companyId}/set_alarm`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      })
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`)
      }
      return await response.text()
    } catch (error: any) {
      return ({ error: error.message })
    }
  }
}
