import React from 'react'
import { Navigate } from 'react-router'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'

interface AuthorizedProps {
  isAuthenticated: boolean
  isAssignedToCompany: boolean
  isAdmin: boolean
  requireIsAuthenticated: boolean
  requireIsAssignedToCompany: boolean
  requireIsAdmin: boolean
}

interface ConnectedAuthorizedProps {
  requireIsAuthenticated: boolean
  requireIsAssignedToCompany: boolean
  requireIsAdmin: boolean
}

export function Authorized({ props, child }: { props: AuthorizedProps, child: JSX.Element }): JSX.Element {
  if (props.requireIsAuthenticated && !props.isAuthenticated) return <Navigate to='/login' />
  if (props.requireIsAssignedToCompany && !props.isAssignedToCompany) return <Navigate to='/landing' />
  if (props.requireIsAdmin && !props.isAdmin) return <Navigate to='/login' /> // TODO: Unauthorized component
  return child
}

export default function ConnectedAuthorized({ props, child }: { props: ConnectedAuthorizedProps, child: JSX.Element }): JSX.Element {
  const {
    isAuthenticated,
    isAssignedToCompany,
    isAdmin
  } = useSelector((state: RootState) => state.vesimittari.auth)

  return <Authorized props={{ ...props, isAuthenticated, isAssignedToCompany, isAdmin }} child={child} />
}
