import styled from '@emotion/styled'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { IconButton, Table, TableBody, TableRow } from '@mui/material'

export const StyledTableHead = styled(TableHead)`
    margin-top: 1rem;
    background-color: #f9fbfd;
`

export const StyledTableRow = styled(TableRow)`
  display: grid;
  grid-template-columns: repeat(9, 1fr)
`

export const StyledTableHeadCell = styled(TableCell)`
  color: #969696;
  font-family: Roboto;
  display: flex;
  align-items: left;
  justify-content: left;
`
export const StyledTableTitle = styled.p`
    padding-right: 5px;
    color: #6e84a3;
    font-size: 10pt;
    font-weight: 600;
`
export const StyledTable = styled(Table)`
  display:grid;
  justify-content: left;
  align-items: left;
`
export const StyledTableBody = styled(TableBody)`
  border: 1px solid #EFF0F3;
  display: grid;
`
export const StyledSortButtonsContainer = styled.div`
  display: grid;
`
export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: #6e84a3;
    &:hover {
      cursor: pointer;
    }
`
