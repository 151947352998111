import { call, put, select, takeLatest } from 'redux-saga/effects'
import Api from '../../api/api'
import { type FetchAlarmSettingsForDevicePayload } from './alarm-settings-types'
import { AlarmSettingsForDeviceResponseAction, FetchAlarmSettingsForDeviceAction } from './alarm-settings-actions'
import selectIdToken from '../../redux/selectors/select-id-token'
import { OpenErrorAction } from '../error/error-actions'

export function * fetchAlarmSettingsForDevice(action: { payload: FetchAlarmSettingsForDevicePayload }) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.AlarmSettingsApi.fetchAlarmSettings, idToken, action.payload)

  if (response.error) {
    yield put(OpenErrorAction(response.error))
  } else {
    yield put(AlarmSettingsForDeviceResponseAction(response))
  }
}

export default function * alarmSettingsSagas() {
  yield takeLatest(FetchAlarmSettingsForDeviceAction, fetchAlarmSettingsForDevice)
}
