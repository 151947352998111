import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { type RootState } from '../../../interfaces/RootState'
import { type Notice } from '../../../redux/notices/notices-types'
import { MarkNoticeAsReadAction } from '../../../redux/notices/notices-actions'
import { useTranslation } from 'react-i18next'

interface Props {
  unreadNotice: Notice | undefined
}

function UnreadNotice({ unreadNotice }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const markAsRead = (id: string) => {
    dispatch(MarkNoticeAsReadAction(id))
  }

  if (!unreadNotice) {
    return <></>
  }

  return <Dialog
    fullWidth
    maxWidth="md"
    open={true}
  >
    <DialogTitle>
      <b>{t('notices.unreadNotice')}</b>
    </DialogTitle>

    <DialogContent>
      <p style={{ whiteSpace: 'pre' }}>{unreadNotice.contents}</p>
    </DialogContent>

    <DialogActions style={{ justifyContent: 'space-between' }}>
      <Typography style={{ color: 'gray' }}>
        {t('notices.created')} {new Date(unreadNotice.created).toLocaleString()}
      </Typography>
      <Button
        variant='contained'
        onClick={() => { markAsRead(unreadNotice.id) }}
      >
        <Typography>{t('notices.markAsRead')}</Typography>
      </Button>
    </DialogActions>
  </Dialog>
}

export default function ConnectedUnreadNotice(): JSX.Element {
  const notices = useSelector((state: RootState): Notice[] => state.vesimittari.notices.notices)

  return <UnreadNotice unreadNotice={notices.find((notice) => !notice.read)} />
}
