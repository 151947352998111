import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { type Message } from '../../../redux/messages/messages-types'
import { type RootState } from '../../../interfaces/RootState'
import { type VesimittariDispatch } from '../../../configureStore'
import { AcknowledgeMessageAction } from '../../../redux/messages/messages-actions'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Toasts = ({ messages, dispatch }: { messages: Message[], dispatch: VesimittariDispatch }): JSX.Element => {
  const t = useTranslation().t
  return (
    <React.Fragment>
      {messages.map(m => {
        return (
          <Snackbar
            open={true}
            key={m.id}
            autoHideDuration={10000}
            onClose={() => { dispatch(AcknowledgeMessageAction({ id: m.id })) }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Alert onClose={() => { dispatch(AcknowledgeMessageAction({ id: m.id })) }} severity={m.type} sx={{ width: '100%' }}>
              {t(m.message)}
            </Alert>
          </Snackbar>)
      })}
    </React.Fragment>
  )
}

export default function ConnectedToasts(): JSX.Element {
  const { messages } = useSelector((state: RootState) => state.vesimittari.messagesReducer)
  const dispatch = useDispatch()
  return (<Toasts messages={messages} dispatch={dispatch} />)
}
