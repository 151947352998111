import { createAction } from '@reduxjs/toolkit'
import { type FetchUserSuccessPayload, type FetchUserFailurePayload, type LockSuccessPayload } from './auth-types'
import {
  LOCK_SUCCESS,
  LOGOUT_SUCCESS,
  FETCHING_USER_INFORMATION,
  USER_RECEIVED,
  USER_RECEIVED_FAILURE
} from '../../constants/action-types'

export const LockSuccessAction = createAction<LockSuccessPayload>(LOCK_SUCCESS)
export const LogoutSuccessAction = createAction<void>(LOGOUT_SUCCESS)
export const FetchingUserInformationAction = createAction<void>(FETCHING_USER_INFORMATION)
export const UserReceivedAction = createAction<FetchUserSuccessPayload>(USER_RECEIVED)
export const UserReceivedFailureAction = createAction<FetchUserFailurePayload>(USER_RECEIVED_FAILURE)
