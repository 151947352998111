import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyledChip, ActiveFiltersContainer } from './Filters.style'
import { type RootState } from '../../../interfaces/RootState'
import ClearIcon from '@mui/icons-material/Clear'
import { UpdateLabelFilterChangedAction, RemoveActiveLabelFilterAction } from '../../../redux/meter-table/meter-table-actions'

export interface ActiveLabel {
  label: string
}
const ActiveLabelFilters = () => {
  const dispatch = useDispatch()

  const data = useSelector((state: RootState): any[] => state.vesimittari.data.labelFilters)

  const handleDelete = (filter: ActiveLabel) => {
    dispatch(RemoveActiveLabelFilterAction({ data: filter }))
    dispatch(UpdateLabelFilterChangedAction({ filter }))
  }

  return (
    <ActiveFiltersContainer>
      {data.map((i) => (
        <StyledChip deleteIcon={<ClearIcon/>} key={i.index} label={i} onDelete={() => { handleDelete(i) }} />
      ))}
    </ActiveFiltersContainer>)
}

export default ActiveLabelFilters
