import { createAction } from '@reduxjs/toolkit'
import {
  type AddDownlinkMessageSuccessPayload,
  type AddDownlinkMessageFailPayload,
  type AddDownlinkMessagePayload,
  type FetchDownlinkMessagesForDevicePayload,
  type FetchDownlinkMessagesForDeviceFailPayload,
  type FetchDownlinkMessagesForDeviceSuccessPayload,
  type CancelDownlinkMessagePayload,
  type CancelDownlinkMessageSuccessPayload,
  type CancelDownlinkMessageFailPayload
} from './downlink-messages-types'
import {
  ADD_DOWNLINK_MESSAGE,
  ADD_DOWNLINK_MESSAGE_SUCCESS,
  ADD_DOWNLINK_MESSAGE_FAILED,
  CANCEL_DOWNLINK_MESSAGE,
  CANCEL_DOWNLINK_MESSAGE_SUCCESS,
  CANCEL_DOWNLINK_MESSAGE_FAILED,
  FETCH_DOWNLINK_MESSAGES_FOR_DEVICE,
  FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS,
  FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL
} from '../../constants/action-types'

export const AddDownlinkMessageAction = createAction<AddDownlinkMessagePayload>(ADD_DOWNLINK_MESSAGE)
export const AddDownlinkMessageSuccessAction = createAction<AddDownlinkMessageSuccessPayload>(ADD_DOWNLINK_MESSAGE_SUCCESS)
export const AddDownlinkMessageFailedAction = createAction<AddDownlinkMessageFailPayload>(ADD_DOWNLINK_MESSAGE_FAILED)
export const CancelDownlinkMessageAction = createAction<CancelDownlinkMessagePayload>(CANCEL_DOWNLINK_MESSAGE)
export const CancelDownlinkMessageSuccessAction = createAction<CancelDownlinkMessageSuccessPayload>(CANCEL_DOWNLINK_MESSAGE_SUCCESS)
export const CancelDownlinkMessageFailedAction = createAction<CancelDownlinkMessageFailPayload>(CANCEL_DOWNLINK_MESSAGE_FAILED)
export const FetchDownlinkMessagesForDeviceAction = createAction<FetchDownlinkMessagesForDevicePayload>(FETCH_DOWNLINK_MESSAGES_FOR_DEVICE)
export const FetchDownlinkMessagesForDeviceFailAction = createAction<FetchDownlinkMessagesForDeviceFailPayload>(FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL)
export const FetchDownlinkMessagesForDeviceSuccessAction = createAction<FetchDownlinkMessagesForDeviceSuccessPayload>(FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS)
