import { type AddDownlinkMessagePayload, type FetchDownlinkMessagesForDevicePayload, type CancelDownlinkMessagePayload } from '../redux/downlink-messages/downlink-messages-types'
import { API_URL } from '../constants/urls'

export default class DownlinkMessagesApi {
  static async addDownlinkMessage<T>(idToken: string, payload: AddDownlinkMessagePayload): Promise<T> {
    return await fetch(`${API_URL}/downlink_messages/addDownlinkMessage`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async cancelDownlinkMessage<T>(idToken: string, payload: CancelDownlinkMessagePayload): Promise<T> {
    return await fetch(`${API_URL}/downlink_messages/cancelDownlinkMessage`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async fetchDownlinkMessagesForDevice<T>(idToken: string, payload: FetchDownlinkMessagesForDevicePayload): Promise<T> {
    return await fetch(`${API_URL}/downlink_messages/messagesForDevice/${payload.deviceId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }
}
