import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import createRootReducer from './redux'
import rootSaga from './redux/root-saga'
import { createPersistenceMiddleware } from './persistence-middleware'
import { configureStore } from '@reduxjs/toolkit'
import type { AnyAction, Middleware } from '@reduxjs/toolkit'
import type { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import type { RootState } from './interfaces/RootState'

const loggerMiddleware = createLogger()

const sagaMiddleware = createSagaMiddleware()

const persistenceMiddleware: Middleware<unknown, RootState> = createPersistenceMiddleware()

const configureStoreWithMiddleware = (): ToolkitStore<RootState, AnyAction, any[]> => {
  const middleware = [
    thunkMiddleware,
    persistenceMiddleware,
    sagaMiddleware,
    loggerMiddleware
  ]

  const store = configureStore({
    reducer: createRootReducer(),
    middleware,
    devTools: process.env.NODE_ENV !== 'production'
  })

  sagaMiddleware.run(rootSaga)

  return store
}

const store = configureStoreWithMiddleware()

export default function getStore(): ToolkitStore<RootState, AnyAction, any[]> {
  return store
}

export type VesimittariStore = typeof store

export type VesimittariDispatch = typeof store.dispatch
