import Fuse from 'fuse.js'

const fuseOptions = {
  keys: ['deviceSerialNumber', 'devEUI', 'serialNumber'],
  ignoreLocation: true,
  threshold: 0
}

export function searchDevicesForProvision (devicesForProvision, query) {
  if (query.trim() === '') {
    return devicesForProvision
  }

  const ret = new Fuse(devicesForProvision, fuseOptions).search(query)
  return ret.map((r) => r.item)
}
