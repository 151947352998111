import { type AnyAction } from 'redux'
import { sortGroups, updateMetersByGroup } from '../../utils/settings-utils'
import { FetchMetersAction, FetchMetersFailureAction, MetersReceivedAction } from '../meter-table/meter-table-actions'
import { AddEmailRecipientAction, AddSMSRecipientAction, ChangeEmailIntervalAction, ChangeSMSIntervalAction, FetchCustomerAlarmsAction, FetchCustomerAlarmsFailAction, FetchCustomerAlarmsSuccessAction, FetchGroupsAction, FetchGroupsFailAction, GroupsReceivedAction, RemoveEmailRecipientAction, RemoveSMSRecipientAction, SaveCustomerAlarmsAction, SaveCustomerAlarmsFailedAction, SaveCustomerAlarmsSuccessAction, ToggleEnableEmailAction, ToggleSMSEnabledAction, ToggleTemperatureAlertsEnabledAction, UpdateSMSRecipientTextBoxAction, UpdateTemperatureAlertLowerLimitAction, UpdateTemperatureAlertUpperLimitAction } from './setting-actions'
import { type SettingsState } from './settings-type'
import { stateWithLoadingFalse, stateWithLoadingTrue } from '../../utils/utils'

const initialState: SettingsState = {
  loading: 0,
  settingsTab: 0,
  addDialogOpen: false,
  addGroupForm: {
    name: '',
    companyId: null
  },
  groups: [],
  mainMeterByGroup: {},
  meters: [],
  alarmSettingsCustomer: null,
  smsRecipientToAdd: '',
  alarmSettingsDirty: false,
  alarmSettings: {
    emailEnabled: false,
    emailInterval: 1,
    emailRecipients: [],
    smsEnabled: false,
    smsInterval: 1,
    smsRecipients: [],
    temperatureAlertsEnabled: false,
    temperatureAlertLowerLimit: 0,
    temperatureAlertUpperLimit: 90
  }
}

export default function settingsReducer(state: SettingsState = initialState, action: AnyAction): SettingsState {
  if (FetchMetersAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (MetersReceivedAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      meters: action.payload.meters,
      mainMeterByGroup: updateMetersByGroup(action.payload.meters, state.groups)
    }
  }

  if (FetchMetersFailureAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (FetchGroupsAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (GroupsReceivedAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      groups: sortGroups(action.payload.groups),
      mainMeterByGroup: updateMetersByGroup(state.meters, action.payload.groups)
    }
  }

  if (FetchGroupsFailAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (ToggleEnableEmailAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        emailEnabled: !action.payload
      }
    }
  }

  if (FetchCustomerAlarmsAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (FetchCustomerAlarmsSuccessAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      alarmSettingsDirty: false,
      alarmSettings: action.payload.data,
      alarmSettingsCustomer: action.payload.id
    }
  }

  if (FetchCustomerAlarmsFailAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (SaveCustomerAlarmsAction.match(action)) {
    return state
  }

  if (SaveCustomerAlarmsSuccessAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: false,
      alarmSettings: action.payload.data,
      alarmSettingsCustomer: action.payload.id
    }
  }

  if (SaveCustomerAlarmsFailedAction.match(action)) {
    return state
  }

  if (AddEmailRecipientAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        emailRecipients: [...state.alarmSettings.emailRecipients, action.payload]
      }
    }
  }

  if (RemoveEmailRecipientAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        emailRecipients: state.alarmSettings.emailRecipients.filter(val => { return val !== action.payload })
      }
    }
  }

  if (ChangeEmailIntervalAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        emailInterval: action.payload
      }
    }
  }

  if (ToggleSMSEnabledAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        smsEnabled: !action.payload
      }
    }
  }

  if (ToggleTemperatureAlertsEnabledAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        temperatureAlertsEnabled: !action.payload
      }
    }
  }

  if (UpdateTemperatureAlertLowerLimitAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        temperatureAlertLowerLimit: action.payload
      }
    }
  }

  if (UpdateTemperatureAlertUpperLimitAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        temperatureAlertUpperLimit: action.payload
      }
    }
  }

  if (AddSMSRecipientAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        smsRecipients: [...state.alarmSettings.smsRecipients, action.payload]
      }
    }
  }

  if (RemoveSMSRecipientAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        smsRecipients: state.alarmSettings.smsRecipients.filter(val => { return val !== action.payload })
      }
    }
  }

  if (UpdateSMSRecipientTextBoxAction.match(action)) {
    return {
      ...state,
      smsRecipientToAdd: action.payload
    }
  }

  if (ChangeSMSIntervalAction.match(action)) {
    return {
      ...state,
      alarmSettingsDirty: true,
      alarmSettings: {
        ...state.alarmSettings,
        smsInterval: action.payload
      }
    }
  }
  return state
}
