import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../assets/koka_logo.png'
import Button from '@mui/material/Button'
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { errorAlert } from '../../../redux/error/error-utils'
import { type RootState } from '../../../interfaces/RootState'
import Authorized from '../../common/authorized/Authorized'

import { Navigate } from 'react-router-dom'
import { FetchingUserInformationAction, LogoutSuccessAction } from '../../../redux/auth/auth-actions'
import { useTranslation } from 'react-i18next'

interface LandingProps {
  username: string
  isFetchingUserInformation: boolean
  isAssignedToCompany: boolean
  errorMessage: string | null
}

function Landing({ username, isFetchingUserInformation, isAssignedToCompany, errorMessage }: LandingProps): JSX.Element {
  const dispatch = useDispatch()

  const t = useTranslation().t

  useEffect(() => {
    if (errorMessage !== null) {
      errorAlert(
        errorMessage,
        '',
        dispatch
      )
    }
  }, [dispatch, errorMessage])

  return (
    <React.Fragment>
      {!isAssignedToCompany && (
        <div className="Aligner">
          <div className="Aligner-item--top" />
          <div className="Aligner-item">{isFetchingUserInformation
            ? <div style={{ textAlign: 'center' }}><CircularProgress /></div>
            : <Card>
              <CardHeader
                title={t('landing.welcome')}
                subtitle={username}
              />
              <CardContent style={{ textAlign: 'center' }}>
                <img src={logo} style={{ width: '300px' }} alt="logo" />
                <p>{t('landing.info')}</p>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(FetchingUserInformationAction())}>
                  <Typography>{t('landing.try_login')}</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => dispatch(LogoutSuccessAction())}>
                  <Typography>{t('landing.logout')}</Typography>
                </Button>
              </CardActions>
            </Card>}
          </div>
          <div className="Aligner-item--bottom" />
        </div>
      )}
      {isAssignedToCompany && (
        <Navigate to='/dashboard/meters' />
      )}
    </React.Fragment>
  )
}

export default function ConnectedLanding(): JSX.Element {
  const username = useSelector((state: RootState): string => state.vesimittari.auth.username)
  const isFetchingUserInformation = useSelector((state: RootState): boolean => state.vesimittari.auth.isFetchingUserInformation)
  const isAssignedToCompany = useSelector((state: RootState): boolean => state.vesimittari.auth.isAssignedToCompany)
  const errorMessage = useSelector((state: RootState): string | null => state.vesimittari.auth.errorMessage)

  return (
    <Authorized props={{ requireIsAdmin: false, requireIsAssignedToCompany: false, requireIsAuthenticated: true }} child={
      <Landing
        username={username}
        isAssignedToCompany={isAssignedToCompany}
        isFetchingUserInformation={isFetchingUserInformation}
        errorMessage={errorMessage} />} />
  )
}
