import { type Meter } from '../redux/meters/meter-types'
import { isAlarmKey } from '../redux/alarm-settings/alarm-types'

export enum SortType {
  ByName,
  ByReading,
  ByFirstDataEntry,
  ByUpdatedAt,
  ByLocation,
  ByAlarmStatus,
}
export enum SortOrder {
  Ascending,
  Descending,
}

export interface Sort {
  type: SortType
  order: SortOrder
}

// used to force exhaustive checking of enum
function unreachable(_v: never, meters: Meter[]): Meter[] {
  return [...meters]
}

function sortByProperty(data: Meter[], property: string): Meter[] {
  return [...data].sort((a, b) => {
    return b[property] - a[property]
  })
}

function sortByStringProperty(data: Meter[], property: string): Meter[] {
  return [...data].sort((a, b) => {
    const aProp = a[property] ?? ''
    const bProp = b[property] ?? ''

    return bProp.localeCompare(aProp)
  })
}

/**
 * Count active alarms for a meter
 * @param meter
 * @returns amount of active alarms
 */
function getActiveAlarmsCount(meter: Meter): number {
  let numberOfAlarms = 0
  if (meter.latestLoraStatus !== undefined) {
    for (const [key, value] of Object.entries(meter.latestLoraStatus)) {
      if (value === true && isAlarmKey(key)) {
        numberOfAlarms++
      }
    }
  }

  if (meter.flowLimitAlertActive) {
    numberOfAlarms++
  }

  return numberOfAlarms
}

function sortByAlarmStatus(data: Meter[]): Meter[] {
  return [...data].sort((a, b) => {
    const aAlarms = getActiveAlarmsCount(a)
    const bAlarms = getActiveAlarmsCount(b)
    return aAlarms - bAlarms
  })
}

function getSortedByType(meters: Meter[], sortType: SortType): Meter[] {
  switch (sortType) {
    case SortType.ByName:
      return sortByStringProperty(meters, 'sigfoxId')
    case SortType.ByLocation:
      return sortByStringProperty(meters, 'location')
    case SortType.ByFirstDataEntry:
      return sortByProperty(meters, 'firstMeterDataEntry')
    case SortType.ByReading:
      return sortByProperty(meters, 'waterUsed')
    case SortType.ByUpdatedAt:
      return sortByProperty(meters, 'updatedAt')
    case SortType.ByAlarmStatus:
      return sortByAlarmStatus(meters)
    default:
      return unreachable(sortType, meters)
  }
}

export function sortFilteredMeters(meters: Meter[], sort: Sort): Meter[] {
  const metersToSort = [...meters]
  if (sort.order === SortOrder.Ascending) {
    return getSortedByType(metersToSort, sort.type)
  } else {
    return getSortedByType(metersToSort, sort.type).reverse()
  }
}
