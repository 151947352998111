import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Tooltip, Paper, type Theme } from '@mui/material'
import { StyledLabel } from '../meter-configuration/MeterConfiguration.style'
import { useTranslation } from 'react-i18next'

const useLabelsFieldStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: '0',
      minWidth: '200px',
      minHeight: '30px'
    },
    chip: {
      margin: theme.spacing(0.5)
    }
  }
})

export default function MetersTableLabelsField({ metersLabel, metersSettingDialog, labelVisibility }) {
  const { classes } = useLabelsFieldStyles()
  const t = useTranslation().t

  const toolTipTitle = t('main_meters.show_all_labels') ?? ''

  if (!metersLabel) { return (<Paper className={classes.root} style={labelVisibility} />) }

  return (
    <div className={classes.root} style={labelVisibility}>
      {metersLabel.slice(0, 2).map((label, index) => {
        return (
          <li key={index}>
            <StyledLabel label={label} className={classes.chip} />
          </li>
        )
      })}
      {metersLabel.length > 2
        ? (
        <Tooltip arrow title={toolTipTitle}>
          <StyledLabel label={`+${metersLabel.length - 2}`} style={{ backgroundColor: '#a7a7a7' }} onClick={metersSettingDialog} />
        </Tooltip>
          )
        : undefined}
    </div>
  )
}
