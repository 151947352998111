import { type AnyAction } from 'redux'
import { updateIntegration, searchCompanies, sortCompanies } from '../../utils/admin-utils'
import {
  FetchCompaniesAction,
  FetchCompaniesFailAction,
  FetchCompaniesSuccessAction,
  SearchCompaniesAction,
  UpdateIntegrationAction
} from './admin-actions'
import { type AdminState } from './admin-types'
import { stateWithLoadingFalse, stateWithLoadingTrue } from '../../utils/utils'

const initialState: AdminState = {
  loading: 0,
  companies: [],
  searchedCompanies: [],
  companiesFetching: false,
  userToRemove: null,
  searchText: ''
}

export default function adminReducer(state: AdminState = initialState, action: AnyAction): AdminState {
  if (FetchCompaniesAction.match(action)) {
    return {
      ...stateWithLoadingTrue(state),
      companiesFetching: true
    }
  }

  if (FetchCompaniesSuccessAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      companies: sortCompanies(action.payload.companies),
      searchedCompanies: searchCompanies(action.payload.companies, state.searchText)
    }
  }

  if (FetchCompaniesFailAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (SearchCompaniesAction.match(action)) {
    return {
      ...state,
      searchText: action.payload.searchText,
      searchedCompanies: searchCompanies(action.payload.searchCompanies, action.payload.searchText)
    }
  }

  if (UpdateIntegrationAction.match(action)) {
    return {
      ...state,
      companies: updateIntegration(state.companies, action.payload.companyId, action.payload.data)
    }
  }

  return state
}
