import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { RemoveMeterAction } from '../../../redux/meter-table/meter-table-actions'
import { SettingsDeleteButton } from './MeterConfiguration.style'

export function DeleteMeterButton({ id, dispatch }) {
  const t = useTranslation().t

  const deleteMeter = () => {
    const confirmText = t('settings_dialog.delete_confirmation_long')
    if (!confirm(confirmText)) {
      return
    }

    dispatch(RemoveMeterAction({ meterId: id }))
  }

  return (
    <SettingsDeleteButton
      onClick={deleteMeter}
    >
      {t('settings_dialog.delete')}
    </SettingsDeleteButton>
  )
}

export default function ConnectedDeleteMeterButton() {
  const dispatch = useDispatch()
  const newMeterValues = useSelector((state: RootState): any => state.vesimittari.meterReducer.newMeterValues)

  return <DeleteMeterButton id={newMeterValues.id} dispatch={dispatch} />
}
