import React, { useEffect, useRef, useState } from 'react'
import logo from '../../../assets/koka_logo.png'
import hydronet from '../../../assets/HYDRONET.png'
import { Button, Typography } from '@mui/material'
import '../../../assets/App.css'
import '../../../assets/Mobile.css'
import '../../../assets/index.css'
import { lockError } from '../../../redux/auth/auth'
import { REDIRECT_URL } from '../../../constants/urls'
import Auth0Lock from 'auth0-lock'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { AUTH0_CLIENT_ID, LOGIN_DOMAIN } from '../../../constants/auth'
import { type RootState } from '../../../interfaces/RootState'
import { FetchingUserInformationAction, LockSuccessAction } from '../../../redux/auth/auth-actions'
import Authorized from '../../common/authorized/Authorized'
import { useTranslation } from 'react-i18next'

interface AuthResult {
  idToken: string
  accessToken: string
}

interface Auth0LockInstance {
  show: () => void
  hide: () => void
}

export function useAuth0Lock(locale: string, onAuthenticated: (authResult: AuthResult) => void, onAuthorizationError: (err: string) => void): null | Auth0LockInstance {
  const callbacks = useRef({ onAuthenticated, onAuthorizationError })
  const [lock, setLock] = useState(null)

  useEffect(() => {
    const lock = new Auth0Lock(AUTH0_CLIENT_ID, LOGIN_DOMAIN, {
      language: locale,
      theme: {
        logo: hydronet,
        primaryColor: '#2C7BE5'
      },
      languageDictionary: {
        title: '',
        signUpTitle: ''
      },
      auth: {
        redirectUrl: REDIRECT_URL,
        responseType: 'token id_token',
        params: {
          scope: 'openid email app_metadata'
        }
      }
    })

    lock.on('authenticated', (authResult: AuthResult) => {
      callbacks.current.onAuthenticated(authResult)
    })

    lock.on('authorization_error', (err: string) => {
      callbacks.current.onAuthorizationError(err)
    })

    setLock(lock)
  }, [locale])

  useEffect(() => {
    callbacks.current = {
      onAuthenticated,
      onAuthorizationError
    }
  }, [onAuthenticated, onAuthorizationError])

  return lock
}

export function Login({ isAuthenticated, lock }: { isAuthenticated: boolean, lock: Auth0LockInstance | null }): JSX.Element {
  const t = useTranslation().t
  function onLoginClicked() {
    lock!.show()
  }

  if (isAuthenticated) {
    return <Navigate to='/landing' />
  }

  return (
    <div className="Aligner">
      <div className="Aligner-item Aligner-item--top" />
      <div className="Aligner-item">
        <div className="App">
          <div className="App-header">
            <img src={logo} className="App-logo" alt="logo" style={{ clear: 'both', display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '15px' }} />
            <img src={hydronet} className="App-logo-smaller" alt="hydronet_logo" style={{ clear: 'both', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
          </div>
          <div className="App-intro">
            <Button variant="contained" color="primary" onClick={onLoginClicked} data-testid="login_btn">
              <Typography color="inherit">{t('login')}</Typography>
            </Button>
            <br /><br />
          </div>
        </div>
      </div>
      <div className="Aligner-item Aligner-item--bottom" />
    </div>
  )
}

export default function ConnectedLogin(): JSX.Element {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState): boolean => state.vesimittari.auth.isAuthenticated)
  const language = useTranslation().i18n.language

  let lock: Auth0LockInstance | null = null

  function onAuthenticated(authResult: AuthResult): void {
    dispatch(LockSuccessAction({ token: authResult.idToken }))
    dispatch(FetchingUserInformationAction())

    setTimeout(() => {
      lock!.hide()
    }, 2000)
  }

  function onAuthorizationError(err: string): void {
    dispatch(lockError(err))
  }

  lock = useAuth0Lock(language, onAuthenticated, onAuthorizationError)

  return <Authorized props={{ requireIsAdmin: false, requireIsAssignedToCompany: false, requireIsAuthenticated: false }} child={<Login isAuthenticated={isAuthenticated} lock={lock} />} />
}
