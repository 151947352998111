import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, connect } from 'react-redux'
import MetersPagination from '../../common/pagination/MetersPagination'
import LoraLogo from '../../../assets/lora.png'
import SigfoxLogo from '../../../assets/sigfox.png'
import SigfoxNoTextLogo from '../../../assets/sigfox-no-text.png'
import CustomTableHead from './CustomTableHead'
import { StyledTable, StyledTableBody } from './MetersTable.style'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MetersTableRow from './meters-table-row/MetersTableRow'
import { paginateList, dateToLocaleOrEmpty, parseCubicMeters } from '../../../utils/utils'
import { type RootState } from '../../../interfaces/RootState'
import ConnectedMeterConfiguration from '../meter-configuration/MeterConfiguration'
import { ToggleMeterSettingsDialogAction } from '../../../redux/meters/meter-actions'
import { FetchDataArrayAction } from '../../../redux/meter-data/meter-data-actions'
import { type Sort, sortFilteredMeters, SortOrder, SortType } from '../../../utils/sortUtils'
import moment from 'moment'
import { FetchMetersAction, SetMeterActivePageAction, SetMeterPageSizeAction, UpdateOutOfDateMeterArrayAction } from '../../../redux/meter-table/meter-table-actions'
import { isPulseDevice, type Meter } from '../../../redux/meters/meter-types'

const MetersTable = ({ values, filter, meterLocations, meterActivePage, meterPageSize }: {
  dispatch: any
  values: any
  filter: any
  meterLocations: any
  meterActivePage: number
  meterPageSize: number
}): React.JSX.Element => {
  const [idVisible, setIdVisible] = useState(true)
  const [usageVisible, setUsageVisible] = useState(true)
  const [labelVisible, setLabelVisible] = useState(true)
  const [activeSort, setSort] = useState<Sort>({ type: SortType.ByName, order: SortOrder.Ascending })

  const sortedList = useMemo(() =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    sortFilteredMeters(filter.filteredMeters, activeSort), [filter.filteredMeters, activeSort])

  const filteredAndPaginatedMeters = useMemo(() =>
    paginateList(sortedList, meterActivePage, meterPageSize), [sortedList, meterActivePage, meterPageSize])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FetchMetersAction())

    const interval = setInterval(() => {
      dispatch(FetchMetersAction())
      dispatch(UpdateOutOfDateMeterArrayAction({ values }))
    }, 1800000) // 30 minutes
    return () => { clearInterval(interval) }
  }, [values, dispatch])

  useEffect(() => {
    const meterIds = filteredAndPaginatedMeters.map(meter => meter.id)
    dispatch(FetchDataArrayAction({ ids: meterIds }))
  }, [dispatch, filteredAndPaginatedMeters])

  const getLogo = (meter: Meter, small: boolean): string => {
    if (meter.isLora) {
      return LoraLogo
    }
    return small ? SigfoxNoTextLogo : SigfoxLogo
  }

  const onToggleUsageVisibility = (): void => {
    setUsageVisible(!usageVisible)
  }

  const onToggleIdVisibility = (): void => {
    setIdVisible(!idVisible)
  }

  const onToggleLabelVisibility = (): void => {
    setLabelVisible(!labelVisible)
  }
  const handlePageChange = (pageNumber: number): void => {
    dispatch(SetMeterActivePageAction(pageNumber))
  }

  const changePageSize = (newPageSize: string): void => {
    dispatch(SetMeterPageSizeAction(Number(newPageSize)))
  }

  const valueToMeterTableRow = (meter: Meter, value, isPulseMeter: boolean, waterValue, dateAndTimeUpateValue): React.JSX.Element => {
    return (
      <MetersTableRow
        meter={meter}
        labelVisibility={labelVisible ? null : { visibility: 'hidden' }}
        logo={getLogo(meter, true)}
        isPulse={isPulseMeter &&
            <div style={{ display: 'inline', color: '#0080FF', verticalAlign: 'middle' }}>
              &nbsp;Pulse
            </div>}
        idVisibility={idVisible ? null : { visibility: 'hidden' }} usageVisibility={usageVisible ? null : { visibility: 'hidden' }}
        meterLocation={value}
        updatedValue={waterValue}
        dateAndTimeCoupling={dateToLocaleOrEmpty((meter.firstMeterDataEntry !== 0) ? new Date(meter.firstMeterDataEntry * 1000) : null)}
        metersSettingDialog={() => dispatch(ToggleMeterSettingsDialogAction(meter))}
        dateAndTimeUpdating={dateAndTimeUpateValue}
        key={meter.sigfoxId}
      />
    )
  }

  return (
    <div>
      <ConnectedMeterConfiguration/>
      <StyledTable>
        <CustomTableHead toggleUsageVisibility={onToggleUsageVisibility} toggleIDVisibility={onToggleIdVisibility}
          toggleLabelVisibility={onToggleLabelVisibility}
          idToggleIcons={idVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
          usageToggleIcons={usageVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
          labelToggleIcons={labelVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
          sortAscendingByReading={() => { setSort({ type: SortType.ByReading, order: SortOrder.Ascending }) }}
          sortDescendingByReading={() => { setSort({ type: SortType.ByReading, order: SortOrder.Descending }) }}
          sortAscendingByName={() => { setSort({ type: SortType.ByName, order: SortOrder.Ascending }) }}
          sortDescendingByName={() => { setSort({ type: SortType.ByName, order: SortOrder.Descending }) }}
          sortAscendingByLocation={() => { setSort({ type: SortType.ByLocation, order: SortOrder.Ascending }) }}
          sortDescendingByLocation={() => { setSort({ type: SortType.ByLocation, order: SortOrder.Descending }) }}
          sortAscendingByCoupling={() => { setSort({ type: SortType.ByFirstDataEntry, order: SortOrder.Ascending }) }}
          sortDescendingByCoupling={() => { setSort({ type: SortType.ByFirstDataEntry, order: SortOrder.Descending }) }}
          sortAscendingByTimeUpdate={() => { setSort({ type: SortType.ByUpdatedAt, order: SortOrder.Ascending }) }}
          sortDescendingByTimeUpdate={() => { setSort({ type: SortType.ByUpdatedAt, order: SortOrder.Descending }) }}
          sortAscendingByAlarmsStatus={() => { setSort({ type: SortType.ByAlarmStatus, order: SortOrder.Ascending }) }}
          sortDescendingByAlarmsStatus={() => { setSort({ type: SortType.ByAlarmStatus, order: SortOrder.Descending }) }}/>
        <StyledTableBody>
          {[...filteredAndPaginatedMeters].map(meter => {
            const valueInState = meterLocations[meter.id]
            const value = valueInState !== undefined ? valueInState : meter.location
            const isPulseMeter = isPulseDevice(meter.type)
            const waterValue = parseCubicMeters(meter.waterUsed)
            const dateAndTimeUpdateValue = (meter.updatedAt !== 0) ? moment.unix(meter.updatedAt).format('DD.MM.YYYY HH:mm') : null
            return (valueToMeterTableRow(meter, value, isPulseMeter, waterValue, dateAndTimeUpdateValue))
          })}
        </StyledTableBody>
      </StyledTable>
      <MetersPagination
        activePage={meterActivePage}
        itemsCountPerPage={meterPageSize}
        totalItemsCount={filter.filteredMeters.length}
        onPageChange={(page: number) => { handlePageChange(page) }}
        onPageSizeChange={(e: React.ChangeEvent<HTMLInputElement>) => { changePageSize(e.target.value) }}
      />
    </div>

  )
}

const mapStateToProps = (state: RootState): {
  values: any
  meterLocations: any
  outOfDateMeters: any
  meterActivePage: number
  meterPageSize: number
  filter: any
  meters: any
} => {
  const { meterTable } = state.vesimittari
  const { data } = state.vesimittari
  const { meterLocations, outOfDateMeters, meterActivePage, meterPageSize } = meterTable
  const { meters } = data
  const { meterDataReducer } = state.vesimittari
  const { values } = meterDataReducer
  const filter = { filteredMeters: data.filteredMeters }

  return {
    values,
    meterLocations,
    outOfDateMeters,
    meterActivePage,
    meterPageSize,
    filter,
    meters
  }
}
export default connect(mapStateToProps)(MetersTable)
