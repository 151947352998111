import * as React from 'react'
import { type TFunction } from 'i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

interface SetCompanyAlarmConfirmDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  t: TFunction
}

export function SetCompanyAlarmConfirmDialog({
  isOpen,
  onClose,
  onConfirm,
  t
}: SetCompanyAlarmConfirmDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} data-testid="set-company-alarm-confirm">
      <DialogTitle>
        {t('company_alarm_dialog.confirm_alarms_change')}
      </DialogTitle>
      <DialogContent>
        {t('company_alarm_dialog.are_you_sure_you_want_to_submit')}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {t('common.actions.back')}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t('common.actions.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
