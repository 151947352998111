import React, { type ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Button, MenuItem, Checkbox, FormControlLabel, TableCell, Table, TableHead, TableRow, TableBody } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledHeaderCol, StyledRow, StyledChip, StyledSelect, StyledTextField, GridContainer } from './Settings.styles'
import { type RootState } from '../../../interfaces/RootState'
import { FetchCompaniesAction } from '../../../redux/admin/admin-actions'
import { type Company } from '../../../redux/meters/meter-types'
import { useTranslation } from 'react-i18next'
import { AddEmailRecipientAction, AddSMSRecipientAction, ChangeEmailIntervalAction, ChangeSMSIntervalAction, FetchCustomerAlarmsAction, RemoveEmailRecipientAction, RemoveSMSRecipientAction, SaveCustomerAlarmsAction, ToggleEnableEmailAction, ToggleSMSEnabledAction, ToggleTemperatureAlertsEnabledAction, UpdateSMSRecipientTextBoxAction, UpdateTemperatureAlertLowerLimitAction, UpdateTemperatureAlertUpperLimitAction } from '../../../redux/settings/setting-actions'
import { type SettingsState } from '../../../redux/settings/settings-type'

function Settings({ dispatch, settings, companies, ownCompany }: { dispatch: any, settings: SettingsState, companies: Company[], ownCompany: any }): JSX.Element {
  const t = useTranslation().t
  const [emailEnabled, setEmailEnabled] = useState<boolean>(false)
  const [smsEnabled, setSmsEnabled] = useState<boolean>(false)
  const [temperatureAlertEnabled, setTemperatureAlertEnabled] = useState<boolean>(false)
  const [smsRecipients, setSMSRecipient] = useState<string>()
  const pattern = '^\\+(?:[0-9] ?){6,14}[0-9]$'
  const phoneNumberValidation = new RegExp(pattern)
  const invalidSMSAlertRecipient = smsRecipients !== '' && smsRecipients !== undefined && !phoneNumberValidation.test(smsRecipients)

  const emailRecipients = (): ReactElement[] => {
    const retValue: ReactElement[] = []
    const companyData = companies.filter(o => o.id === settings.alarmSettingsCustomer)
    if (companyData.length === 0) {
      companyData.push(ownCompany)
    }
    const filteredUsers = companyData[0]?.users?.filter(u => !settings.alarmSettings.emailRecipients.includes(u.username)) ?? []
    for (const usr of filteredUsers) {
      retValue.push(
        <MenuItem
          key={'usr-select-email-' + usr.username}
          value={usr.username}
        >
          {usr.username}
        </MenuItem>
      )
    }

    return retValue
  }

  const getEmailRecipientChips = (): ReactElement[] => {
    return settings.alarmSettings.emailRecipients.map((r, i) => {
      return <StyledChip
        label={r}
        key={i}
        onDelete={() => dispatch(RemoveEmailRecipientAction(r))}
        deleteIcon={<ClearIcon style={{ color: 'white' }}/>}
      />
    })
  }

  const getSMSRecipientChips = (): ReactElement[] => {
    return settings.alarmSettings.smsRecipients.map((r, i) => {
      return <StyledChip
        label={r}
        key={i}
        onDelete={() => dispatch(RemoveSMSRecipientAction(r))}
        deleteIcon={<ClearIcon style={{ color: 'white' }}/>}
      />
    })
  }

  const handleSMSSubmit = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') { // User presses Enter. Works with mobile as well
      dispatch(AddSMSRecipientAction(settings.smsRecipientToAdd))
      dispatch(UpdateSMSRecipientTextBoxAction(''))
    }
  }

  const handleUpdateSMSRecipientTextBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(UpdateSMSRecipientTextBoxAction(e.target.value))
    setSMSRecipient(e.target.value)
  }

  const addSMSRecipientClicked = () => {
    dispatch(AddSMSRecipientAction(settings.smsRecipientToAdd))
    dispatch(UpdateSMSRecipientTextBoxAction(''))
  }

  const enableEmailSetting = () => {
    setEmailEnabled(!emailEnabled)
    dispatch(ToggleEnableEmailAction(emailEnabled))
  }

  const enableSMSSetting = () => {
    setSmsEnabled(!smsEnabled)
    dispatch(ToggleSMSEnabledAction(smsEnabled))
  }

  const enableTemperatureAlertSetting = () => {
    setTemperatureAlertEnabled(!temperatureAlertEnabled)
    dispatch(ToggleTemperatureAlertsEnabledAction(temperatureAlertEnabled))
  }

  const handleCancel = () => {
    dispatch(FetchCustomerAlarmsAction({ id: ownCompany.id }))
  }

  // Load companies and alarms
  useEffect(() => {
    dispatch(FetchCompaniesAction())
    if (ownCompany !== null) {
      dispatch(FetchCustomerAlarmsAction({ id: ownCompany.id }))
    }
  }, [dispatch, ownCompany])

  useEffect(() => {
    setEmailEnabled(settings.alarmSettings.emailEnabled)
    setSmsEnabled(settings.alarmSettings.smsEnabled)
    setTemperatureAlertEnabled(settings.alarmSettings.temperatureAlertsEnabled)
  }, [settings])

  return (
    <div style={{ marginLeft: '20%', marginRight: '20%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeaderCol gray={false}>
              <h3>{t('settings_o.settings')}</h3>
            </StyledHeaderCol>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* HEADER */}
          <StyledRow>
            <StyledHeaderCol gray={false}><h4>{t('settings_o.message_settings')}</h4></StyledHeaderCol>
            <StyledHeaderCol colSpan={2} gray={true}><p>{t('settings_o.recipients')}</p></StyledHeaderCol>
            <StyledHeaderCol gray={true}><p>{t('settings_o.sending_interval')}</p></StyledHeaderCol>
            <StyledHeaderCol gray={false}/>
          </StyledRow>

          {/* EMAIL */}
          <StyledRow>
            <StyledHeaderCol gray={false}>
              <p>{t('settings_o.email')}</p>
            </StyledHeaderCol>
            <TableCell colSpan={2}>
              <StyledSelect
                value={''}
                disableUnderline
                input={<Input id="selectEmailRecipients" />}
                disabled={settings.alarmSettingsCustomer == null}
                onChange={(e) => dispatch(AddEmailRecipientAction(e.target.value as string))}
                IconComponent={({ className }) => <ExpandMoreIcon className={className} />}
              >
                {emailRecipients()}
              </StyledSelect>
              {getEmailRecipientChips()}
            </TableCell>
            <TableCell>
              <StyledSelect
                disableUnderline
                input={<Input id="selectEmailInverval" />}
                value={settings.alarmSettings.emailInterval}
                disabled={settings.alarmSettingsCustomer == null}
                onChange={(e) => dispatch(ChangeEmailIntervalAction(parseInt(e.target.value as string)))}
                IconComponent={({ className }) => <ExpandMoreIcon className={className} />}
              >
                <MenuItem value={1}>
                  {t('settings_o.daily')}
                </MenuItem>
                <MenuItem value={2}>
                  {t('settings_o.weekly')}
                </MenuItem>
              </StyledSelect>
            </TableCell>
            <TableCell>
              <FormControlLabel
                label={settings.alarmSettings.emailEnabled ? t('settings_o.enabled') : t('settings_o.disabled')}
                control={
                  <Checkbox
                    disabled={settings.alarmSettingsCustomer == null}
                    checked={emailEnabled}
                    onChange={enableEmailSetting}
                    color="default"
                  />
                }
              />
            </TableCell>
          </StyledRow>

          {/* SMS */}
          <StyledRow>
            <StyledHeaderCol gray={false}>
              <p>{t('settings_o.sms')}</p>
            </StyledHeaderCol>
            <TableCell>
              <StyledTextField
                error={invalidSMSAlertRecipient}
                helperText={invalidSMSAlertRecipient ? t('settings_o.wrong_phone_number_format') : ''}
                fullWidth
                margin="dense"
                variant="outlined"
                id={'sms_recipient_field'}
                inputProps={{ 'data-testid': 'sms_recipient_field' }}
                // label={i18n.t('settings_o.recipient')}
                value={settings.smsRecipientToAdd}
                disabled={settings.alarmSettingsCustomer == null}
                onChange={handleUpdateSMSRecipientTextBoxChange}
                onKeyUp={handleSMSSubmit}
              />
            </TableCell>
            <TableCell>
              <Button
                style={{ marginLeft: '-0.5em', marginRight: '.6em', padding: '.43em', width: '50%' }}
                color="primary"
                variant={'contained'}
                onClick={addSMSRecipientClicked}
                data-testid='add-recipient-btn'
                disabled={settings.smsRecipientToAdd === '' || invalidSMSAlertRecipient}
              >
                OK
              </Button>
              {getSMSRecipientChips()}
            </TableCell>
            <TableCell>
              <StyledSelect
                disableUnderline
                input={<Input id="selectSMSInverval"/>}
                value={settings.alarmSettings.smsInterval}
                disabled={settings.alarmSettingsCustomer == null}
                onChange={(e) => dispatch(ChangeSMSIntervalAction(parseInt(e.target.value as string)))}
                IconComponent={({ className }) => <ExpandMoreIcon className={className} />}
              >
                <MenuItem value={1}>
                  {t('settings_o.daily')}
                </MenuItem>
                <MenuItem value={2}>
                  {t('settings_o.weekly')}
                </MenuItem>
              </StyledSelect>
            </TableCell>
            <TableCell>
              <FormControlLabel
                data-testid='sms-alert-enabled'
                label={settings.alarmSettings.smsEnabled ? t('settings_o.enabled') : t('settings_o.disabled')}
                control={
                  <Checkbox
                    disabled={settings.alarmSettingsCustomer == null}
                    checked={smsEnabled}
                    onChange={enableSMSSetting}
                    color="default"
                  />
                }
              />
            </TableCell>
          </StyledRow>
          {/* TEMPERATURE ALARM */}
          <StyledRow>
            <StyledHeaderCol gray={false}><h4>{t('settings_o.temperature_alarm')}</h4></StyledHeaderCol>
            <StyledHeaderCol gray={true}><p>{t('settings_o.lower_limit')}</p></StyledHeaderCol>
            <StyledHeaderCol gray={true}><p>{t('settings_o.upper_limit')}</p></StyledHeaderCol>
            <StyledHeaderCol gray={false}/>
            <StyledHeaderCol gray={false}/>
          </StyledRow>
          <StyledRow>
            <TableCell/>
            <TableCell>
              <StyledTextField
                fullWidth
                margin="dense"
                variant="outlined"
                id={'temperature_alarm_lower_limit'}
                inputProps={{ 'data-testid': 'temperature_alarm_lower_limit' }}
                type={'number'}
                value={settings.alarmSettings.temperatureAlertLowerLimit}
                disabled={!settings.alarmSettingsCustomer}
                onChange={(e) => dispatch(UpdateTemperatureAlertLowerLimitAction(parseInt(e.target.value)))}
              />
            </TableCell>
            <TableCell>
              <StyledTextField
                fullWidth
                margin="dense"
                variant="outlined"
                id={'temperature_alarm_upper_limit'}
                inputProps={{ 'data-testid': 'temperature_alarm_upper_limit' }}
                type={'number'}
                value={settings.alarmSettings.temperatureAlertUpperLimit}
                disabled={!settings.alarmSettingsCustomer}
                onChange={(e) => dispatch(UpdateTemperatureAlertUpperLimitAction(parseInt(e.target.value)))}
              />
            </TableCell>
            <TableCell>
            </TableCell>
            <TableCell>
              <FormControlLabel
                label={settings.alarmSettings.temperatureAlertsEnabled ? t('settings_o.enabled') : t('settings_o.disabled')}
                control={
                  <Checkbox
                    disabled={settings.alarmSettingsCustomer == null}
                    checked={temperatureAlertEnabled}
                    onChange={enableTemperatureAlertSetting}
                    color="default"
                  />
                }
              />
            </TableCell>
          </StyledRow>

          {/* BUTTONS */}
          <StyledRow>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <GridContainer>
              <Button
                fullWidth
                variant={'contained'}
                color="inherit"
                disabled={!settings.alarmSettingsDirty}
                onClick={handleCancel}
              >
                {t('settings_o.cancel')}
              </Button>
              <Button
                fullWidth
                color="primary"
                variant={'contained'}
                onClick={() => dispatch(SaveCustomerAlarmsAction({
                  id: settings.alarmSettingsCustomer,
                  data: settings.alarmSettings
                }))}
                disabled={!settings.alarmSettingsDirty}
              >
                {t('settings_o.save')}
              </Button>
            </GridContainer>
          </StyledRow>
        </TableBody>
      </Table>
    </div>
  )
}

function mapStateToProps(state: RootState) {
  const { settings, admin, auth } = state.vesimittari

  return { settings, companies: admin.companies, ownCompany: auth.ownCompany }
}

export default connect(mapStateToProps)(Settings)
