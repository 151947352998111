import React from 'react'
import { StyledTableHead, StyledTableHeadCell, StyledTableRow, StyledTableTitle, StyledSortButtonsContainer, StyledIconButton } from './MetersTable.style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function CustomTableHead({ toggleIDVisibility, toggleLabelVisibility, toggleUsageVisibility, idToggleIcons, usageToggleIcons, labelToggleIcons, ...props }) {
  const t = useTranslation().t
  const {
    sortAscendingByReading,
    sortAscendingByTimeUpdate,
    sortAscendingByCoupling,
    sortAscendingByName,
    sortAscendingByLocation,
    sortDescendingByName,
    sortDescendingByReading,
    sortDescendingByCoupling,
    sortDescendingByTimeUpdate,
    sortDescendingByLocation,
    sortAscendingByAlarmsStatus,
    sortDescendingByAlarmsStatus
  } = props

  const toggleVisibility = t('toggle_visibility')

  return (
    <StyledTableHead>
      <StyledTableRow>
        <StyledTableHeadCell></StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('radio_id')}</StyledTableTitle>
          <Tooltip title={toggleVisibility} arrow>
            <StyledIconButton onClick={toggleIDVisibility}>
              {idToggleIcons}
            </StyledIconButton>
          </Tooltip>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByName}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByName} />
          </StyledSortButtonsContainer>

        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('usage_location_number')}</StyledTableTitle>
          <Tooltip title={toggleVisibility} arrow>
            <StyledIconButton onClick={toggleUsageVisibility}>
              {usageToggleIcons}
            </StyledIconButton>
          </Tooltip>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByLocation}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByLocation} />
          </StyledSortButtonsContainer>
        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('main_meters.labels')}</StyledTableTitle>
          <Tooltip title={toggleVisibility} arrow>
            <StyledIconButton onClick={toggleLabelVisibility}>
              {labelToggleIcons}
            </StyledIconButton>
          </Tooltip>
        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('alarms')}</StyledTableTitle>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByAlarmsStatus}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByAlarmsStatus} />
          </StyledSortButtonsContainer>
        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('reading')}</StyledTableTitle>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByReading}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByReading} />
          </StyledSortButtonsContainer>

        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('activation_date')}</StyledTableTitle>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByCoupling}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByCoupling} />
          </StyledSortButtonsContainer>
        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle>{t('updated')}</StyledTableTitle>
          <StyledSortButtonsContainer>
            <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={sortAscendingByTimeUpdate}/>
            <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={sortDescendingByTimeUpdate} />
          </StyledSortButtonsContainer>
        </StyledTableHeadCell>
        <StyledTableHeadCell>
          <StyledTableTitle />
          <StyledSortButtonsContainer />
        </StyledTableHeadCell>
      </StyledTableRow>
    </StyledTableHead>

  )
}
