import { API_URL } from '../constants/urls'

export default class AdminApi {
  static async fetchCompanies<T>(idToken: string): Promise<T> {
    return await fetch(`${API_URL}/company`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }
}
