import { type Middleware } from 'redux'
import { createAction } from '@reduxjs/toolkit'
import { type RootState } from './interfaces/RootState'
import { PERSISTENCE_LOAD, PERSISTENCE_RESTORE } from './constants/action-types'
import { LockSuccessAction, LogoutSuccessAction, UserReceivedAction } from './redux/auth/auth-actions'
import { FetchDataArraySuccessAction, FetchDataSuccessAction } from './redux/meter-data/meter-data-actions'

export const loadData = (key: string): any => {
  return localStorage.getItem(key)
}

const persistData = (key: string, value: any): void => {
  localStorage.setItem(key, value)
}

const removeData = (keys: string[]): void => {
  for (const key of keys) {
    localStorage.removeItem(key)
  }
}

export interface PersistedData {
  idToken: string | null
  data: any
  tab: number
  selectedMeter: any
  isAdmin: boolean
  isAssignedToCompany: boolean
}

const loadAllData = (): PersistedData => {
  return {
    idToken: loadData('id_token'),
    data: loadData('data'),
    tab: loadData('tab'),
    selectedMeter: loadData('selected_meter'),
    isAdmin: loadData('admin'),
    isAssignedToCompany: loadData('assigned')
  }
}

export const loadPersistedDataAction = createAction<void>(PERSISTENCE_LOAD)
export const RestorePersistedDataAction = createAction<PersistedData>(PERSISTENCE_RESTORE)

export function createPersistenceMiddleware(): Middleware<unknown, RootState> {
  return function(store) {
    return next => action => {
      if (loadPersistedDataAction.match(action)) {
        store.dispatch(RestorePersistedDataAction(loadAllData()))
      }

      if (FetchDataArraySuccessAction.match(action)) {
        persistData('data', JSON.stringify(action.payload.datas))
      }

      if (FetchDataSuccessAction.match(action)) {
        persistData('data', JSON.stringify(action.payload.data))
      }

      if (UserReceivedAction.match(action)) {
        persistData('username', action.payload.user.username)
        persistData('admin', action.payload.user.systemAdmin)
        persistData('assigned', action.payload.assignedToCompany)
        persistData('ownCompany', JSON.stringify(action.payload.ownCompany))
      }

      if (LockSuccessAction.match(action)) {
        persistData('id_token', action.payload.token)
      }

      if (LogoutSuccessAction.match(action)) {
        removeData(['admin', 'id_token', 'username', 'assigned', 'ownCompany', 'data'])
      }

      return next(action)
    }
  }
}
