import { combineReducers, type Reducer } from 'redux'
import admin from './admin/admin-reducer'
import auth from './auth/auth-reducer'
import settings from './settings/setting-reducer'
import data from './data/data-reducer'
import devicesForProvisionReducer from './devices-for-provision/devices-for-provision-reducer'
import downlinkMessagesReducer from './downlink-messages/downlink-messages-reducer'
import mainMetersReducer from './main-meters/main-meters-reducer'
import meterReducer from './meters/meter-reducer'
import meterDataReducer from './meter-data/meter-data-reducer'
import messagesReducer from './messages/messages-reducer'
import notices from './notices/notices-reducer'
import { i18nReducer } from 'react-redux-i18n'
import type I18nState from '../interfaces/I18nState'
import type AppState from '../interfaces/AppState'
import companies from './companies/companies-reducer'
import error from './error/error-reducer'
import meterTable from './meter-table/meter-table-reducer'
import alarmSettings from './alarm-settings/alarm-settings-reducer'

export const vesimittari = combineReducers({
  auth,
  companies,
  error,
  meterTable,
  alarmSettings,
  data,
  admin,
  devicesForProvisionReducer,
  downlinkMessagesReducer,
  mainMetersReducer,
  meterReducer,
  meterDataReducer,
  messagesReducer,
  settings,
  notices
})

const _i18nReducer: Reducer<I18nState> = i18nReducer

function createRootReducer(): Reducer<AppState> {
  return combineReducers({
    vesimittari,
    i18n: _i18nReducer
  })
}

export default createRootReducer
