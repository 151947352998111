import React from 'react'
import { AppBar, Button, Grid, IconButton, ListItemIcon, Menu, MenuItem, type SvgIconProps, Toolbar, Typography } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import List from '@mui/icons-material/List'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu'
import TermsIcon from '@mui/icons-material/Description'
import HelpIcon from '@mui/icons-material/Help'
import Company from '@mui/icons-material/BusinessCenter'
import CompareArrows from '@mui/icons-material/CompareArrows'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import HeaderBackground from '../../../assets/header-bgr.svg'
import HydronetLogo from '../../../assets/logo-header.svg'
import { FetchCompaniesAction } from '../../../redux/admin/admin-actions'
import { FetchNoticesAction } from '../../../redux/notices/notices-actions'
import LanguageSelector from '../language-selector/LanguageSelector'
import { useTranslation } from 'react-i18next'
import { FetchGroupsAction } from '../../../redux/settings/setting-actions'
import { useNavigate } from 'react-router-dom'
import { LogoutSuccessAction } from '../../../redux/auth/auth-actions'
export function Header({ dispatch, username, isAdmin }: { dispatch: any, username: string, isAdmin: boolean }): JSX.Element {
  const t = useTranslation().t
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const onMenuOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onMenuClose = () => {
    setAnchorEl(null)
  }

  const handleTabClicked = (tab: string) => {
    navigate(tab)
  }

  interface Tab {
    label: string
    icon: React.ReactElement<SvgIconProps>
    adminOnly: boolean
    onClick: () => void
  }

  const tabs: Tab[] = [
    // METERS
    {
      label: t('bottom_bar.meters'),
      icon: <List />,
      adminOnly: false,
      onClick: () => {
        handleTabClicked('/dashboard/meters')
        dispatch(FetchGroupsAction())
        onMenuClose()
      }
    },

    // SETTINGS
    {
      label: t('bottom_bar.settings'),
      icon: <SettingsIcon />,
      adminOnly: false,
      onClick: () => {
        dispatch(FetchGroupsAction())
        handleTabClicked('/dashboard/settings')
        onMenuClose()
      }
    },

    // INTEGRATIONS
    {
      label: t('bottom_bar.integrations'),
      icon: <CompareArrows />,
      adminOnly: true,
      onClick: () => {
        handleTabClicked('/dashboard/integrations')
        dispatch(FetchCompaniesAction())
        onMenuClose()
      }
    },

    // CUSTOMERS
    {
      label: t('bottom_bar.customers'),
      icon: <Company />,
      adminOnly: true,
      onClick: () => {
        handleTabClicked('/dashboard/customers')
        dispatch(FetchCompaniesAction())
        onMenuClose()
      }
    },

    // ACTIVATION
    {
      label: t('bottom_bar.activation'),
      icon: <SettingsIcon />,
      adminOnly: true,
      onClick: () => {
        handleTabClicked('/dashboard/devices_for_provision')
        onMenuClose()
      }
    },

    // TERMS
    {
      label: t('bottom_bar.terms'),
      icon: <TermsIcon />,
      adminOnly: false,
      onClick: () => {
        window.open('/docs/hydronet-kayttoehdot.pdf')
        onMenuClose()
      }
    },

    // TERMS
    {
      label: t('bottom_bar.help'),
      icon: <HelpIcon />,
      adminOnly: false,
      onClick: () => {
        window.open('/docs/Hydronet_ohje_v.2.5.1.pdf')
        onMenuClose()
      }
    },

    // NOTICES
    {
      label: t('notices.notices'),
      icon: <TextSnippetIcon />,
      adminOnly: false,
      onClick: () => {
        handleTabClicked('/dashboard/notices')
        dispatch(FetchNoticesAction())
        onMenuClose()
      }
    }
  ]

  const headerBgStyle = {
    backgroundImage: `url(${HeaderBackground})`,
    backgroundColor: '#000000',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }

  const hamburgerMenuStyle = {
    color: '#ffffff',
    fontSize: '1.9rem'
  }

  return (
    <AppBar style={headerBgStyle}>
      <Toolbar style={{ margin: '0 20% 0 20%' }}>
        <Grid
          container
          justifyContent={'space-between'}
          wrap={'nowrap'}
        >
          {/* LEFT SIDE */}
          <Grid item>
            <IconButton edge="start" onClick={onMenuOpen} size="large">
              <MenuIcon style={hamburgerMenuStyle}></MenuIcon>
            </IconButton>
            <Menu
              open={Boolean(anchorEl)}
              onClose={ onMenuClose }
              anchorEl={anchorEl}
              disableScrollLock
            >
              {tabs.filter((e) => !e.adminOnly || isAdmin).map((e, i) => (
                <MenuItem key={i} onClick={e.onClick}>
                  <ListItemIcon>
                    {e.icon}
                  </ListItemIcon>
                  <Typography>
                    {e.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>

          {/* LOGO */}
          <Grid item style={{ marginLeft: '25%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <img src={HydronetLogo} alt="hydronet-logo"/>
          </Grid>

          {/* RIGHT SIDE */}
          <Grid item >
            <Grid container wrap={'nowrap'} alignItems={'center'} style={{ height: '100%' }} >
              <Grid item style={{ marginRight: '2.5em' }}>
                <Typography style={{ color: '#ffffff' }} variant="h6">
                  {username}
                </Typography>
              </Grid>
              <Grid item style={{ marginRight: '2.5em', minWidth: '5em' }}>
                <LanguageSelector />
              </Grid>

              <Grid item style={{ marginRight: '2.5em' }}>
                <Button
                  endIcon={<ChevronRight />}
                  onClick={() => dispatch(LogoutSuccessAction())} style= {{ color: '#ffffff', fontSize: '1rem' }}
                >
                  {t('landing.logout')}
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default function ConnectedHeader(): JSX.Element {
  const dispatch = useDispatch()
  const isAdmin = useSelector((state: RootState): boolean => state.vesimittari.auth.isAdmin)
  const username = useSelector((state: RootState): string => state.vesimittari.auth.username)

  return <Header dispatch={dispatch} username={username} isAdmin={isAdmin} />
}
