import styled from '@emotion/styled'

export const ModalCancelButton = styled.button`
    padding: 10px 20px;
    font-weight: 600;
    border-radius : 5px;
    margin: 16px 0;
    &:hover {
        cursor: pointer;
        filter: brightness(120%);
    }
    color: #2c7be5;
    background: #fff;
    border: 1px solid #2c7be5;
`
export const ModalConfirmButton = styled.button`
    padding: 10px 20px;
    font-weight: 600;
    border-radius : 5px;
    margin: 16px 0;
    &:hover {
        cursor: pointer;
        filter: brightness(120%);
    }
    &:disabled {
        background: rgba(19, 1, 1, 0.3);
        color: rgba(16, 16, 16, 0.3);
    }
    color: #fff;
    background: #2c7be5;
    border: none;
`
