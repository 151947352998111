import React, { useEffect } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import '../../../assets/App.css'
import '../../../assets/Mobile.css'
import { useTranslation } from 'react-i18next'
import { storeLanguage } from '../../../utils/languageStore'

type Language = 'fi'

const defaultLanguage: Language = 'fi'

const getLanguage = (country: string): Language => {
  switch (country) {
    case 'FI':
      return 'fi'
    default:
      return defaultLanguage
  }
}

const LanguageSelector = () => {
  const i18n = useTranslation().i18n
  const language = i18n.language
  const changeLanguage = i18n.changeLanguage

  const onSelectLanguage = (country: string) => {
    const la = getLanguage(country)
    changeLanguage(la)
  }

  useEffect(() => {
    storeLanguage(language ?? defaultLanguage)
  }, [language])

  return (
    <ReactFlagsSelect
      countries={['FI']}
      placeholder={language}
      onSelect={onSelectLanguage}
      selected={language}
      showOptionLabel={false}
      showSelectedLabel={false}
      className={'language-selector'}
      selectButtonClassName={'language-select-button'}
    />
  )
}

export default LanguageSelector
