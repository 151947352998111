import { call, put, select, takeLatest } from 'redux-saga/effects'
import Api from '../../api/api'
import { FETCH_COMPANIES } from '../../constants/action-types'
import selectIdToken from '../../redux/selectors/select-id-token'
import { FetchCompaniesFailAction, FetchCompaniesSuccessAction } from './admin-actions'

export function * fetchCompaniesAsync() {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.AdminApi.fetchCompanies, idToken)
  if (response.error) {
    yield put(FetchCompaniesFailAction(response.error))
  } else {
    yield put(FetchCompaniesSuccessAction({
      companies: response,
      searchedCompanies: response
    }))
  }
}

export function * fetchCompaniesStart() {
  yield takeLatest(FETCH_COMPANIES, fetchCompaniesAsync)
}

export default function * adminSagas() {
  yield call(fetchCompaniesStart)
}
