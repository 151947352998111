import { TextField } from '@mui/material'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sharedStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between
`
export const CustomersViewContainer = styled.div`
    padding: 2rem 3rem
`
export const CompanyFormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px
`
export const FormContainer = styled.form`
    border: solid #EFF0F3 1px;
    border-radius: 5px;
    padding: 16px
`
export const FormHeader = styled.div`
    ${sharedStyle}
`
export const FormFieldTitle = styled.p`
    font-weight: 600
`
export const FormFieldsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
`
export const FormInputContainer = styled.div`
   display: grid;
   gap: 10px;
   padding: 10px 0;
   max-width: 70%
`
export const FormInput = styled(TextField)`
    border: solid #EFF0F3 1px;
    border-radius: 5px;
`

export const CheckboxInputContainer = styled.div`
   ${sharedStyle};
   background-color: #F8F8F8;
   border: solid #EFF0F3 1px;
   border-radius: 5px;
   margin: 10px 0;
   padding: 0 10px;
`
export const CheckboxInput = styled.div`
    display: flex;
    align-items: center
`
export const CustomerListContainer = styled.div`
    display:grid;
    height: max-content;
    gap: 10px;
    max-width: 60%
`
