import { takeLatest, put, call, select, all } from 'redux-saga/effects'
import { API_URL } from '../../constants/urls'
import { UPDATE_NEW_METER_VALUES } from '../../constants/action-types'
import { UpdateNewMeterValuesSuccessAction, UpdateNewMeterValuesErrorAction } from './meter-actions'
import { type Meter } from './meter-types'
import selectIdToken from '../selectors/select-id-token'

export function * updateNewMeterValues(actionPayload: { type: string, payload: Meter }) {
  try {
    const idToken = yield select(selectIdToken)
    const updatedMeter = yield call(async () => {
      return await fetch(`${API_URL}/device/${actionPayload.payload.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(actionPayload.payload)
      }).then(async response => await response.json())
    })
    yield put(UpdateNewMeterValuesSuccessAction(updatedMeter))
  } catch (error) {
    yield put(UpdateNewMeterValuesErrorAction(error))
  }
}

export function * updateNewMeterValuesStart() {
  yield takeLatest(UPDATE_NEW_METER_VALUES, updateNewMeterValues)
}

export function * meterSagas() {
  yield all([call(updateNewMeterValuesStart)])
}
