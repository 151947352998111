export enum MessageTypes {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info'
}

export interface Message {
  type: MessageTypes
  message: string
  id: string
}

export interface MessagesState {
  messages: Message[]
}

export interface AddMessagePayload {
  message: {
    message: string
    type: MessageTypes
  }
}

export interface AcknowledgeMessagePayload {
  id: string
}
