import {
  type ADD_DOWNLINK_MESSAGE,
  type ADD_DOWNLINK_MESSAGE_SUCCESS,
  type ADD_DOWNLINK_MESSAGE_FAILED,
  type FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS,
  type FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL,
  type FETCH_DOWNLINK_MESSAGES_FOR_DEVICE,
  type CANCEL_DOWNLINK_MESSAGE,
  type CANCEL_DOWNLINK_MESSAGE_SUCCESS,
  type CANCEL_DOWNLINK_MESSAGE_FAILED
} from '../../constants/action-types'

export enum DownlinkMessageType {
  SET_SEND_INTERVAL = 'SET_SEND_INTERVAL',
  SET_PULSE_WIDTH = 'SET_PULSE_WIDTH',
  SET_SEND_TEMPERATURE = 'SET_SEND_TEMPERATURE',
  RESET_ALARMS = 'RESET_ALARMS',
  RESET_READING = 'RESET_READING',
  CUSTOM = 'CUSTOM'
}

export interface SentMessage {
  success: boolean
  error: string
  type: DownlinkMessageType
  created: number
  queued: number
}

export interface QueuedMessage {
  sendCount: number
  type: DownlinkMessageType
  queued: number
}

export interface DownlinkMessagesByDeviceId {
  sentMessages: SentMessage[]
  queuedMessages: QueuedMessage[]
}

export interface DownlinkMessagesState {
  downlinkMessagesByDeviceId: any /* Record<string, DownlinkMessagesByDeviceId> */
}

export interface AddDownlinkMessagePayload {
  type: DownlinkMessageType
  deviceId: string
  pulseWidth?: number
  sendInterval?: number
  sendTemperature?: boolean
}

export interface CancelDownlinkMessagePayload {
  index: number
  deviceId: string
}

export interface FetchDownlinkMessagesForDevicePayload {
  deviceId: string
}

export type AddDownlinkMessageSuccessPayload = AddDownlinkMessagePayload

export interface AddDownlinkMessageFailPayload {}

export interface CancelDownlinkMessageSuccessPayload {
  index: number
  deviceId: string
}

export interface CancelDownlinkMessageFailPayload {}

export interface FetchDownlinkMessagesForDeviceSuccessPayload {
  deviceId: string
  sentMessages: SentMessage[]
  queuedMessages: QueuedMessage[]
}

export interface FetchDownlinkMessagesForDeviceFailPayload {}

export interface AddDownlinkMessage {
  type: typeof ADD_DOWNLINK_MESSAGE
  payload: AddDownlinkMessagePayload
}

export interface AddDownlinkMessageSuccess {
  type: typeof ADD_DOWNLINK_MESSAGE_SUCCESS
  payload: AddDownlinkMessageSuccessPayload
}

export interface AddDownlinkMessageFail {
  type: typeof ADD_DOWNLINK_MESSAGE_FAILED
  payload: AddDownlinkMessageFailPayload
}

export interface CancelDownlinkMessage {
  type: typeof CANCEL_DOWNLINK_MESSAGE
  payload: CancelDownlinkMessagePayload
}

export interface CancelDownlinkMessageSuccess {
  type: typeof CANCEL_DOWNLINK_MESSAGE_SUCCESS
  payload: CancelDownlinkMessageSuccessPayload
}

export interface CancelDownlinkMessageFail {
  type: typeof CANCEL_DOWNLINK_MESSAGE_FAILED
  payload: CancelDownlinkMessageFailPayload
}

export interface FetchDownlinkMessagesForDevice {
  type: typeof FETCH_DOWNLINK_MESSAGES_FOR_DEVICE
  payload: FetchDownlinkMessagesForDevicePayload
}

export interface FetchDownlinkMessagesForDeviceSuccess {
  type: typeof FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS
  payload: FetchDownlinkMessagesForDeviceSuccessPayload
}

export interface FetchDownlinkMessagesForDeviceFail {
  type: typeof FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL
  payload: FetchDownlinkMessagesForDeviceFailPayload
}
