import { Button, Select, Table, TableHead, TableBody, MenuItem, TableCell, type SelectChangeEvent } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { AssociateMainWaterMeterToLabelAction, ChooseLabelValue, ChooseMainWaterMeter } from '../../../redux/main-meters/main-meters-actions'
import { AssociateFieldTitle, AssociateLabelText, StyledHeadTableCell } from './MainMeters.style'
import { type FetchLabelsWithMainMetersSuccessfulPayload } from '../../../redux/main-meters/main-meters-types'
import { errorAlert } from '../../../redux/error/error-utils'
export default function AssociateLabel({ labels, meters, mainMetersList }) {
  const labelsWithMainMeters = useSelector((state: RootState): FetchLabelsWithMainMetersSuccessfulPayload => state.vesimittari.mainMetersReducer.labelsWithMainMeters)
  const [label, setLabel] = React.useState('')
  const [labelItem, setLabelItem] = React.useState(labelsWithMainMeters)

  const [meter, setMeter] = React.useState('')
  const dispatch = useDispatch()

  const unassignedMeters = meters
    ? meters.filter(el => {
      return el.labels && el.labels.length > 0 && el.labels.includes(label)
    })
    : []

  const handleLabelChanged = (e: SelectChangeEvent<string>) => {
    const foundLabel = labels.find(i => i.value === e.target.value)
    setLabel(e.target.value)
    setLabelItem(foundLabel)
    dispatch(ChooseLabelValue(foundLabel.value))
  }

  const handleMeterChanged = (e: SelectChangeEvent<string>) => {
    const foundMeter = unassignedMeters.find(i => i.sigfoxId === e.target.value)
    setMeter(e.target.value)
    setLabelItem({ ...labelItem, meterSigfoxId: foundMeter.sigfoxId })
    dispatch(ChooseMainWaterMeter(foundMeter.sigfoxId))
  }

  const mainWaterMeterCheck = (meterSigfoxId) => {
    return mainMetersList.some((el) => {
      return el.meterSigfoxId === meterSigfoxId
    })
  }
  const associateMainMeterToLabelHandler = () => {
    if (mainWaterMeterCheck(labelItem.meterSigfoxId) === true) {
      errorAlert('Päävesimittareiden lisäminen epäonnistui', new Error(), dispatch)
    } else {
      dispatch(AssociateMainWaterMeterToLabelAction(labelItem))
      setLabel('')
      setMeter('')
    }
  }
  return (
    <div>
      <AssociateFieldTitle>Lisää uusi päävesimittari</AssociateFieldTitle>
      <Table>
        <TableHead>
          <StyledHeadTableCell>Merkintä</StyledHeadTableCell>
          <StyledHeadTableCell>Mittari ID</StyledHeadTableCell>
          <StyledHeadTableCell>Toiminto</StyledHeadTableCell>
        </TableHead>
        <TableBody style={{ background: '#F8F8F8' }}>
          <TableCell>
            <Select variant="outlined" style={{ width: '100%' }} value={label} onChange={handleLabelChanged} displayEmpty>
              <MenuItem value="">
                <em>Valitse merkintä...</em>
              </MenuItem>
              {labels.map((l) => (
                <MenuItem key={l.id} value={l.value}>{l.value}</MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Select style={{ width: '100%' }} variant="outlined" value={meter} onChange={handleMeterChanged} displayEmpty>
              <MenuItem value="">
                <em>Valitse mittari...</em>
              </MenuItem>
              {unassignedMeters.map((i) => (
                <MenuItem key={i.id} value={i.sigfoxId} >{i.sigfoxId}</MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Button onClick={associateMainMeterToLabelHandler} color="inherit">
              <AssociateLabelText>+ Lisää</AssociateLabelText>
            </Button>
          </TableCell>
        </TableBody>
      </Table>
    </div>
  )
}
