import * as React from 'react'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { type RootState } from '../../../interfaces/RootState'
import { SetCompanyAlarmDialogVisibilityAction } from '../../../redux/meter-table/meter-table-actions'
import { UpdateCompanyAlarmAction } from '../../../redux/companies/companies-actions'
import { type Company } from '../../../redux/meters/meter-types'
import { type VesimittariDispatch } from '../../../configureStore'
import { SetCompanyAlarmForm } from './set-company-alarm-form/SetCompanyAlarmForm'
import { SetCompanyAlarmConfirmDialog } from './set-company-alarm-confirm-dialog/SetCompanyAlarmConfirmDialog'
import { type CompanyAlarm } from '../../../redux/companies/companies-types'
import { type i18n, type TFunction } from 'i18next'

interface SetCompanyAlarmDialogProps {
  dispatch: VesimittariDispatch
  companies: Company[]
  companyAlarm: CompanyAlarm
  isOpen: boolean
  t: TFunction
  i18n: i18n
}

export function SetCompanyAlarmDialog({
  dispatch,
  companies,
  companyAlarm,
  isOpen,
  t,
  i18n
}: SetCompanyAlarmDialogProps) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)

  const handleClose = () => {
    dispatch(SetCompanyAlarmDialogVisibilityAction(false))
    setIsConfirmDialogOpen(false)
  }

  const handleSubmit = () => {
    setIsConfirmDialogOpen(true)
  }

  const handleConfirm = () => {
    if (companyAlarm) {
      dispatch(UpdateCompanyAlarmAction({ companyAlarm, i18n }))
    }
    handleClose()
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false)
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        data-testid="set-company-alarm-dialog"
        maxWidth="sm"
      >
        <DialogTitle>
          {t('company_alarm_dialog.edit_all_alarms')}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <SetCompanyAlarmForm
            dispatch={dispatch}
            companies={companies}
            companyAlarm={companyAlarm}
            t={t}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('common.actions.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('common.actions.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <SetCompanyAlarmConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirm} t={t}
      />
    </>
  )
}

export default function ConnectedSetCompanyAlarmDialog(): JSX.Element {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const isAdmin = useSelector((state: RootState) => state.vesimittari.auth.isAdmin)
  const isOpen = useSelector((state: RootState) => state.vesimittari.meterTable.isSetCompanyAlarmDialogOpen)
  const companyAlarm = useSelector((state: RootState) => state.vesimittari.meterTable.companyAlarm)
  const adminCompanies = useSelector<RootState, Company[]>(state => state.vesimittari.admin.companies)
  const filteredAdminCompanies = useMemo(() => adminCompanies.filter(company => company.name !== 'NOT_ASSIGNED_USERS'), [adminCompanies])
  const ownCompany = useSelector<RootState, Company | null>(state => state.vesimittari.auth.ownCompany)

  const companies = useMemo(() => {
    if (isAdmin) {
      return filteredAdminCompanies
    }
    if (ownCompany) {
      return [ownCompany]
    }
    return []
  }, [filteredAdminCompanies, isAdmin, ownCompany])

  return (
    <SetCompanyAlarmDialog
      dispatch={dispatch}
      companies={companies}
      companyAlarm={companyAlarm}
      isOpen={isOpen}
      t={t}
      i18n={i18n}
    />
  )
}
