import React from 'react'
import { Button, IconButton, type SxProps, TableCell, TableRow, Typography } from '@mui/material'
import { type Notice } from '../../../redux/notices/notices-types'
import { DeleteNoticeAction } from '../../../redux/notices/notices-actions'
import { useDispatch } from 'react-redux'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

interface Props {
  isAdmin: boolean
  notice: Notice
}

export default function NoticeRow({ isAdmin, notice }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const deleteNotice = () => {
    dispatch(DeleteNoticeAction(notice.id))
  }

  const getContentStyle = (): SxProps => {
    return expanded
      ? { whiteSpace: 'pre' }
      : {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical'
        }
  }

  return <TableRow>
    <TableCell>
      <Typography>{new Date(notice.created).toLocaleString()}</Typography>
    </TableCell>
    <TableCell>
      <Typography sx={getContentStyle()}>{notice.contents}</Typography>
    </TableCell>
    <TableCell>
      <IconButton onClick={() => { setExpanded(!expanded) }}>
        {expanded
          ? <ExpandLessIcon />
          : <ExpandMoreIcon />
        }
      </IconButton>
    </TableCell>
    <TableCell>
      {isAdmin &&
        <Button
          variant='outlined'
          onClick={deleteNotice}
        >
          {t('common.actions.delete')}
        </Button>
      }
    </TableCell>
  </TableRow>
}
