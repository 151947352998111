import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Tooltip } from '@mui/material'
import { type RootState } from '../../../interfaces/RootState'
import ConnectedNoticesList from './NoticesList'
import AddIcon from '@mui/icons-material/Add'
import { ToggleCreateNoticeModalAction } from '../../../redux/notices/notices-actions'
import CreateNoticeDialog from './CreateNoticeModal'
import { useTranslation } from 'react-i18next'

interface Props {
  isAdmin: boolean
}

function NoticesView({ isAdmin }: Props): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const NoticesContainer = ({ children }: { children: React.ReactChild[] }) =>
    <div style={{ /* backgroundColor: '#f9fbfd' */ }}>
      {children}
    </div>

  const openCreateNoticeModal = () => {
    dispatch(ToggleCreateNoticeModalAction())
  }

  const CreateNoticeButton = () =>
    <Tooltip title={t('notices.createNew')}>
      <Fab
        style={{ margin: '1em 2em' }}
        color='primary'
        onClick={openCreateNoticeModal}
      >
        <AddIcon />
      </Fab>
    </Tooltip>

  return <NoticesContainer>
    {isAdmin
      ? <>
      <CreateNoticeDialog />
      <CreateNoticeButton />
    </>
      : <></> }
    <ConnectedNoticesList />
  </NoticesContainer>
}

export default function ConnectedNoticesView(): JSX.Element {
  const isAdmin = useSelector((state: RootState) => state.vesimittari.auth.isAdmin)

  return <NoticesView isAdmin={isAdmin} />
}
