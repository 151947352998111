import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'

export default function Spinner() {
  const authLoading = useSelector((state: RootState) => state.vesimittari.auth.loading)
  const metersLoading = useSelector((state: RootState) => state.vesimittari.meterTable.loading)
  const noticesLoading = useSelector((state: RootState) => state.vesimittari.notices.loading)
  const loading = (): boolean =>
    authLoading > 0 ||
    metersLoading > 0 ||
    noticesLoading > 0

  return (
    <Backdrop sx={{ backgroundColor: 'rgba(255, 255, 255, .5)' }} open={loading()} data-testid='loading-spinner'>
      <CircularProgress color='primary'></CircularProgress>
    </Backdrop>
  )
}
