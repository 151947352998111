import Fuse from 'fuse.js'
import { minimumLength } from '../components/common/search-bar/SearchBar'
import { type Company } from '../redux/meters/meter-types'

export function updateIntegration(companies: Company[], companyId: string, data: Company): Company[] {
  return companies.map((company) =>
    company.id !== companyId ? company : data
  )
}

export function sortCompanies(companies: Company[]): Company[] {
  return companies.sort((a, b) => {
    const aName = a.name.toUpperCase()
    const bName = b.name.toUpperCase()
    return (aName < bName) ? -1 : (aName > bName) ? 1 : 0
  })
}

const searchCompaniesFuseOptions = {
  keys: ['name'],
  includeScore: true,
  minMatchCharLength: minimumLength
}

export function searchCompanies(companies: Company[], searchText: string): Company[] {
  if (!companies) {
    companies = []
  }
  if (searchText.length > 2) {
    companies = new Fuse(companies, searchCompaniesFuseOptions).search(searchText).map(e => e.item)
    return companies
  }
  return companies
}
