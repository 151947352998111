import { createAction } from '@reduxjs/toolkit'
import {
  type AcknowledgeMessagePayload,
  type MessageTypes
} from './messages-types'
import {
  ADD_MESSAGE,
  ACKNOWLEDGE_MESSAGE
} from '../../constants/action-types'

export const AddMessageAction = createAction(ADD_MESSAGE, (message: string, type: MessageTypes) => {
  return {
    payload: {
      message: {
        message,
        type,
        id: Date.now().toString()
      }
    }
  }
})
export const AcknowledgeMessageAction = createAction<AcknowledgeMessagePayload>(ACKNOWLEDGE_MESSAGE)
