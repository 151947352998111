import { type DownlinkMessagesState } from './downlink-messages-types'
import {
  AddDownlinkMessageAction,
  AddDownlinkMessageSuccessAction,
  AddDownlinkMessageFailedAction,
  FetchDownlinkMessagesForDeviceAction,
  FetchDownlinkMessagesForDeviceSuccessAction,
  FetchDownlinkMessagesForDeviceFailAction,
  CancelDownlinkMessageSuccessAction
} from './downlink-messages-actions'
import { type AnyAction } from 'redux'

export const initialDownlinkMessageState: DownlinkMessagesState = {
  downlinkMessagesByDeviceId: {}
}

export default function downlinkMessagesReducer(state: DownlinkMessagesState = initialDownlinkMessageState, action: AnyAction): DownlinkMessagesState {
  if (AddDownlinkMessageAction.match(action)) {
    return state
  }

  if (AddDownlinkMessageSuccessAction.match(action)) {
    return state
  }

  if (AddDownlinkMessageFailedAction.match(action)) {
    return state
  }

  if (FetchDownlinkMessagesForDeviceAction.match(action)) {
    return state
  }

  if (CancelDownlinkMessageSuccessAction.match(action)) {
    return state
  }

  if (FetchDownlinkMessagesForDeviceSuccessAction.match(action)) {
    return {
      ...state,
      downlinkMessagesByDeviceId: {
        ...state.downlinkMessagesByDeviceId,
        [action.payload.deviceId]: {
          sentMessages: action.payload.sentMessages,
          queuedMessages: action.payload.queuedMessages
        }
      }
    }
  }

  if (FetchDownlinkMessagesForDeviceFailAction.match(action)) {
    return state
  }

  return state
}
