import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles()(() => {
  return {
    search: {
      display: 'flex',
      border: 'solid #EFF0F3 1px',
      borderRadius: '5px',
      width: '30%',
      marginTop: '10px',
      marginBottom: '10px',
      marginRight: '10px',
      justifyContent: 'space-between',
      backgroundColor: '#ffffff'
    },
    searchIcon: {
      alignSelf: 'center',
      height: '100%'
    },
    searchRoot: {
      fontSize: '25px',
      padding: '5px',
      color: '#6e84a3'
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
      fontSize: '16px'
    },
    inputInput: {
      padding: '5px'
    }
  }
})

export const minimumLength: number = 3

export default function SearchBar({ searchValue, onChange }) {
  const { classes } = useStyles()
  const t = useTranslation().t
  const search = t('search') ?? ''

  return (
    <div className={classes.search}>
      <InputBase
        data-testid="input-base"
        placeholder={search + '...'}
        onChange={onChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': search }} value={searchValue}
      />
      <div className={classes.searchIcon}>
        <SearchIcon classes={{ root: classes.searchRoot }} />
      </div>
    </div>
  )
}
