import { createAction } from '@reduxjs/toolkit'
import {
  GROUPS_RECEIVED,
  FETCH_GROUPS,
  FETCH_GROUPS_FAIL,
  EMAIL_ENABLED_TOGGLED,
  FETCH_CUSTOMER_ALARM_SETTINGS,
  CUSTOMER_ALARM_SETTINGS_FETCHED,
  FETCH_CUSTOMER_ALARM_SETTINGS_FAILED,
  SAVE_CUSTOMER_ALARMS,
  SAVE_CUSTOMER_ALARMS_FAILED,
  SAVE_CUSTOMER_ALARMS_SUCCESS,
  EMAIL_RECIPIENTS_ADD,
  EMAIL_RECIPIENTS_REMOVE,
  EMAIL_INTERVAL_CHANGED,
  SMS_ENABLED_TOGGLED,
  TEMPERATURE_ALERTS_ENABLED_TOGGLED,
  UPDATE_TEMPERATURE_ALERT_LOWER_LIMIT,
  SMS_RECIPIENTS_ADD,
  SMS_RECIPIENTS_REMOVE,
  SMS_INTERVAL_CHANGED,
  UPDATE_SMS_RECIPIENT_TEXT_BOX,
  UPDATE_TEMPERATURE_ALERT_UPPER_LIMIT
} from '../../constants/action-types'
import { type FetchGroupsSuccessPayload, type FetchGroupsFailPayload, type FetchCustomerAlarmsPayload, type FetchCustomerAlarmsSuccessPayload } from './settings-type'
export const FetchGroupsAction = createAction<void>(FETCH_GROUPS)
export const GroupsReceivedAction = createAction<FetchGroupsSuccessPayload>(GROUPS_RECEIVED)
export const FetchGroupsFailAction = createAction<FetchGroupsFailPayload>(FETCH_GROUPS_FAIL)
export const ToggleEnableEmailAction = createAction<boolean>(EMAIL_ENABLED_TOGGLED)
export const FetchCustomerAlarmsAction = createAction<FetchCustomerAlarmsPayload>(FETCH_CUSTOMER_ALARM_SETTINGS)
export const FetchCustomerAlarmsSuccessAction = createAction<FetchCustomerAlarmsSuccessPayload>(CUSTOMER_ALARM_SETTINGS_FETCHED)
export const FetchCustomerAlarmsFailAction = createAction<FetchGroupsFailPayload>(FETCH_CUSTOMER_ALARM_SETTINGS_FAILED)
export const SaveCustomerAlarmsAction = createAction<FetchCustomerAlarmsSuccessPayload>(SAVE_CUSTOMER_ALARMS)
export const SaveCustomerAlarmsSuccessAction = createAction<FetchCustomerAlarmsSuccessPayload>(SAVE_CUSTOMER_ALARMS_SUCCESS)
export const SaveCustomerAlarmsFailedAction = createAction<FetchGroupsFailPayload>(SAVE_CUSTOMER_ALARMS_FAILED)
export const AddEmailRecipientAction = createAction<string>(EMAIL_RECIPIENTS_ADD)
export const RemoveEmailRecipientAction = createAction<string>(EMAIL_RECIPIENTS_REMOVE)
export const ChangeEmailIntervalAction = createAction<number>(EMAIL_INTERVAL_CHANGED)
export const ToggleSMSEnabledAction = createAction<boolean>(SMS_ENABLED_TOGGLED)
export const ToggleTemperatureAlertsEnabledAction = createAction<boolean>(TEMPERATURE_ALERTS_ENABLED_TOGGLED)
export const UpdateTemperatureAlertLowerLimitAction = createAction<number>(UPDATE_TEMPERATURE_ALERT_LOWER_LIMIT)
export const UpdateTemperatureAlertUpperLimitAction = createAction<number>(UPDATE_TEMPERATURE_ALERT_UPPER_LIMIT)
export const AddSMSRecipientAction = createAction<string>(SMS_RECIPIENTS_ADD)
export const RemoveSMSRecipientAction = createAction<string>(SMS_RECIPIENTS_REMOVE)
export const UpdateSMSRecipientTextBoxAction = createAction<string>(UPDATE_SMS_RECIPIENT_TEXT_BOX)
export const ChangeSMSIntervalAction = createAction<number>(SMS_INTERVAL_CHANGED)
