import { createAction } from '@reduxjs/toolkit'
import {
  COMPANIES_RECEIVED,
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAIL,
  SEARCH_COMPANIES_STRING_CHANGED,
  UPDATE_INTEGRATION
} from '../../constants/action-types'
import {
  type AdminActionsFailPayload,
  type FetchCompaniesSuccessPayload,
  type SearchCompaniesSuccessPayload,
  type UpdateIntegrationActionPayload
} from './admin-types'

export const FetchCompaniesAction = createAction<void>(FETCH_COMPANIES)
export const FetchCompaniesSuccessAction = createAction<FetchCompaniesSuccessPayload>(COMPANIES_RECEIVED)
export const FetchCompaniesFailAction = createAction<AdminActionsFailPayload>(FETCH_COMPANIES_FAIL)
export const SearchCompaniesAction = createAction<SearchCompaniesSuccessPayload>(SEARCH_COMPANIES_STRING_CHANGED)
export const UpdateIntegrationAction = createAction<UpdateIntegrationActionPayload>(UPDATE_INTEGRATION)
