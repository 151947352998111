// Auth actions
import {
  LOCK_ERROR,
  FETCHING_USER_INFORMATION
} from '../../constants/action-types'
import { type FetchUserDataRequest, type LockError } from './auth-types'

export function lockError(err): LockError {
  return {
    type: LOCK_ERROR,
    err
  }
}

export function fetchingUserInformation(): FetchUserDataRequest {
  return {
    type: FETCHING_USER_INFORMATION
  }
}
