import i18next from 'i18next'
import translations from './assets/translations.json'

export default function createI18nInstance(lng: string) {
  return i18next.createInstance({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    defaultNS: ['general'], // This is here only to solve a bug: https://github.com/i18next/i18next/issues/1855
    resources: translations,
    lng, // if you're using a language detector, do not define the lng option
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    nsSeparator: '.'
  }, (err, t) => {
    if (err) { console.log('something went wrong loading', err); return }
    t('key') // -> same as i18next.t
  })
}
