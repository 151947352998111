import { takeLatest, put, call, select, all } from 'redux-saga/effects'
import { API_URL } from '../../constants/urls'
import { FETCHING_USER_INFORMATION } from '../../constants/action-types'
import { LogoutSuccessAction, UserReceivedAction, UserReceivedFailureAction } from '../auth/auth-actions'
import selectIdToken from '../selectors/select-id-token'
import { FetchNoticesAction } from '../notices/notices-actions'

export function * checkUserAPICall() {
  try {
    const idToken = yield select(selectIdToken)
    const userCollection = yield call(async () => {
      return await fetch(`${API_URL}/company/checkUser`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).then(async response => await response.json())
    })
    yield put(FetchNoticesAction())
    yield put(UserReceivedAction(userCollection))
  } catch (error: any) {
    yield put(UserReceivedFailureAction(error))
    yield put(LogoutSuccessAction())
  }
}

export function * checkUserStart() {
  yield takeLatest(FETCHING_USER_INFORMATION, checkUserAPICall)
}

export function * authSagas() {
  yield all([call(checkUserStart)])
}
