import React from 'react'
import { connect } from 'react-redux'

import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl } from '@mui/material'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { withTranslation } from 'react-i18next'

import {
  closeImportDevicesForProvisionDialog,
  uploadDevicesForProvisionCsv,
  importDevicesForProvisionFileChanged,
  importDevicesForProvisionProfileIdChanged
} from '../../../redux/devices-for-provision/devices-for-provision-actions'

class ImportDevicesForProvisionDialog extends React.Component {
  closeDialog () {
    this.props.dispatch(closeImportDevicesForProvisionDialog())
  }

  disabled () {
    return !this.props.importDevicesForProvisionFile || !this.props.importDevicesForProvisionProfileId
  }

  import () {
    const file = this.props.importDevicesForProvisionFile
    const formData = new FormData()
    formData.append('file', file)
    formData.append('profileId', this.props.importDevicesForProvisionProfileId)
    this.props.dispatch(uploadDevicesForProvisionCsv(formData))
  }

  onFileChange = event => {
    const file = Array.from(event.target.files)[0]
    this.props.dispatch(importDevicesForProvisionFileChanged(file))
  }

  onProfileIdChange = val => {
    this.props.dispatch(importDevicesForProvisionProfileIdChanged(val))
  }

  getActions () {
    const { t } = this.props

    return [
      <Button key='cancel' color='primary' onClick={() => { this.closeDialog() }}>{t('common.actions.cancel')}</Button>,
      <Button
        key='import'
        color='secondary'
        disabled={this.disabled()}
        onClick={() => { this.import() }}
        secondary
      >{t('settings_dialog.import')}
      </Button>
    ]
  }

  render () {
    const { t } = this.props

    return (
      <Dialog
        open={this.props.importDevicesForProvisionDialogOpen}
      >
        <DialogTitle>{t('settings_dialog.import_meters_to_activate')}</DialogTitle>
        <DialogContent>
          <Grid item container>
            <Grid item xs>
              <Button
                component='label'
                color='secondary'
              >
                {t('select_csv_file')}
                <input id='csv-file-input' type='file' style={{ display: 'none' }} onChange={this.onFileChange} />
              </Button>
              {this.props.importDevicesForProvisionFile &&
                <div>{`${t('selected_file')}: ${this.props.importDevicesForProvisionFile.name}`}</div>}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs>
              {this.props.loadingDeviceProfiles &&
                <span>{t('settings_dialog.downloading_profiles')}</span>}
              {!this.props.loadingDeviceProfiles && (
                <FormControl style={{ width: '100%' }}>
                  <Select
                    fullWidth
                    value={this.props.importDevicesForProvisionProfileId}
                    onChange={(event) => {
                      this.onProfileIdChange(event.target.value)
                    }}
                    label={t('settings_dialog.select_profile')}
                    variant='standard'
                  >
                    {this.props.deviceProfiles.map((c, i) => <MenuItem key={c.id + c.name + c.typeMAC + i} value={c.id}>{c.name}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps (state) {
  const { devicesForProvisionReducer } = state.vesimittari
  const {
    importDevicesForProvisionDialogOpen,
    importDevicesForProvisionFile,
    importDevicesForProvisionProfileId,
    deviceProfiles,
    loadingDeviceProfiles
  } = devicesForProvisionReducer
  return {
    importDevicesForProvisionDialogOpen,
    importDevicesForProvisionFile,
    importDevicesForProvisionProfileId,
    deviceProfiles,
    loadingDeviceProfiles
  }
}

export default withTranslation()(connect(mapStateToProps)(ImportDevicesForProvisionDialog))
