import {
  FetchDataArrayAction,
  FetchDataArrayFailedAction,
  FetchDataArraySuccessAction,
  FetchDataSuccessAction,
  FetchMeterHistoryValuesAction,
  FetchMeterHistoryValuesSuccessAction
} from './meter-data-actions'
import { type AnyAction } from 'redux'
import { groupBy } from '../../utils/utils'
import moment from 'moment'
import { RestorePersistedDataAction } from '../../persistence-middleware'
import { type MeterHistoryValue } from './meter-data-types'

interface AllHistoryGraphData {
  water: UsageData
  temperature: UsageData
}

const initialHistoryGraphData = {
  water: { labels: [], datasets: [] },
  temperature: { labels: [], datasets: [] }
}

interface MeterDataState {
  values: any /* Record<string, unknown> */
  selectionData: null
  fetchingHistoryGraphData: boolean
  historyGraphData: AllHistoryGraphData
}

const initialState: MeterDataState = {
  values: {},
  selectionData: null,
  fetchingHistoryGraphData: false,
  historyGraphData: initialHistoryGraphData
}

interface UsageData {
  labels: string[]
  datasets: Array<{
    label: string
    backgroundColor: string
    borderRadius: number
    borderSkipped: 'start' | 'end' | 'left' | 'right' | 'bottom' | 'top' | 'middle' | boolean
    barPercentage: number
    fill?: boolean
    data: number[]
  }>
}

const litersToCube = (input: number): number => {
  // Get rid of the last decimal. So that the accuracy is 10s liters. ie 1.413m^3 -> 1.410m^3 as the most accurate meters have 10 liter pulses.
  return Math.round(input / 10) / 100
}

const groupWaterHistoryGraphData = (data: MeterHistoryValue[], scale: string) => {
  let format
  switch (scale) {
    case 'single':
      format = 'DD.MM.YYYY HH:mm'
      break
    case 'day':
      format = 'DD.MM.YYYY'
      break
    case 'week':
      format = 'WW/YYYY'
      break
    case 'month':
      format = 'MM.YYYY'
      break
    default:
      format = 'DD.MM.YYYY'
  }

  const groupedByTime = groupBy(data, function(item) {
    return moment.unix(item.time).format(format)
  })

  const usageData: UsageData = {
    labels: [],
    datasets: [
      {
        label: 'Vedenkulutus',
        backgroundColor: '#2B75FF',
        borderRadius: 12,
        borderSkipped: 'start',
        barPercentage: 0.4,
        data: []
      }
    ]
  }

  if (data.length === 0) {
    return usageData
  }

  // this is the last value before the time period
  const firstKey = groupedByTime.keys().next().value
  const usages = groupedByTime.get(firstKey)?.map(i => i.waterUsed).filter((wu) => wu !== null)
  let prevMax = usages ? Math.max(...usages) : 0

  groupedByTime.delete(firstKey)

  groupedByTime.forEach(function(value, key) {
    usageData.labels.push(key)

    const usages = value.map(i => i.waterUsed).filter(Boolean)
    const currentMax = Math.max(...usages)

    const usageInTimeFrame = currentMax - prevMax
    prevMax = currentMax

    usageData.datasets[0].data.push(usageInTimeFrame)
  })

  usageData.datasets[0].data = usageData.datasets[0].data.map((d) => litersToCube(d))
  return usageData
}

const groupTemperatureHistoryGraphData = (data: MeterHistoryValue[]) => {
  const usageData: UsageData = {
    labels: [],
    datasets: [
      {
        label: 'Lämpötila',
        backgroundColor: '#2B75FF',
        borderRadius: 12,
        borderSkipped: 'start',
        barPercentage: 0.4,
        fill: false,
        data: []
      }
    ]
  }

  data.forEach((e) => {
    usageData.labels.push(moment.unix(e.time).format('DD.MM.YYYY HH:mm'))

    usageData.datasets[0].data.push(e.temperature)
  })

  return usageData
}

export default function metersReducer(state: MeterDataState = initialState, action: AnyAction): MeterDataState {
  if (FetchDataArrayAction.match(action)) {
    return state
  }

  if (FetchDataArrayFailedAction.match(action)) {
    return state
  }

  if (FetchDataArraySuccessAction.match(action)) {
    const valuesMap = state.values
    action.payload.datas.forEach((item) => {
      if (item) {
        valuesMap[item.internalDeviceId] = item
      }
    })

    return Object.assign({}, state, {
      values: valuesMap
    })
  }

  if (FetchDataSuccessAction.match(action)) {
    const values = state.values
    values[action.payload.id] = action.payload.data[0]

    return Object.assign({}, state, {
      selectionData: action.payload.data,
      values
    })
  }

  if (FetchMeterHistoryValuesAction.match(action)) {
    return {
      ...state,
      fetchingHistoryGraphData: true,
      historyGraphData: initialHistoryGraphData
    }
  }

  if (FetchMeterHistoryValuesSuccessAction.match(action)) {
    return {
      ...state,
      fetchingHistoryGraphData: false,
      historyGraphData: {
        water: groupWaterHistoryGraphData(action.payload.data, action.payload.scale),
        temperature: groupTemperatureHistoryGraphData(action.payload.data)
      }
    }
  }

  if (RestorePersistedDataAction.match(action)) {
    return {
      ...state,
      values: JSON.parse(action.payload.data) ?? {}
    }
  }

  return state
}
