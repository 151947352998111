import * as React from 'react'
import { useEffect } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { type Company } from '../../../../redux/meters/meter-types'
import { type TFunction } from 'i18next'
import { useAlarmTypeSelectOptions } from '../AlarmTypeSelectOptions'
import { type VesimittariDispatch } from '../../../../configureStore'
import { type CompanyAlarm } from '../../../../redux/companies/companies-types'
import { SetCompanyAlarmAction } from '../../../../redux/meter-table/meter-table-actions'
import { type AlarmKey, FLOW_LIMIT, getAlarmTypeByKey } from '../../../../redux/alarm-settings/alarm-types'

interface SetCompanyAlarmFormProps {
  dispatch: VesimittariDispatch
  companies: Company[]
  companyAlarm: CompanyAlarm
  t: TFunction
}

export function SetCompanyAlarmForm({
  dispatch,
  companies,
  companyAlarm,
  t
}: SetCompanyAlarmFormProps) {
  const alarmTypeSelectOptions = useAlarmTypeSelectOptions()
  const [isUpdateFlowLimitEnabled, setIsUpdateFlowLimitEnabled] = React.useState(false)

  useEffect(() => {
    if (!companyAlarm.companyId) {
      dispatch(
        SetCompanyAlarmAction({
          companyId: companies[0].id,
          alarmType: getAlarmTypeByKey(alarmTypeSelectOptions[0].value),
          state: false,
          maxFlowPerHour: undefined
        })
      )
    }
  }, [companies, companyAlarm, dispatch, alarmTypeSelectOptions])

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    const company = companies.find(
      (company) => company.id === event.target.value
    )
    if (company) {
      dispatch(
        SetCompanyAlarmAction({ ...companyAlarm, companyId: company.id })
      )
    }
  }

  const handleAlarmStateChange = (
    event: React.MouseEvent<HTMLElement>,
    newState: string
  ) => {
    dispatch(
      SetCompanyAlarmAction({ ...companyAlarm, state: newState === 'true' })
    )
  }

  const handleAlarmTypeChange = (event: SelectChangeEvent<AlarmKey>) => {
    dispatch(
      SetCompanyAlarmAction({ ...companyAlarm, alarmType: getAlarmTypeByKey(event.target.value) })
    )
  }

  const handleMaxFlowPerHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdateFlowLimitEnabled) {
      dispatch(
        SetCompanyAlarmAction({
          ...companyAlarm,
          maxFlowPerHour: event.target.value === '' ? null : parseInt(event.target.value)
        })
      )
    }
  }

  const handleIsUpdateFlowLimitEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdateFlowLimitEnabled(event.target.checked)
    dispatch(
      SetCompanyAlarmAction({ ...companyAlarm, maxFlowPerHour: event.target.checked ? null : undefined })
    )
  }

  const getCompanySelect = () => {
    if (companies && companies.length === 1) {
      return (
        <Input
          data-testid="single-company-input"
          type="hidden"
          value={companies[0]}
        />
      )
    }
    return (
      <>
        <FormControl fullWidth margin="dense">
          <FormLabel id="company-select-label">
            {t('classifications.company')}
          </FormLabel>
          <Select
            style={{ width: '30rem' }}
            labelId="company-select-label"
            id="company-select"
            data-testid="company-select"
            value={
              companyAlarm.companyId ||
              (companies && companies.length > 0 ? companies[0].id : '')
            }
            onChange={handleCompanyChange}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )
  }

  const getMaxFlowPerHourInput = () => {
    if (companyAlarm.alarmType === FLOW_LIMIT) {
      return (
        <FormControl margin="dense">
          <FormLabel id="max-flow-per-hour-label">
            {t('company_alarm_dialog.max_flow_per_hour')}
          </FormLabel>
          { isUpdateFlowLimitEnabled && (
            <TextField
              variant="outlined"
              data-testid="max-flow-per-hour-input"
              type="number"
              size="small"
              value={companyAlarm.maxFlowPerHour ?? ''}
              onChange={handleMaxFlowPerHourChange}
            />
          )}
          <FormControlLabel
            control={<Checkbox onChange={handleIsUpdateFlowLimitEnabledChange} />}
            label={t('company_alarm_dialog.update_flow_limit_rate')}
            data-testid="update-flow-limit-rate-checkbox"
          />
        </FormControl>
      )
    }
  }

  return (
    <>
      {getCompanySelect()}
      <FormControl
        fullWidth
        margin="dense"
        data-testid="set-company-alarm-form"
      >
        <FormLabel id="alarm-select-label">
          {t('company_alarm_dialog.alarm_types')}
        </FormLabel>
        <Select
          style={{ width: '30rem' }}
          labelId="alarm-select-label"
          id="alarm-select"
          value={companyAlarm.alarmType?.alarmKey || alarmTypeSelectOptions[0].value as AlarmKey}
          onChange={handleAlarmTypeChange}
          data-testid="alarm-type-select"
        >
          {alarmTypeSelectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      { getMaxFlowPerHourInput() }
      <FormControl fullWidth margin="dense">
        <FormLabel id="alarm-select-label">
          {t('company_alarm_dialog.alarm_state')}
        </FormLabel>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={companyAlarm.state.toString()}
          exclusive
          onChange={handleAlarmStateChange}
          data-testid="alarm-state"
        >
          <ToggleButton value="true" data-testid="on-button">
            {t('company_alarm_dialog.on')}
          </ToggleButton>
          <ToggleButton value="false" data-testid="off-button">
            {t('company_alarm_dialog.off')}
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </>
  )
}
