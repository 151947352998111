import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Select, MenuItem } from '@mui/material'
import { DownlinkActionButtonAndDialog } from './DownlinkActionButtonAndDialog'

interface ValueOptionsProps {
  value: number
  label: string
}

interface DownlinkPayloadProps {
  currentValue: number | boolean
  valueOptions: ValueOptionsProps[]
  valueText: string
  setValueText: string
  confirmText: string
  setSendDownlinkAction: (value: any) => void
  enabled: boolean
}

export function SetDownlinkExtraPayload({ currentValue, valueOptions, valueText, setValueText, confirmText, setSendDownlinkAction, enabled }: DownlinkPayloadProps): JSX.Element {
  const [value, setValue] = React.useState(currentValue)

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  return (
    <div>
      <FormControlLabel
        style={{ paddingLeft: '12px' }}
        control={
          <Select labelId="label" id="select" value={value} onChange={e => { handleChange(e) }}>
            {valueOptions.map(v => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
          </Select>}
        disabled={!enabled}
        label={valueText}
      />
      <DownlinkActionButtonAndDialog
        enabled={enabled}
        actionText={setValueText}
        confirmDialogText={confirmText}
        sendDownlinkMessage={() => { setSendDownlinkAction(value) }}
      />
    </div>
  )
}
