import { type i18n } from 'i18next'
import { type AlarmType, type CompanyAlarmKey } from '../alarm-settings/alarm-types'

export enum CsvType {
  CSV = 'CSV',
  CSV_FIN = 'CSV_FIN',
  COMPANY_SPECIFIC = 'COMPANY_SPECIFIC',
  DATE_SPECIFIC = 'DATE_SPECIFIC'
}

export interface DownloadFileForUserPayload {
  downloadType: CsvType
  downloadDateSelected: Date
}

export interface DownloadFileForAdminPayload {
  downloadDateSelected: Date
  downloadType: CsvType
  companyId: string
}

export interface DownloadFileWithinSpecificDatesPayload {
  startDateSelected: Date | undefined
  endDateSelected: Date | undefined
  downloadType: CsvType
}

export interface DownloadFileWithinSpecificDatesForAdminPayload {
  startDateSelected: Date | undefined
  endDateSelected: Date | undefined
  downloadType: CsvType
  companyId: string
}

export interface DownloadFileForUserFailedPayload {
  error: string
}

export interface InfoDialogs {
  lora: boolean
  sigfox: boolean
  saveEnabled: boolean
  errorText: string
  selectedCompany: SelectedCompany
}

export interface SelectedCompany {
  id: string
  lrcAsKey: string
  asId: string
  canUpdateInterval: boolean
}

export interface CompaniesState {
  createCompanyDialogOpen: boolean
  editCompanyDialogOpen: boolean
  organization: Organization
  editedOrganization: EditedOrganization
  removeCompanyDialogOpen: boolean
  parentCompany: number | undefined
  infoDialogs: InfoDialogs
}

export interface OpenEditCompanyDialogPayload {
  company: any
}

export interface Organization {
  name: string
  postalCode: string
  streetAddress: string
  town: string
  vatId: string
  lrcAsKey: string
  asId: string
  alertsEnabled: boolean
  alertRecipients: any[]
  postalDistrict: string
}

export interface EditedOrganization {
  id: string
  name: string
  postalCode: string
  streetAddress: string
  town: string
  vatId: string
  alertsEnabled: boolean
  alertRecipients: any[]
  postalDistrict: string
  lrcAsKey: string
}

export interface UpdateCompanyPayload {
  id: any
  data: any
  notAssignedId?: any
  notAssignedData?: any
}

export interface CreateCompanyPayload {
  data: any
}

export interface DeleteCompanyPayload {
  companyId: number
}

export interface DownloadFileForAdminPayload2 {
  companyId: number
  useGeneric: any
}

export interface CompanyAlarm {
  companyId: string | null
  alarmType: AlarmType | null
  state: boolean
  maxFlowPerHour: number | null | undefined
}

export interface SetCompanyAlarmBody {
  alarmType: CompanyAlarmKey | null
  state: boolean
  maxFlowPerHour?: number | null
}

export interface UpdateCompanyAlarmPayload {
  companyAlarm: CompanyAlarm
  i18n: i18n
}
