import { takeLatest, put, call, select, all } from 'redux-saga/effects'
import { API_URL } from '../../constants/urls'
import { REMOVE_MAIN_WATER_METER_TO_LABEL_START, ASSOCIATE_MAIN_METER_TO_LABEL_START, FETCH_MAIN_METERS } from '../../constants/action-types'
import {
  FetchLabelsWithMainMetersSuccessfulAction, FetchLabelsWithMainMetersFailedAction, FetchLabelsWithMainMetersAction, AssociateMainWaterMeterToLabelFailed, AssociateMainWaterMeterToLabelSuccessful,
  RemoveMainWaterMeterToLabelSuccessful, RemoveMainWaterMeterToLabelFailed
} from './main-meters-actions'
import selectIdToken from '../../redux/selectors/select-id-token'

export function * fetchMainWaterMeters() {
  try {
    const idToken = yield select(selectIdToken)
    const mainMetersData = yield call(async () => {
      return await fetch(`${API_URL}/label`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).then(async response => await response.json())
    })
    yield put(FetchLabelsWithMainMetersSuccessfulAction(mainMetersData))
  } catch (error: any) {
    yield put(FetchLabelsWithMainMetersFailedAction(error))
  }
}

export function * associateMainMetersToLabels(labelsWithMainMeters) {
  try {
    const idToken = yield select(selectIdToken)
    const updatedLabel = yield call(async () => {
      return await fetch(`${API_URL}/label/${labelsWithMainMeters.payload.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(labelsWithMainMeters.payload)
      })
    })
    yield put(AssociateMainWaterMeterToLabelSuccessful(updatedLabel))
    yield put(FetchLabelsWithMainMetersAction())
  } catch (error: any) {
    yield put(AssociateMainWaterMeterToLabelFailed(error))
  }
}

export function * removeMainWaterMetersToLabels(labelsWithMainMeters) {
  try {
    const idToken = yield select(selectIdToken)
    const updatedData = yield call(async () => {
      return await fetch(`${API_URL}/label/removeMainWaterMeter/${labelsWithMainMeters.payload.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(labelsWithMainMeters.payload)
      })
    })
    yield put(RemoveMainWaterMeterToLabelSuccessful(updatedData))
    yield put(FetchLabelsWithMainMetersAction())
  } catch (error: any) {
    yield put(RemoveMainWaterMeterToLabelFailed(error))
  }
}
export function * fetchMainMetersStart() {
  yield takeLatest(FETCH_MAIN_METERS, fetchMainWaterMeters)
}

export function * associateMainWaterMeterToLabelStart() {
  yield takeLatest(ASSOCIATE_MAIN_METER_TO_LABEL_START, associateMainMetersToLabels)
}

export function * removeMainWaterMeterToLabelStart() {
  yield takeLatest(REMOVE_MAIN_WATER_METER_TO_LABEL_START, removeMainWaterMetersToLabels)
}
export function * mainMetersSagas() {
  yield all([call(fetchMainMetersStart), call(associateMainWaterMeterToLabelStart), call(removeMainWaterMeterToLabelStart)])
}
