import { AddMessageAction } from '../messages/messages-actions'
import { MessageTypes } from '../messages/messages-types'

export class ApiError extends Error {
  forceLogout: boolean
  constructor(message: string, forceLogout: boolean = false) {
    super(message)
    this.name = 'ApiError'
    this.forceLogout = forceLogout
  }
}

export async function handleErrors(response: Response) {
  if (!response.ok) {
    const text = await response.text()
    const json = text ? JSON.parse(text) : null
    const error = json ? json.error : ''

    const err = `Status: ${response.status}\n` + error
    if (response.status === 401) {
      throw new ApiError(err + '\n Istuntosi on mahdollisesti vanhentunut. Kirjaudu sisään uudelleen.', true)
    }
    throw new ApiError(err)
  }
  return response
}

export function errorAlert(string: string, error: any, dispatch: any) {
  dispatch(AddMessageAction(string + '\n' + error, MessageTypes.error))
}
