import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type FilterOption from '../../../interfaces/FilterOption'
import { StyledChip, ActiveFiltersContainer } from './Filters.style'
import { type RootState } from '../../../interfaces/RootState'
import ClearIcon from '@mui/icons-material/Clear'
import { RemoveActiveFilterAction, SetAlarmsFilterAction } from '../../../redux/meter-table/meter-table-actions'

const ActiveAlarmFilter = () => {
  const dispatch = useDispatch()

  const data = useSelector((state: RootState) => state.vesimittari.data)

  const handleDelete = (filter: FilterOption) => {
    dispatch(RemoveActiveFilterAction({ data: filter }))
    dispatch(SetAlarmsFilterAction({ data: false }))
  }

  return (
    <ActiveFiltersContainer>
      {data.activeFilters.map((i: FilterOption) => (
        <StyledChip deleteIcon={<ClearIcon/>} key={i.name} label={i.name} onDelete={() => { handleDelete(i) }} />
      ))}
    </ActiveFiltersContainer>)
}

export default ActiveAlarmFilter
