import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { CreateNewNoticeAction, ToggleCreateNoticeModalAction } from '../../../redux/notices/notices-actions'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
}

function CreateNoticeDialog({ open }: Props): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [newNoticeContents, setNewNoticeContents] = React.useState<string>('')

  const closeModal = () => {
    dispatch(ToggleCreateNoticeModalAction())
    setNewNoticeContents('')
  }

  const saveNotice = () => {
    dispatch(CreateNewNoticeAction(newNoticeContents))
    closeModal()
  }

  const onClose = (_: React.SyntheticEvent, reason: string) => {
    // Called when the user clicks outside of modal
    if (reason === 'backdropClick') {
      closeModal()
    }
  }

  return <Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={onClose}
  >
    <DialogTitle style={{ paddingRight: '12px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <b>{t('notices.createNew')}</b>
        <div style={{ cursor: 'pointer' }}>
          <CloseIcon onClick={closeModal} />
        </div>
      </div>
    </DialogTitle>

    <DialogContent>
      <TextField
        multiline
        fullWidth
        value={newNoticeContents}
        minRows={3}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNewNoticeContents(e.currentTarget.value) }}
      />
    </DialogContent>

    <DialogActions>
      <Button
        variant='contained'
        onClick={saveNotice}
      >
        {t('common.actions.save')}
      </Button>
    </DialogActions>

  </Dialog>
}

export default function ConnectedCreateNoticeDialog(): JSX.Element {
  const createNoticeModalOpen = useSelector((state: RootState) => state.vesimittari.notices.createNoticeModalOpen)

  return <CreateNoticeDialog open={createNoticeModalOpen} />
}
