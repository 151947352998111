import React from 'react'
import { Button, TableRow, TableCell, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { StyledExpandedTableCellHead, StyledExpandedTableRowBody } from './MainMeters.style'
import { useDispatch, useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ExpandOneMainMeterRowAction, RemoveMainWaterMeterToLabelAction } from '../../../redux/main-meters/main-meters-actions'
import { type RootState } from '../../../interfaces/RootState'
import { useTranslation } from 'react-i18next'

export default function MainMeterRow({ id, labelsWithMainMeters, index, value, meterSigfoxId, waterValue, otherReadings }) {
  const dispatch = useDispatch()
  const t = useTranslation().t
  const mainWaterId = useSelector((state: RootState) => state.vesimittari.mainMetersReducer.mainWaterId)
  const [isRowExpanded, setIsRowExpanded] = React.useState(false)
  const [removeConfirmation, setRemoveConfirmation] = React.useState(false)

  const removeConfirmationDialogClose = () => {
    setRemoveConfirmation(false)
  }

  const removeButtonHandler = () => {
    setRemoveConfirmation(!removeConfirmation)
  }
  const expandMainMeterRow = () => {
    dispatch(ExpandOneMainMeterRowAction(id))
    setIsRowExpanded(!isRowExpanded)
  }

  const removeMainWaterMeterToLabelHandler = () => {
    dispatch(RemoveMainWaterMeterToLabelAction(labelsWithMainMeters))
    setRemoveConfirmation(false)
  }
  if (isRowExpanded && mainWaterId !== id) {
    setIsRowExpanded(false)
  }
  return (
    <>
      <TableRow key={id}>
        <TableCell>{index}</TableCell>
        <TableCell>{value}</TableCell>
        <TableCell>{meterSigfoxId}</TableCell>
        <TableCell>{waterValue}</TableCell>
        <TableCell>{otherReadings}</TableCell>
        <TableCell>
          <Button onClick={() => { expandMainMeterRow() }} color="inherit">
            {isRowExpanded ? <ExpandMore/> : <ExpandLess/>}
          </Button>
        </TableCell>
      </TableRow>
      {isRowExpanded &&
        <>
          <TableRow>
            <StyledExpandedTableCellHead>{t('main_meters.label')}</StyledExpandedTableCellHead>
            <StyledExpandedTableCellHead/>
            <StyledExpandedTableCellHead>{t('main_meters.main_meter_id')}</StyledExpandedTableCellHead>
            <StyledExpandedTableCellHead/>
            <StyledExpandedTableCellHead/>
            <StyledExpandedTableCellHead>{t('main_meters.feature')}</StyledExpandedTableCellHead>
          </TableRow>
          <StyledExpandedTableRowBody>
            <TableCell>{value}</TableCell>
            <TableCell/>
            <TableCell>{meterSigfoxId}</TableCell>
            <TableCell/>
            <TableCell/>
            <TableCell onClick={() => { removeButtonHandler() }}>
              <Button color="inherit">x {t('main_meters.remove')}</Button>
            </TableCell>
          </StyledExpandedTableRowBody>
        </>
      }
      <Dialog
        open={removeConfirmation}
        onClose={removeConfirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('main_meters.remove_confirmation')}</DialogTitle>
        <DialogActions>
          <Button color="inherit" onClick={removeConfirmationDialogClose}>
            {t('main_meters.cancel')}
          </Button>
          <Button color="inherit" onClick={removeMainWaterMeterToLabelHandler} autoFocus>
            {t('main_meters.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
