import {
  FetchLabelsWithMainMetersAction, FetchLabelsWithMainMetersSuccessfulAction, FetchLabelsWithMainMetersFailedAction, AssociateMainWaterMeterToLabelAction, AssociateMainWaterMeterToLabelFailed,
  AssociateMainWaterMeterToLabelSuccessful, ChooseLabelValue, ChooseMainWaterMeter, RemoveMainWaterMeterToLabelAction, RemoveMainWaterMeterToLabelSuccessful, RemoveMainWaterMeterToLabelFailed, ExpandOneMainMeterRowAction, ToggleMainMetersModalAction
} from './main-meters-actions'
import { type AnyAction } from 'redux'
import { type MainMetersState, type FetchLabelsWithMainMetersSuccessfulPayload } from './main-meters-types'
const initialState: MainMetersState = {
  isFetchingLabelsWithMainMeters: false,
  mainWaterMeters: null,
  errorMessage: '',
  isAssociatingMainWaterMeterToLabel: false,
  isRemovingMainWaterMeterToLabel: false,
  mainWaterId: '',
  labelsWithMainMeters: {} as FetchLabelsWithMainMetersSuccessfulPayload,
  mainMetersModalOpen: false
}

export default function mainMetersReducer(state: MainMetersState = initialState, action: AnyAction): MainMetersState {
  if (FetchLabelsWithMainMetersAction.match(action)) {
    return Object.assign({}, state, {
      isFetchingLabelsWithMainMeters: true
    })
  }

  if (FetchLabelsWithMainMetersSuccessfulAction.match(action)) {
    return Object.assign({}, state, {
      mainWaterMeters: action.payload,
      isFetchingLabelsWithMainMeters: false
    })
  }

  if (FetchLabelsWithMainMetersFailedAction.match(action)) {
    return Object.assign({}, state, {
      isFetchingLabelsWithMainMeters: false,
      errorMessage: 'Päävesimittareiden haku epäonnistui'
    })
  }

  if (AssociateMainWaterMeterToLabelAction.match(action)) {
    return Object.assign({}, state, {
      isAssociatingMainWaterMeterToLabel: true
    })
  }

  if (AssociateMainWaterMeterToLabelSuccessful.match(action)) {
    return Object.assign({}, state, {
      isAssociatingMainWaterMeterToLabel: false,
      labelsWithMainMeters: action.payload
    })
  }

  if (AssociateMainWaterMeterToLabelFailed.match(action)) {
    return Object.assign({}, state, {
      isAssociatingMainWaterMeterToLabel: false,
      errorMessage: 'Päävesimittareiden lisäminen epäonnistui'
    })
  }

  if (RemoveMainWaterMeterToLabelAction.match(action)) {
    return Object.assign({}, state, {
      isRemovingMainWaterMeterToLabel: true
    })
  }

  if (RemoveMainWaterMeterToLabelSuccessful.match(action)) {
    return Object.assign({}, state, {
      isRemovingMainWaterMeterToLabel: false,
      labelsWithMainMeters: action.payload
    })
  }

  if (RemoveMainWaterMeterToLabelFailed.match(action)) {
    return Object.assign({}, state, {
      isRemovingMainWaterMeterToLabel: false,
      errorMessage: 'Päävesimittareiden poisto epäonnistui'
    })
  }

  if (ChooseLabelValue.match(action)) {
    return Object.assign({}, state, {
      labelsWithMainMeters: { ...state.labelsWithMainMeters, value: action.payload.value }
    })
  }

  if (ChooseMainWaterMeter.match(action)) {
    return Object.assign({}, state, {
      labelsWithMainMeters: { ...state.labelsWithMainMeters, meterSigfoxId: action.payload.sigfoxId }
    })
  }

  if (ExpandOneMainMeterRowAction.match(action)) {
    return Object.assign({}, state, {
      mainWaterId: action.payload
    })
  }
  if (ToggleMainMetersModalAction.match(action)) {
    return {
      ...state,
      mainMetersModalOpen: action.payload
    }
  }
  return state
}
