import { put, call, all, takeLatest } from 'redux-saga/effects'
import { API_URL } from '../../constants/urls'
import { ApiError, handleErrors } from '../error/error-utils'
import {
  MeterRemovedAction,
  MetersReceivedAction,
  FetchMetersAction,
  RemoveMeterAction,
  FetchMetersFailureAction
} from './meter-table-actions'
import { OpenErrorAction } from '../error/error-actions'
import { type RemoveMeterPayload } from './meter-table-types'
import { LogoutSuccessAction } from '../auth/auth-actions'

export function * fetchMeters() {
  try {
    const meters = yield call(async () => {
      return await fetch(`${API_URL}/device`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`
        }
      })
        .then(async response => await handleErrors(response))
        .then(async response => await response.json())
    })
    yield put(MetersReceivedAction({ meters }))
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(FetchMetersFailureAction())
    yield put(OpenErrorAction('Mittareiden haku epäonnistui'))
  }
}

export function * removeMeter(action: { payload: RemoveMeterPayload }) {
  const id: number = action.payload.meterId
  try {
    yield call(async () => {
      return await fetch(`${API_URL}/device/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json'
        }
      })
        .then(async response => await handleErrors(response))
    })
    yield all([put(FetchMetersAction()),
      put(MeterRemovedAction())])
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(OpenErrorAction('Mittarin poisto epäonnistui'))
  }
}

export default function * meterTableSagas() {
  yield takeLatest(FetchMetersAction, fetchMeters)
  yield takeLatest(RemoveMeterAction, removeMeter)
}
