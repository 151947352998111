import { takeEvery, takeLatest, put, call, select, all } from 'redux-saga/effects'
import { FetchDataArrayFailedAction, FetchDataArraySuccessAction, FetchDataFailedAction, FetchDataSuccessAction, FetchMeterHistoryValuesFailedAction, FetchMeterHistoryValuesSuccessAction } from './meter-data-actions'
import { FETCH_DATA, FETCH_DATA_ARRAY, FETCH_METER_HISTORY_VALUES } from '../../constants/action-types'
import Api from '../../api/api'
import { type FetchData, type FetchDataArray, type FetchMeterHistoryValues } from './meter-data-types'
import selectIdToken from '../selectors/select-id-token'
import { OpenErrorAction } from '../error/error-actions'

export function * fetchDataArray(action: FetchDataArray) {
  if (action.payload.ids.length === 0) {
    return
  }

  const idToken = yield select(selectIdToken)
  const response = yield call(Api.MeterDataApi.fetchDataArray, idToken, action.payload)

  if (response.error) {
    yield put(FetchDataArrayFailedAction(response.error))
    yield put(OpenErrorAction(response.error)) // TODO: move to new format
  } else {
    yield put(FetchDataArraySuccessAction({ datas: response }))
  }
}

export function * fetchData(action: FetchData) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.MeterDataApi.fetchData, idToken, action.payload)

  if (response.error) {
    yield put(FetchDataFailedAction(response.error))
    yield put(OpenErrorAction(response.error)) // TODO: move to new format
  } else {
    yield put(FetchDataSuccessAction(response))
  }
}

export function * fetchMeterHistoryValues(action: FetchMeterHistoryValues) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.MeterDataApi.fetchMeterHistoryValues, idToken, action.payload)

  if (response.error) {
    yield put(FetchMeterHistoryValuesFailedAction(response.error))
    yield put(OpenErrorAction(response.error))
  } else {
    yield put(FetchMeterHistoryValuesSuccessAction({ data: response, scale: action.payload.scale }))
  }
}

export function * fetchDataArrayStart() {
  yield takeEvery(FETCH_DATA_ARRAY, fetchDataArray)
}

export function * fetchDataStart() {
  yield takeEvery(FETCH_DATA, fetchData)
}

export function * fetchMeterHistoryValuesStart() {
  yield takeLatest(FETCH_METER_HISTORY_VALUES, fetchMeterHistoryValues)
}

export function * meterDataSagas() {
  yield all([
    call(fetchDataArrayStart),
    call(fetchDataStart),
    call(fetchMeterHistoryValuesStart)
  ])
}
