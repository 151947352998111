import {
  FETCH_DEVICES_FOR_PROVISION_SUCCESS,
  FETCH_DEVICES_FOR_PROVISION_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS,
  OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  OPEN_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG,
  NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  DEVICE_ACTIVATION_DATA_VALUES_CHANGED,
  CREATE_DEVICE_FOR_PROVISION_SUCCESS,
  CREATE_DEVICE_FOR_PROVISION_FAILED,
  UPDATE_DEVICE_FOR_PROVISION_SUCCESS,
  UPDATE_DEVICE_FOR_PROVISION_FAILED,
  DELETE_DEVICE_FOR_PROVISION_FAILED,
  DELETE_DEVICE_FOR_PROVISION_SUCCESS,
  ACTIVATE_DEVICE_FOR_PROVISION_FAILED,
  ACTIVATE_DEVICE_FOR_PROVISION_SUCCESS,
  OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED,
  IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED,
  FETCH_DEVICE_PROFILES_FAILED,
  FETCH_DEVICE_PROFILES_SUCCESS,
  FETCH_DEVICE_PROFILES_STARTED,
  SET_DEVICES_ACTIVE_PAGE,
  SET_DEVICES_PAGE_SIZE,
  OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED,
  SEARCH_DEVICES_FOR_PROVISION
} from '../../constants/action-types.ts'

import createI18nInstance from '../../i18n'
import { retrieveLanguage } from '../../utils/languageStore'

const i18n = createI18nInstance(retrieveLanguage())

const initialDeviceForProvisionFields = {
  serialNumber: '',
  devEUI: '',
  appEUI: '',
  appKey: '',
  deviceProfileId: ''
}

export const initialDeviceForProvisionState = {
  devicesForProvision: [],
  editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
  newDeviceForProvision: { ...initialDeviceForProvisionFields },
  deviceActivationData: {
    deviceForProvision: { ...initialDeviceForProvisionFields },
    location: '',
    company: null
  },
  csvExportStart: '',
  editDeviceForProvisionDialogOpen: false,
  createDeviceForProvisionDialogOpen: false,
  activateDeviceForProvisionDialogOpen: false,
  importDevicesForProvisionDialogOpen: false,
  importDevicesForProvisionFile: null,
  importDevicesForProvisionProfileId: '',
  deviceProfiles: [],
  loadingDeviceProfiles: false,
  deviceProfilesLoaded: false,
  devicesActivePage: 1,
  devicesPageSize: 10,
  searchQuery: ''
}

const activationDataFetched = 'Aktivointitietoja tuotu'
const units = 'kpl'
function devicesForProvisionReducer (state = initialDeviceForProvisionState, action) {
  switch (action.type) {
    case FETCH_DEVICES_FOR_PROVISION_SUCCESS:
      return Object.assign({}, state, {
        devicesForProvision: action.data
      })
    case FETCH_DEVICES_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.fetch_devices_for_provision_failed' + ': ') + action.error)
      return state
    case FETCH_DEVICE_PROFILES_STARTED:
      return Object.assign({}, state, {
        loadingDeviceProfiles: true
      })
    case FETCH_DEVICE_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        deviceProfiles: action.data.sort(function (a, b) {
          // Magic sort, make two most common ids first ("bmeters/lr3lr4" and "Koka/standard")
          const magicIds = ['bmeters/lr3lr4', 'Koka/standard']
          return magicIds.indexOf(b.id) - magicIds.indexOf(a.id)
        }),
        loadingDeviceProfiles: false,
        deviceProfilesLoaded: true
      })
    case FETCH_DEVICE_PROFILES_FAILED:
      window.alert(i18n.t('errors.fetch_device_profiles_failed') + ': ' + action.error)
      return Object.assign({}, state, {
        loadingDeviceProfiles: false
      })
    case UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS:
      if (action.data.error !== '') {
        window.alert(activationDataFetched + ' ' + action.data.returnValue.length + ' ' + units + ', virheitä: \n' + action.data.error)
      } else {
        window.alert(activationDataFetched + ' ' + action.data.returnValue.length + ' ' + units)
      }
      return Object.assign({}, state, {
        devicesForProvision: [...state.devicesForProvision, ...action.data.returnValue],
        importDevicesForProvisionFile: null,
        importDevicesForProvisionProfileId: '',
        importDevicesForProvisionDialogOpen: false
      })
    case UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED:
      window.alert(i18n.t('errors.upload_devices_for_provision_csv_failed') + ': ' + action.error)
      return state
    case OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        createDeviceForProvisionDialogOpen: true
      })
    case CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        createDeviceForProvisionDialogOpen: false,
        newDeviceForProvision: { ...initialDeviceForProvisionFields }
      })
    case OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        editDeviceForProvisionDialogOpen: true,
        editedDeviceForProvision: action.deviceForProvision
      })
    case CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        editDeviceForProvisionDialogOpen: false,
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' }
      })
    case OPEN_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        deviceActivationData: {
          ...state.deviceActivationData,
          deviceForProvision: action.deviceForProvision
        },
        activateDeviceForProvisionDialogOpen: true
      })
    case CLOSE_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        deviceActivationData: {
          deviceForProvision: { ...initialDeviceForProvisionFields },
          location: '',
          company: null
        },
        activateDeviceForProvisionDialogOpen: false
      })
    case OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        importDevicesForProvisionDialogOpen: true
      })
    case CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG:
      return Object.assign({}, state, {
        importDevicesForProvisionDialogOpen: false,
        importDevicesForProvisionFile: null,
        importDevicesForProvisionProfileId: ''
      })
    case NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED:
      return Object.assign({}, state, {
        newDeviceForProvision: action.values
      })
    case OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG:
      return Object.assign({}, state, {
        generateCSVDialogOpen: true
      })
    case CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG:
      return Object.assign({}, state, {
        generateCSVDialogOpen: false
      })
    case NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED:
      return Object.assign({}, state, {
        csvExportStart: action.value
      })
    case EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED:
      return Object.assign({}, state, {
        editedDeviceForProvision: action.values
      })
    case DEVICE_ACTIVATION_DATA_VALUES_CHANGED:
      return Object.assign({}, state, {
        deviceActivationData: action.values
      })
    case CREATE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.create_device_for_provision_failed') + ': ' + action.error)
      return state
    case CREATE_DEVICE_FOR_PROVISION_SUCCESS:
      return Object.assign({}, state, {
        devicesForProvision: [...state.devicesForProvision, action.data],
        newDeviceForProvision: { ...initialDeviceForProvisionFields },
        createDeviceForProvisionDialogOpen: false
      })
    case UPDATE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.update_device_for_provision_failed') + ': ' + action.error)
      return state
    case UPDATE_DEVICE_FOR_PROVISION_SUCCESS:
      return Object.assign({}, state, {
        devicesForProvision: state.devicesForProvision.map(item => {
          if (item.id === action.data.id) {
            return { ...item, ...action.data }
          }
          return item
        }),
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
        editDeviceForProvisionDialogOpen: false
      })
    case DELETE_DEVICE_FOR_PROVISION_SUCCESS:
      return Object.assign({}, state, {
        devicesForProvision: state.devicesForProvision.filter(item => item.id !== action.data.id),
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
        editDeviceForProvisionDialogOpen: false
      })
    case DELETE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.delete_device_for_provision_failed') + ': ' + action.error)
      return state
    case ACTIVATE_DEVICE_FOR_PROVISION_SUCCESS:
      return Object.assign({}, state, {
        devicesForProvision: state.devicesForProvision.map(item => {
          if (item.id === action.data.id) {
            return { ...item, ...action.data }
          }
          return item
        }),
        deviceActivationData: {
          deviceForProvision: { ...initialDeviceForProvisionFields },
          customer: '',
          location: ''
        },
        activateDeviceForProvisionDialogOpen: false
      })
    case ACTIVATE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.activate_device_for_provision_failed') + ': ' + action.error)
      return state
    case IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED:
      return Object.assign({}, state, {
        importDevicesForProvisionFile: action.file
      })
    case IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED:
      return Object.assign({}, state, {
        importDevicesForProvisionProfileId: action.profileId
      })
    case SET_DEVICES_ACTIVE_PAGE:
      return Object.assign({}, state, {
        devicesActivePage: action.pageNumber
      })
    case SET_DEVICES_PAGE_SIZE:
      return Object.assign({}, state, {
        devicesActivePage: 1,
        devicesPageSize: action.pageSize
      })
    case SEARCH_DEVICES_FOR_PROVISION:
      return Object.assign({}, state, {
        searchQuery: action.query
      })
    default:
      return state
  }
}

export default devicesForProvisionReducer
