import TableCell from '@mui/material/TableCell'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TableRow } from '@mui/material'

// Used only for the table head cells
export const StyledHeadTableCell = styled(TableCell)`
    color: rgb(110,110,110);
`
export const ButtonContainer = styled.div`
    background: #969696;
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        filter: brightness(120%);
    };
    align-items: center;
    justify-content: center;
    color: #ffffff;
`
const SharedTextStyle = css`
    font-weight: 600;
    font-family: Roboto;
`
export const AssociateLabelText = styled.p`
    ${SharedTextStyle}
    text-transform: capitalize;
`
export const AssociateFieldTitle = styled.p`
    ${SharedTextStyle}
`
export const StyledExpandedTableCellHead = styled(TableCell)`
    border-bottom: none;
    color: rgb(110,110,110);
`
export const StyledExpandedTableRowBody = styled(TableRow)`
    border: 1px solid #EFF0F3;
`
