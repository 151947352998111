import { createAction } from '@reduxjs/toolkit'
import {
  DOWNLOAD_FILE_FOR_USER,
  DOWNLOAD_FILE_FOR_USER_FAILED,
  DOWNLOAD_FILE_FOR_ADMIN,
  OPEN_EDIT_COMPANY_DIALOG,
  DISABLE_INFO_DIALOG_SAVE,
  UPDATE_COMPANY,
  CREATE_COMPANY,
  DELETE_COMPANY,
  DOWNLOAD_FILE_FOR_ADMIN2,
  DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES,
  UPDATE_COMPANY_ALARM,
  DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES_FOR_ADMIN
} from '../../constants/action-types'
import {
  type DownloadFileForUserPayload,
  type DownloadFileForUserFailedPayload,
  type DownloadFileForAdminPayload,
  type OpenEditCompanyDialogPayload,
  type UpdateCompanyPayload,
  type CreateCompanyPayload,
  type DeleteCompanyPayload,
  type DownloadFileForAdminPayload2,
  type DownloadFileWithinSpecificDatesPayload,
  type UpdateCompanyAlarmPayload,
  type DownloadFileWithinSpecificDatesForAdminPayload
} from './companies-types'

export const DownloadFileForUserAction = createAction<DownloadFileForUserPayload>(DOWNLOAD_FILE_FOR_USER)
export const DownloadFileForAdminAction = createAction<DownloadFileForAdminPayload>(DOWNLOAD_FILE_FOR_ADMIN)
export const DownloadFileForUserFailedAction = createAction<DownloadFileForUserFailedPayload>(DOWNLOAD_FILE_FOR_USER_FAILED)
export const DownloadFileWithinSpecificDatesAction = createAction<DownloadFileWithinSpecificDatesPayload>(DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES)
export const DownloadFileWithinSpecificDatesForAdminAction = createAction<DownloadFileWithinSpecificDatesForAdminPayload>(DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES_FOR_ADMIN)
export const OpenEditCompanyDialogAction = createAction<OpenEditCompanyDialogPayload >(OPEN_EDIT_COMPANY_DIALOG)
export const DisableInfoDialogSaveAction = createAction<void>(DISABLE_INFO_DIALOG_SAVE)
export const UpdateCompanyAction = createAction<UpdateCompanyPayload>(UPDATE_COMPANY)
export const CreateCompanyAction = createAction<CreateCompanyPayload>(CREATE_COMPANY)
export const DeleteCompanyAction = createAction<DeleteCompanyPayload>(DELETE_COMPANY)
export const DownloadFileForAdmin2Action = createAction<DownloadFileForAdminPayload2>(DOWNLOAD_FILE_FOR_ADMIN2)
export const UpdateCompanyAlarmAction = createAction<UpdateCompanyAlarmPayload>(UPDATE_COMPANY_ALARM)
