import { Dialog, DialogTitle, DialogActions } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsGeneralButton, SettingsGeneralDisabledButton, SettingsSaveButton } from './MeterConfiguration.style'

interface DownlinkActionButtonAndDialogProps {
  enabled: boolean
  actionText: string
  confirmDialogText: string
  sendDownlinkMessage: () => void
}

export function DownlinkActionButtonAndDialog({ enabled, actionText, confirmDialogText, sendDownlinkMessage }: DownlinkActionButtonAndDialogProps): JSX.Element {
  const t = useTranslation().t
  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const confirmationDialogClose = () => {
    setOpenConfirmation(false)
  }

  const toggleOpen = () => {
    setOpenConfirmation(!openConfirmation)
  }

  const confirmationCancelHandler = () => {
    setOpenConfirmation(false)
  }

  const confirmationApproveHandler = () => {
    sendDownlinkMessage()
    setOpenConfirmation(false)
  }
  return (
    <React.Fragment>
      {enabled
        ? (
        <SettingsGeneralButton color="primary" onClick={toggleOpen}>
          {actionText}
        </SettingsGeneralButton>
          )
        : (
        <SettingsGeneralDisabledButton color="primary">
          {actionText}
        </SettingsGeneralDisabledButton>
          )}
      <Dialog
        open={openConfirmation}
        onClose={confirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmDialogText}</DialogTitle>
        <DialogActions>
          <SettingsGeneralButton onClick={confirmationCancelHandler}>
            {t('settings_dialog.cancel')}
          </SettingsGeneralButton>
          <SettingsSaveButton onClick={confirmationApproveHandler} autoFocus>
            {t('settings_dialog.yes')}
          </SettingsSaveButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
