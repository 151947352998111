import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { FetchCompaniesAction, SearchCompaniesAction } from '../../../redux/admin/admin-actions'
import { type Company } from '../../../redux/meters/meter-types'
import SearchBar from '../../common/search-bar/SearchBar'
import ConnectedCustomerDetails from './CustomersDetails'
import CustomersList from './CustomersList'
import { CompanyFormContainer, CustomersViewContainer } from './CustomersView.style'

export default function CustomersView(): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const companies = useSelector<RootState, Company[]>(state => state.vesimittari.admin.companies)
  useEffect(() => {
    if (companies.length === 0) {
      dispatch(FetchCompaniesAction())
    }
  }, [dispatch, companies])
  const searchValue = useSelector<RootState, string>(state => state.vesimittari.admin.searchText)
  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(SearchCompaniesAction({
      searchText: e.target.value,
      searchCompanies: companies
    }))
  }
  return (
    <CustomersViewContainer>
      <div>
        <p>{t('customers')}</p>
        <SearchBar onChange={onSearchTextChange} searchValue={searchValue} />
      </div>
      <CompanyFormContainer>
        <CustomersList/>
        <ConnectedCustomerDetails/>
      </CompanyFormContainer>
    </CustomersViewContainer>
  )
}
