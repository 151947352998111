import { type Meter, type MeterRecord } from '../redux/meters/meter-types'
import { type Group } from '../redux/settings/settings-type'

export const updateMetersByGroup = (meters: Meter[], groups: Group[]): MeterRecord => {
  if (meters.length === 0 || groups.length === 0) return {}
  const ret = {}
  groups.forEach(g => {
    const meter = meters.find(m => m.group && m.group.id === g.id && m.mainWaterMeter)
    if (meter) {
      ret[g.id] = meter
    }
  })
  return ret
}

export const sortGroups = (groups: Group[]): Group[] => {
  return groups.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
}
