import React from 'react'
import { Outlet } from 'react-router'
import ConnectedHeader from '../common/header/Header'
import ConnectedUnreadNotice from '../common/unread-notice/UnreadNotice'
export default function Dashboard() {
  return (
    <div style={{ marginTop: '5em' }}>
      <ConnectedHeader />
      <ConnectedUnreadNotice />
      <Outlet/>
    </div>
  )
}
