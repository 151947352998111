import { takeEvery, put, call, select } from 'redux-saga/effects'
import Api from '../../api/api'
import selectIdToken from '../selectors/select-id-token'
import fileDownload from 'js-file-download'
import { OpenErrorAction } from '../error/error-actions'
import { DownloadActiveMetersAction, DownloadActiveMetersFailedAction } from './reports-actions'

export function * downloadActiveMeters() {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.ReportsApi.downloadActiveMetersReport, idToken)
  if (response.error) {
    yield put(OpenErrorAction(response.error))
    yield put(DownloadActiveMetersFailedAction({ error: response.error }))
  } else {
    fileDownload(response, 'active_meters_by_company.csv')
  }
}

export default function * reportsSagas() {
  yield takeEvery(DownloadActiveMetersAction, downloadActiveMeters)
}
