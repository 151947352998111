import {
  FetchingUserInformationAction,
  LockSuccessAction,
  LogoutSuccessAction,
  UserReceivedAction,
  UserReceivedFailureAction
} from './auth-actions'
import { type AuthState } from './auth-types'
import { type AnyAction } from 'redux'
import { RestorePersistedDataAction } from '../../persistence-middleware'
import { stateWithLoadingFalse, stateWithLoadingTrue } from '../../utils/utils'

const initialState: AuthState = {
  loading: 0,
  isFetchingUserInformation: false,
  isAuthenticated: false,
  username: '',
  isAdmin: false,
  isAssignedToCompany: false,
  ownCompany: null,
  idToken: '',
  errorMessage: null
}

export default function authReducer(state: AuthState = initialState, action: AnyAction): AuthState {
  if (LockSuccessAction.match(action)) {
    return Object.assign({}, state, {
      isFetching: false,
      isAuthenticated: true,
      errorMessage: null,
      idToken: action.payload.token
    })
  }

  if (LogoutSuccessAction.match(action)) {
    return Object.assign({}, state, {
      isFetching: true,
      isAuthenticated: false
    })
  }

  if (FetchingUserInformationAction.match(action)) {
    return {
      ...stateWithLoadingTrue(state),
      isFetchingUserInformation: true
    }
  }

  if (UserReceivedAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      username: action.payload.user.username,
      isAdmin: action.payload.user.systemAdmin,
      isAssignedToCompany: action.payload.assignedToCompany,
      isFetchingUserInformation: false,
      ownCompany: action.payload.ownCompany
    }
  }

  if (UserReceivedFailureAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      isFetchingUserInformation: false,
      errorMessage: 'Käyttäjätietojen haku epäonnistui, istuntosi on mahdollisesti vanhentunut. Kokeile kirjautua sisään uudelleen'
    }
  }

  if (RestorePersistedDataAction.match(action)) {
    return {
      ...state,
      idToken: action.payload.idToken ?? '',
      isAuthenticated: !!action.payload.idToken,
      isAdmin: action.payload.isAdmin,
      isAssignedToCompany: action.payload.isAssignedToCompany
    }
  }
  return state
}
