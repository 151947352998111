import { API_URL } from '../constants/urls'
import {
  type FetchAlarmSettingsForDevicePayload
} from '../redux/alarm-settings/alarm-settings-types'

export default class AlarmSettingsApi {
  static async fetchAlarmSettings<T>(idToken: string, payload: FetchAlarmSettingsForDevicePayload): Promise<T> {
    return await fetch(`${API_URL}/device/${payload.deviceId}/alarmSettings`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }
}
