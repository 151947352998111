import { type MessagesState } from './messages-types'
import {
  AddMessageAction,
  AcknowledgeMessageAction
} from './messages-actions'
import { type AnyAction } from 'redux'

const initialState: MessagesState = {
  messages: []
}

export default function messagesReducer(state: MessagesState = initialState, action: AnyAction): MessagesState {
  if (AddMessageAction.match(action)) {
    return {
      ...state,
      messages: [...state.messages, action.payload.message]
    }
  }

  if (AcknowledgeMessageAction.match(action)) {
    const newMessages = state.messages.filter((e) => e.id !== action.payload.id)
    return {
      ...state,
      messages: newMessages
    }
  }

  return state
}
