import { put, call, select, all, takeLatest, takeEvery } from 'redux-saga/effects'
import Api from '../../api/api'
import { FETCH_CUSTOMER_ALARM_SETTINGS, FETCH_GROUPS, SAVE_CUSTOMER_ALARMS } from '../../constants/action-types'
import { OpenErrorAction } from '../error/error-actions'
import selectIdToken from '../selectors/select-id-token'
import { FetchCustomerAlarmsAction, FetchCustomerAlarmsFailAction, FetchCustomerAlarmsSuccessAction, FetchGroupsFailAction, GroupsReceivedAction, SaveCustomerAlarmsFailedAction, SaveCustomerAlarmsSuccessAction } from './setting-actions'
import { type FetchCustomerAlarms, type SaveCustomerAlarms } from './settings-type'

export function * fetchGroupsAsync() {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.SettingsApi.fetchGroups, idToken)

  if (response.error) {
    yield put(FetchGroupsFailAction(response.error))
    yield put(OpenErrorAction(response.error))
  } else {
    yield put(GroupsReceivedAction({
      groups: response
    }))
  }
}

export function * fetchCustomerAlarmSettings(action: FetchCustomerAlarms) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.SettingsApi.fetchCustomerAlarmSettings, idToken, action.payload)

  if (response.error) {
    yield put(FetchCustomerAlarmsFailAction(response.error))
    yield put(OpenErrorAction(response.error))
  } else {
    yield put(FetchCustomerAlarmsSuccessAction({
      id: action.payload.id,
      data: response
    }))
  }
}

export function * saveCustomerAlarmSettings(action: SaveCustomerAlarms) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.SettingsApi.saveCustomerAlarms, idToken, action.payload)

  if (response.error) {
    yield put(SaveCustomerAlarmsFailedAction(response.error))
    yield put(OpenErrorAction(response.error))
  } else {
    yield put(SaveCustomerAlarmsSuccessAction({
      id: action.payload.id,
      data: response
    }))
    yield put(FetchCustomerAlarmsAction({
      id: action.payload.id
    }))
  }
}

export function * fetchGroupsStart() {
  yield takeLatest(FETCH_GROUPS, fetchGroupsAsync)
}

export function * fetchCustomerAlarmsStart() {
  yield takeLatest(FETCH_CUSTOMER_ALARM_SETTINGS, fetchCustomerAlarmSettings)
}

export function * saveCustomerAlarmsSettingsStart() {
  yield takeEvery(SAVE_CUSTOMER_ALARMS, saveCustomerAlarmSettings)
}

export default function * settingsSagas() {
  yield all([call(fetchGroupsStart), call(fetchCustomerAlarmsStart), call(saveCustomerAlarmsSettingsStart)])
}
