import React from 'react'
import './assets/index.css'
import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { syncTranslationWithStore } from 'react-redux-i18n'
import getStore from './configureStore'
import { loadPersistedDataAction } from './persistence-middleware'
import { fetchingUserInformation } from './redux/auth/auth'
import Application from './components/application/Application'
import { UpdateOutOfDateMeterArrayAction } from './redux/meter-table/meter-table-actions'
import { BrowserRouter } from 'react-router-dom'
import createI18nInstance from './i18n'
import { retrieveLanguage } from './utils/languageStore'
import { OUT_OF_DATE } from './utils/utils'
import { createRoot } from 'react-dom/client'

const store = getStore()

syncTranslationWithStore(store)

function toObservable (store) {
  return {
    subscribe ({ onNext }) {
      const dispose = store.subscribe(() => onNext(store.getState()))
      onNext(store.getState())
      return { dispose }
    }
  }
}

const outOfDateMeterActor = {
  previousState: undefined,
  onNext: (state) => {
    const previous = outOfDateMeterActor.previousState
    if (previous) {
      const { values } = state.vesimittari.data
      const { outOfDateMeters } = state.vesimittari.meterTable
      if (Object.keys(values).some(key => outOfDateMeters[key] === OUT_OF_DATE)) {
        store.dispatch(UpdateOutOfDateMeterArrayAction({ values }))
      }
    }
    if (previous !== state) {
      outOfDateMeterActor.previousState = state
    }
  }
}

store.dispatch(loadPersistedDataAction())

if (store.getState().vesimittari.auth.isAuthenticated) {
  store.dispatch(fetchingUserInformation())
}

const theme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12
  },
  palette: {
    primary: {
      main: '#00bcd4',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#c0392b',
      contrastText: '#ffffff'
    }
  }
}))

toObservable(store).subscribe(outOfDateMeterActor)

const i18n = createI18nInstance(retrieveLanguage())
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <Application store={store} theme={theme} i18n={i18n} />
  </BrowserRouter>
)
