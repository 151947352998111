const _API_URL = process.env.REACT_APP_API_URL
const _REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

function getRedirectURL () {
  return _REDIRECT_URL
}

function getApiURL () {
  return _API_URL
}

export const REDIRECT_URL = getRedirectURL()
export const API_URL = getApiURL()
