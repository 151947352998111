import { API_URL } from '../constants/urls'

export default class ReportsApi {
  static async downloadActiveMetersReport(idToken: string): Promise<string | { error: string }> {
    return await fetch(`${API_URL}/reports/active_meters`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.text())
      .catch(error => ({ error: error.message }))
  }
}
