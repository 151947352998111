import { type AnyAction } from 'redux'
import { DisableInfoDialogSaveAction, OpenEditCompanyDialogAction } from './companies-actions'
import { type CompaniesState, type EditedOrganization, type Organization } from './companies-types'

const organization = {
  name: '',
  postalCode: '',
  streetAddress: '',
  town: '',
  vatId: '',
  alertsEnabled: true,
  alertRecipients: [],
  postalDistrict: ''
}

export const getEmptyOrganization = (): Organization => {
  return {
    ...organization,
    lrcAsKey: '',
    asId: ''
  }
}

export const getEmptyEditedOrganization = (): EditedOrganization => {
  return {
    ...organization,
    lrcAsKey: '',
    id: ''
  }
}

const initialState: CompaniesState = {
  createCompanyDialogOpen: false,
  editCompanyDialogOpen: false,
  organization: getEmptyOrganization(),
  editedOrganization: getEmptyEditedOrganization(),
  removeCompanyDialogOpen: false,
  parentCompany: undefined,
  infoDialogs: {
    lora: false,
    sigfox: false,
    saveEnabled: false,
    errorText: '',
    selectedCompany: { id: '', lrcAsKey: '', asId: '', canUpdateInterval: false }
  }
}

export default function companiesReducer(state: CompaniesState = initialState, action: AnyAction): CompaniesState {
  switch (action.type) {
    case OpenEditCompanyDialogAction.type : {
      return {
        ...state,
        editCompanyDialogOpen: true,
        editedOrganization: {
          ...action.payload.company,
          alertRecipients: action.payload.company.alertRecipients ?? []
        }
      }
    }

    case DisableInfoDialogSaveAction.type : {
      return {
        ...state,
        infoDialogs: {
          ...state.infoDialogs,
          saveEnabled: false
        }
      }
    }

    default : {
      return state
    }
  }
}
