import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { default as ReactJSPagination } from 'react-js-pagination'
import { makeStyles } from 'tss-react/mui'

import '../../../assets/App.css'
const options = [10, 20, 50, 100]

const pageRangeDisplayed = 7

const valueToMenuItem = (value: number) => {
  return (
    <MenuItem style={{ fontSize: '16px' }} value={value} key={value} >
      {value}
    </MenuItem>
  )
}

const useStyles = makeStyles()(() => {
  return {
    inputRoot: {
      fontSize: '1rem',
      marginTop: '5px'
    }
  }
})

export default function MetersPagination({ totalItemsCount, onPageSizeChange, itemsCountPerPage, onPageChange, activePage }) {
  const { classes } = useStyles()

  return (
    <div className="pagination-container">
      <ReactJSPagination activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={onPageChange}
        innerClass="pagination"
        activeClass="pagination-li-active"
        activeLinkClass="pagination-a-active"
        itemClass="pagination-li"
        linkClass="pagination-a"
      />
      <div className="page-size-select">
        <p style={{ margin: 0 }} className='page-size-text'>Näytä:</p>
        <Select
          label="Näytä"
          defaultValue={itemsCountPerPage}
          onChange={onPageSizeChange}
          disableUnderline classes={{
            nativeInput: classes.inputRoot
          }}
          variant="standard"
        >
          {options.map((value) => valueToMenuItem(value))}
        </Select>
      </div>

    </div>
  )
}
