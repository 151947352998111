import { API_URL } from '../constants/urls'
import { type FetchCustomerAlarmsPayload, type FetchCustomerAlarmsSuccessPayload } from '../redux/settings/settings-type'

export default class SettingsApi {
  static async fetchGroups<T>(idToken: string): Promise<T> {
    return await fetch(`${API_URL}/group/`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async fetchCustomerAlarmSettings<T>(idToken: string, payload: FetchCustomerAlarmsPayload): Promise<T> {
    return await fetch(`${API_URL}/company/${payload.id}/alarm_settings`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async saveCustomerAlarms<T>(idToken: string, payload: FetchCustomerAlarmsSuccessPayload): Promise<T> {
    return await fetch(`${API_URL}/company/${payload.id}/alarm_settings`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload.data)
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }
}
