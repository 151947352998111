import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { type Theme } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { AddButtonContainer } from '../../meter/filters/Filters.style'
import Add from '@mui/icons-material/Add'
import { CustomerListContainer } from './CustomersView.style'
import { type RootState } from '../../../interfaces/RootState'
import { useDispatch, useSelector } from 'react-redux'
import { OpenEditCompanyDialogAction } from '../../../redux/companies/companies-actions'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      maxWidth: '100%',
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300,
      border: 'solid #EFF0F3 1px',
      borderRadius: '5px'
    }
  }
})

export default function CustomersList(): JSX.Element {
  const dispatch = useDispatch()

  const { classes } = useStyles()
  const searchedCompanies = useSelector<RootState, any[]>(state => state.vesimittari.admin.searchedCompanies)

  const createCompany = () => {
    const newCompany = {
      id: '',
      name: '',
      vatId: null,
      streetAddress: null,
      town: null,
      postalCode: null,
      newCompany: true
    }
    dispatch(OpenEditCompanyDialogAction({ company: newCompany }))
  }

  return (
    <CustomerListContainer>
      <List className={classes.root}>
        {searchedCompanies.map((company) => (
          <ListItem key={company.id} button onClick={() => dispatch(OpenEditCompanyDialogAction({ company }))}>
            <ListItemText primary={company.name}/>
          </ListItem>
        ))}
      </List>
      <AddButtonContainer
        aria-controls='add-company-button'
        aria-haspopup={true}
        style={{ justifySelf: 'end' }}
        onClick={createCompany}
      >
        <Add style={{ color: '#ffffff', alignSelf: 'center', margin: 'auto' }}/>
      </AddButtonContainer>
    </CustomerListContainer>

  )
}
