import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { Select, MenuItem, InputLabel } from '@mui/material'
import { StyledFormControl } from './Filters.style'
import { FetchCompaniesAction } from '../../../redux/admin/admin-actions'
import { type Company } from '../../../redux/meters/meter-types'
import { CustomerFilterChangedAction } from '../../../redux/meter-table/meter-table-actions'
import { useTranslation } from 'react-i18next'

function CustomerFilter({ value, dispatch, companies }: { value: string, dispatch: any, companies: Company[] }): JSX.Element {
  const t = useTranslation().t
  useEffect(() => {
    dispatch(FetchCompaniesAction())
  }, [dispatch])

  const handleChange = (newFilter) => {
    let foundCustomer
    if (newFilter === 'all') {
      foundCustomer = { id: 'all' }
    } else {
      foundCustomer = companies.find(e => {
        return e.id === newFilter
      })
    }
    dispatch(CustomerFilterChangedAction({ customer: foundCustomer }))
  }

  return (
    <StyledFormControl variant="outlined" size="small">
      <InputLabel>{t('customer_filter')}</InputLabel>
      <Select value={value} onChange={(e) => { handleChange(e.target.value) }} label={t('customer_filter')}>
        <MenuItem value="all">{t('all')}</MenuItem>
        {companies.map(item => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default function ConnectedCustomerFilter(): JSX.Element {
  const dispatch = useDispatch()
  const value = useSelector((state: RootState): string => state.vesimittari.data.customerFilter)
  const companies = useSelector((state: RootState): Company[] => state.vesimittari.admin.companies)
  return <CustomerFilter value={value} dispatch={dispatch} companies={companies}/>
}
