import {
  NEW_METER_VALUES_UPDATE_ERROR,
  NEW_METER_VALUES_UPDATE_SUCCESS,
  SET_NEW_METER_VALUES,
  TOGGLE_METER_SETTINGS_DIALOG,
  UPDATE_NEW_METER_VALUES
} from '../../constants/action-types'
import { type Meter } from './meter-types'

import { createAction } from '@reduxjs/toolkit'

export const SetNewMeterValuesAction = createAction<Meter>(SET_NEW_METER_VALUES)
export const ToggleMeterSettingsDialogAction = createAction<Meter | null>(TOGGLE_METER_SETTINGS_DIALOG)
export const UpdateNewMeterValuesAction = createAction<Meter>(UPDATE_NEW_METER_VALUES)
export const UpdateNewMeterValuesSuccessAction = createAction<Meter>(NEW_METER_VALUES_UPDATE_SUCCESS)
export const UpdateNewMeterValuesErrorAction = createAction<any>(NEW_METER_VALUES_UPDATE_ERROR)
