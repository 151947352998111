import { call, put, select, takeLatest } from 'redux-saga/effects'
import { API_URL } from '../../constants/urls'
import selectIdToken from '../selectors/select-id-token'
import { CreateNewNoticeAction, CreateNewNoticeFailureAction, CreateNewNoticeSuccessAction, DeleteNoticeAction, DeleteNoticeFailureAction, DeleteNoticeSuccessAction, FetchNoticesAction, FetchNoticesFailureAction, FetchNoticesSuccessAction, MarkNoticeAsReadAction, MarkNoticeAsReadFailureAction, MarkNoticeAsReadSuccessAction } from './notices-actions'

function * fetchNotices() {
  try {
    const idToken = yield select(selectIdToken)

    const res = yield call(async () => {
      return await fetch(`${API_URL}/notice`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).then(async response => await response.json())
    })

    if (res.error !== undefined) {
      throw res // is actually an error now
    }

    yield put(FetchNoticesSuccessAction(res))
  } catch (error: any) {
    // yield put(AddMessageAction(error.message, MessageTypes.error))
    yield put(FetchNoticesFailureAction(error))
  }
}

function * createNewNotice(action: { payload: string }) {
  try {
    const idToken = yield select(selectIdToken)

    const res = yield call(async () => {
      return await fetch(`${API_URL}/notice`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: action.payload
      }).then(async response => await response.json())
    })

    if (res.error !== undefined) {
      throw res // is actually an error now
    }

    yield put(CreateNewNoticeSuccessAction(res))
  } catch (error: any) {
    // yield put(AddMessageAction(error.message, MessageTypes.error))
    yield put(CreateNewNoticeFailureAction(error))
  }
}

function * deleteNotice(action: { payload: string }) {
  try {
    const idToken = yield select(selectIdToken)

    const res = yield call(async () => {
      return await fetch(`${API_URL}/notice/${action.payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      })
    })

    if (res.status !== 200) {
      throw new Error(`Could not delete notice ${action.payload} [${res.status}]`)
    }

    yield put(DeleteNoticeSuccessAction(action.payload))
  } catch (error: any) {
    // yield put(AddMessageAction(error.message, MessageTypes.error))
    yield put(DeleteNoticeFailureAction(error))
  }
}

function * markNoticeAsRead(action: { payload: string }) {
  try {
    const idToken = yield select(selectIdToken)

    const res = yield call(async () => {
      return await fetch(`${API_URL}/notice/${action.payload}/mark_as_read`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).then(async response => await response.json())
    })

    if (res.error !== undefined) {
      throw res // is actually an error now
    }

    yield put(MarkNoticeAsReadSuccessAction(res))
  } catch (error: any) {
    // yield put(AddMessageAction(error.message, MessageTypes.error))
    yield put(MarkNoticeAsReadFailureAction(error))
  }
}

export default function * noticesSagas() {
  yield takeLatest(FetchNoticesAction, fetchNotices)
  yield takeLatest(DeleteNoticeAction, deleteNotice)
  yield takeLatest(CreateNewNoticeAction, createNewNotice)
  yield takeLatest(MarkNoticeAsReadAction, markNoticeAsRead)
}
