import { type AnyAction } from '@reduxjs/toolkit'
import { CreateNewNoticeAction, CreateNewNoticeFailureAction, CreateNewNoticeSuccessAction, DeleteNoticeSuccessAction, FetchNoticesAction, FetchNoticesFailureAction, FetchNoticesSuccessAction, MarkNoticeAsReadSuccessAction, ToggleCreateNoticeModalAction } from './notices-actions'
import { type NoticesState } from './notices-types'
import { stateWithLoadingFalse, stateWithLoadingTrue } from '../../utils/utils'

const initialState: NoticesState = {
  loading: 0,
  notices: [],
  createNoticeModalOpen: false
}

export default function noticesReducer(state: NoticesState = initialState, action: AnyAction): NoticesState {
  if (FetchNoticesAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (FetchNoticesSuccessAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      notices: action.payload
    }
  }

  if (FetchNoticesFailureAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (CreateNewNoticeAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (CreateNewNoticeSuccessAction.match(action)) {
    return {
      ...stateWithLoadingFalse(state),
      createNoticeModalOpen: false,
      notices: [
        ...state.notices,
        action.payload
      ]
    }
  }

  if (CreateNewNoticeFailureAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (DeleteNoticeSuccessAction.match(action)) {
    const noticesWithoutDeleted = state.notices.filter((notice) => notice.id !== action.payload)
    return {
      ...state,
      notices: noticesWithoutDeleted
    }
  }

  if (MarkNoticeAsReadSuccessAction.match(action)) {
    const noticesWithoutUpdated = state.notices.filter((notice) => notice.id !== action.payload.id)
    return {
      ...state,
      notices: [
        ...noticesWithoutUpdated,
        action.payload
      ]
    }
  }

  if (ToggleCreateNoticeModalAction.match(action)) {
    return {
      ...state,
      createNoticeModalOpen: !state.createNoticeModalOpen
    }
  }

  return state
}
