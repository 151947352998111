export const SHOW_LOCK = 'SHOW_LOCK'
export const LOCK_SUCCESS = 'LOCK_SUCCESS'
export const LOCK_ERROR = 'LOCK_ERROR'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const USER_RECEIVED = 'USER_RECEIVED'
export const USER_RECEIVED_FAILURE = 'USER_RECEIVED_FAILURE'
export const FETCHING_USER_INFORMATION = 'FETCHING_USER_INFORMATION'

export const OPEN_ERROR = 'OPEN_ERROR'
export const CLOSE_ERROR = 'CLOSE_ERROR'

export const PERSISTENCE_LOAD = 'PERSISTENCE_LOAD'
export const PERSISTENCE_RESTORE = 'PERSISTENCE_RESTORE'

// ADMIN PAGE
export const COMPANIES_RECEIVED = 'COMPANIES_RECEIVED'
export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL'
export const DISABLE_INFO_DIALOG_SAVE = 'DISABLE_INFO_DIALOG_SAVE'
export const OPEN_EDIT_COMPANY_DIALOG = 'OPEN_EDIT_COMPANY_DIALOG'
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS'
export const SEARCH_COMPANIES_STRING_CHANGED = 'SEARCH_COMPANIES_STRING_CHANGED'
export const SELECT_TAB = 'SELECT_TAB'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'

// DASHBOARD
export const FETCH_METERS = 'FETCH_METERS'
export const METERS_RECEIVED = 'METERS_RECEIVED'
export const FETCH_METERS_FAILED = 'FETCH_METERS_FAILED'
export const UPDATE_OUT_OF_DATE_METER_ARRAY = 'UPDATE_OUT_OF_DATE_METER_ARRAY'
export const STORE_LOCATION = 'STORE_LOCATION'
export const STORE_ALL = 'STORE_ALL'
export const FETCH_DATA = 'FETCH_DATA'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'
export const FETCH_DATA_ARRAY = 'FETCH_DATA_ARRAY'
export const FETCH_DATA_ARRAY_SUCCESS = 'FETCH_DATA_ARRAY_SUCCESS'
export const FETCH_DATA_ARRAY_FAILED = 'FETCH_DATA_ARRAY_FAILED'
export const DATA_RECEIVED = 'DATA_RECEIVED'
export const ADD_LABEL = 'ADD_LABEL'
export const OPEN_EMPTY_LABEL_ERROR = 'OPEN_EMPTY_LABEL_ERROR'
export const OPEN_SAME_LABEL_ERROR = 'OPEN_SAME_LABEL_ERROR'
export const FORCE_LOGOUT = 'FORCE_LOGOUT'
export const REMOVE_LABEL = 'REMOVE_LABEL'
export const TOGGLE_METER_SETTINGS_DIALOG = 'TOGGLE_METER_SETTINGS_DIALOG'
export const OPEN_METER_HISTORY_DIALOG = 'OPEN_METER_HISTORY_DIALOG'
export const CHANGE_HISTORY_DIALOG_COLUMN_WIDTH = 'CHANGE_HISTORY_DIALOG_COLUMN_WIDTH'
export const CHANGE_HISTORY_DIALOG_DATE_RANGE = 'CHANGE_HISTORY_DIALOG_DATE_RANGE'
export const HISTORY_DIALOG_DATE_PREV = 'HISTORY_DIALOG_DATE_PREV'
export const HISTORY_DIALOG_DATE_NEXT = 'HISTORY_DIALOG_DATE_NEXT'
export const CLOSE_METER_HISTORY_DIALOG = 'CLOSE_METER_HISTORY_DIALOG'
export const RESET_METER_HISTORY_DIALOG = 'RESET_METER_HISTORY_DIALOG'
export const BASE_VALUE_UPDATED = 'BASE_VALUE_UPDATED'
export const BASE_VALUE_UPDATE_ERROR = 'BASE_VALUE_UPDATE_ERROR'
export const DATA_TRANSFER_INTERVAL_UPDATED = 'DATA_TRANSFER_INTERVAL_UPDATED'
export const LORA_RESET_QUEUED = 'LORA_RESET_QUEUED'
export const LORA_RESET_QUEUE_FAILED = 'LORA_RESET_QUEUE_FAILED'
export const OPEN_REMOVE_METER_DIALOG = 'OPEN_REMOVE_METER_DIALOG'
export const CLOSE_REMOVE_METER_DIALOG = 'CLOSE_REMOVE_METER_DIALOG'
export const TOGGLE_REMOVE_METER_CONFIRMED = 'TOGGLE_REMOVE_METER_CONFIRMED'
export const METER_REMOVED = 'METER_REMOVED'
export const TOGGLE_DOWNLOAD_DIALOG = 'TOGGLE_DOWNLOAD_DIALOG'
export const SET_EDIT_COMPANY_ALARMS_DIALOG_VISIBILITY = 'SET_EDIT_COMPANY_ALARMS_DIALOG_VISIBILITY'
export const SET_COMPANY_ALARM = 'SET_COMPANY_ALARM'
export const UPDATE_COMPANY_ALARM = 'UPDATE_COMPANY_ALARM'
export const SET_METER_ACTIVE_PAGE = 'SET_METER_ACTIVE_PAGE'
export const SEARCH_METERS_DATA_ACTION = 'SEARCH_METERS_DATA_ACTION'
export const SET_METER_PAGE_SIZE = 'SET_METER_PAGE_SIZE'
export const TOGGLE_MAIN_METERS_MODAL = 'TOGGLE_MAIN_METERS_MODAL'
export const FETCH_MAIN_METERS = 'FETCH_MAIN_METERS'
export const FETCH_MAIN_METERS_SUCCESSFUL = 'FETCH_MAIN_METERS_SUCCESSFUL'
export const FETCH_MAIN_METERS_FAILED = 'FETCH_MAIN_METERS_FAILED'
export const ASSOCIATE_MAIN_METER_TO_LABEL_START = 'ASSOCIATE_MAIN_METER_TO_LABEL_START'
export const ASSOCIATE_MAIN_METER_TO_LABEL_SUCCESS = 'ASSOCIATE_MAIN_METER_TO_LABEL_SUCCESS'
export const ASSOCIATE_MAIN_METER_TO_LABEL_ERROR = 'ASSOCIATE_MAIN_METER_TO_LABEL_ERROR'
export const REMOVE_MAIN_WATER_METER_TO_LABEL_START = 'REMOVE_MAIN_WATER_METER_TO_LABEL_START'
export const REMOVE_MAIN_WATER_METER_TO_LABEL_SUCCCESSFUL = 'REMOVE_MAIN_WATER_METER_SUCCESSFUL'
export const REMOVE_MAIN_WATER_METER_TO_LABEL_FAILED = 'REMOVE_MAIN_WATER_METER_TO_LABEL_FAILED'
export const MAIN_WATER_METER_CHANGED = 'MAIN_WATER_METER_CHANGED'
export const LABEL_VALUE_CHOSEN = 'LABEL_VALUE_CHOSEN'
export const EXPAND_ONE_MAIN_METER_ROW = 'EXPAND_ONE_MAIN_METER_ROW'
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION'
export const FETCH_METER_HISTORY_VALUES = 'FETCH_METER_HISTORY_VALUES'
export const FETCH_METER_HISTORY_VALUES_SUCCESS = 'FETCH_METER_HISTORY_VALUES_SUCCESS'
export const FETCH_METER_HISTORY_VALUES_FAILED = 'FETCH_METER_HISTORY_VALUES_FAILED'

export const ADD_ACTIVE_FILTER = 'ADD_ACTIVE_FILTER'
export const REMOVE_ACTIVE_FILTER = 'REMOVE_ACTIVE_FILTER'
export const ADD_ACTIVE_LABEL_FILTER = 'ADD_ACTIVE_LABEL_FILTER'
export const REMOVE_ACTIVE_LABEL_FILTER = 'REMOVE_ACTIVE_LABEL_FILTER'

export const CHANGE_ALARM_SETTING = 'CHANGE_ALARM_SETTING'
export const FETCH_ALARM_SETTINGS = 'FETCH_ALARM_SETTINGS'
export const RESPONSE_ALARM_SETTINGS = 'RESPONSE_ALARM_SETTINGS'
export const CHANGE_FLOW_LIMIT = 'CHANGE_FLOW_LIMIT'

// SETTINGS PAGE
export const SAVE_NEW_GROUP = 'SAVE_NEW_GROUP'
export const NEW_GROUP_SAVED = 'NEW_GROUP_SAVED'
export const UPDATE_NEW_GROUP_NAME = 'UPDATE_NEW_GROUP_NAME'
export const FETCH_GROUPS = 'FETCH_GROUPS'
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL'
export const GROUPS_RECEIVED = 'GROUPS_RECEIVED'
export const GROUP_FILTER_CHANGED = 'GROUP_FILTER_CHANGED'
export const CUSTOMER_FILTER_CHANGED = 'CUSTOMER_FILTER_CHANGED'
export const LABEL_FILTER_CHANGED = 'LABEL_FILTER_CHANGED'
export const SET_ALARMS_FILTER = 'SET_ALARMS_FILTER'
export const EMAIL_ENABLED_TOGGLED = 'EMAIL_ENABLED_TOGGLED'
export const EMAIL_RECIPIENTS_ADD = 'EMAIL_RECIPIENTS_ADD'
export const EMAIL_RECIPIENTS_REMOVE = 'EMAIL_RECIPIENTS_REMOVE'
export const EMAIL_INTERVAL_CHANGED = 'EMAIL_INTERVAL_CHANGED'
export const SMS_ENABLED_TOGGLED = 'SMS_ENABLED_TOGGLED'
export const TEMPERATURE_ALERTS_ENABLED_TOGGLED = 'TEMPERATURE_ALERTS_ENABLED_TOGGLED'
export const SMS_RECIPIENTS_ADD = 'SMS_RECIPIENTS_ADD'
export const SMS_RECIPIENTS_REMOVE = 'SMS_RECIPIENTS_REMOVE'
export const SMS_INTERVAL_CHANGED = 'SMS_INTERVAL_CHANGED'
export const FETCH_CUSTOMER_ALARM_SETTINGS = 'FETCH_CUSTOMER_ALARM_SETTINGS'
export const CUSTOMER_ALARM_SETTINGS_FETCHED = 'CUSTOMER_ALARM_SETTINGS_FETCHED'
export const FETCH_CUSTOMER_ALARM_SETTINGS_FAILED = 'FETCH_CUSTOMER_ALARM_SETTINGS_FAILED'
export const SAVE_CUSTOMER_ALARMS = 'SAVE_CUSTOMER_ALARMS'
export const SAVE_CUSTOMER_ALARMS_SUCCESS = 'SAVE_CUSTOMER_ALARMS_SUCCESS'
export const SAVE_CUSTOMER_ALARMS_FAILED = 'SAVE_CUSTOMER_ALARMS_FAILED'
export const UPDATE_SMS_RECIPIENT_TEXT_BOX = 'UPDATE_SMS_RECIPIENT_TEXT_BOX'
export const UPDATE_TEMPERATURE_ALERT_LOWER_LIMIT = 'UPDATE_TEMPERATURE_ALERT_LOWER_LIMIT'
export const UPDATE_TEMPERATURE_ALERT_UPPER_LIMIT = 'UPDATE_TEMPERATURE_ALERT_UPPER_LIMIT'
export const CHANGE_SETTINGS_TAB = 'CHANGE_SETTINGS_TAB'
export const GET_STORED_DATA = 'GET_STORED_DATA'

// DEVICE PROVISIONING
export const FETCH_DEVICES_FOR_PROVISION_SUCCESS = 'FETCH_DEVICES_FOR_PROVISION_SUCCESS'
export const FETCH_DEVICES_FOR_PROVISION_FAILED = 'FETCH_DEVICES_FOR_PROVISION_FAILED'
export const UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS = 'UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS'
export const UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED = 'UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED'
export const OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG = 'OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG'
export const CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG = 'CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG'
export const OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG = 'OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG'
export const CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG = 'CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG'
export const OPEN_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG = 'OPEN_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG'
export const CLOSE_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG = 'CLOSE_ACTIVATE_DEVICE_FOR_PROVISION_DIALOG'
export const CREATE_DEVICE_FOR_PROVISION_SUCCESS = 'CREATE_DEVICE_FOR_PROVISION_SUCCESS'
export const CREATE_DEVICE_FOR_PROVISION_FAILED = 'CREATE_DEVICE_FOR_PROVISION_FAILED'
export const UPDATE_DEVICE_FOR_PROVISION_SUCCESS = 'UPDATE_DEVICE_FOR_PROVISION_SUCCESS'
export const UPDATE_DEVICE_FOR_PROVISION_FAILED = 'UPDATE_DEVICE_FOR_PROVISION_FAILED'
export const NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED = 'NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED'
export const EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED = 'EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED'
export const DELETE_DEVICE_FOR_PROVISION_SUCCESS = 'DELETE_DEVICE_FOR_PROVISION_SUCCESS'
export const DELETE_DEVICE_FOR_PROVISION_FAILED = 'DELETE_DEVICE_FOR_PROVISION_FAILED'
export const ACTIVATE_DEVICE_FOR_PROVISION_SUCCESS = 'ACTIVATE_DEVICE_FOR_PROVISION_SUCCESS'
export const ACTIVATE_DEVICE_FOR_PROVISION_FAILED = 'ACTIVATE_DEVICE_FOR_PROVISION_FAILED'
export const DEVICE_ACTIVATION_DATA_VALUES_CHANGED = 'DEVICE_ACTIVATION_DATA_VALUES_CHANGED'
export const OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG = 'OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG'
export const CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG = 'CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG'
export const IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED = 'IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED'
export const IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED = 'IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED'
export const FETCH_DEVICE_PROFILES_STARTED = 'FETCH_DEVICE_PROFILES_STARTED'
export const FETCH_DEVICE_PROFILES_FAILED = 'FETCH_DEVICE_PROFILES_FAILED'
export const FETCH_DEVICE_PROFILES_SUCCESS = 'FETCH_DEVICE_PROFILES_SUCCESS'
export const REFRESH_DEVICE_FOR_PROVISION_STATUS_SUCCESS = 'UPDATE_DEVICE_FOR_PROVISION_STATUS_SUCCESS'
export const REFRESH_DEVICE_FOR_PROVISION_STATUS_ERROR = 'UPDATE_DEVICE_FOR_PROVISION_STATUS_ERROR'
export const SET_DEVICES_ACTIVE_PAGE = 'SET_DEVICES_ACTIVE_PAGE'
export const SET_DEVICES_PAGE_SIZE = 'SET_DEVICES_PAGE_SIZE'
export const SEARCH_DEVICES_FOR_PROVISION = 'SEARCH_DEVICES_FOR_PROVISION'

// SERIAL NUMBERS TO CSV PAGE
export const OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG = 'OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG'
export const CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG = 'CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG'
export const NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED = 'NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED'

// DOWNLINK MESSAGES
export const FETCH_DOWNLINK_MESSAGES_FOR_DEVICE = 'FETCH_DOWNLINK_MESSAGES_FOR_DEVICE'
export const FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS = 'FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_SUCCESS'
export const FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL = 'FETCH_DOWNLINK_MESSAGES_FOR_DEVICE_FAIL'
export const CANCEL_DOWNLINK_MESSAGE = 'CANCEL_DOWNLINK_MESSAGE'
export const CANCEL_DOWNLINK_MESSAGE_SUCCESS = 'CANCEL_DOWNLINK_MESSAGE_SUCCESS'
export const CANCEL_DOWNLINK_MESSAGE_FAILED = 'CANCEL_DOWNLINK_MESSAGE_FAILED'
export const ADD_DOWNLINK_MESSAGE = 'ADD_DOWNLINK_MESSAGE'
export const ADD_DOWNLINK_MESSAGE_SUCCESS = 'ADD_DOWNLINK_MESSAGE_SUCCESS'
export const ADD_DOWNLINK_MESSAGE_FAILED = 'ADD_DOWNLINK_MESSAGE_FAILED'
export const SEARCH_STRING_CHANGED = 'SEARCH_STRING_CHANGED'

// METER CONSTANTS

export const SET_NEW_METER_VALUES = 'SET_NEW_METER_VALUES'
export const UPDATE_NEW_METER_VALUES = 'UPDATE_NEW_METER_VALUES'
export const NEW_METER_VALUES_UPDATE_SUCCESS = 'NEW_METER_VALUES_UPDATE_SUCCESS'
export const NEW_METER_VALUES_UPDATE_ERROR = 'NEW_METER_VALUES_UPDATE_ERROR'

export const DOWNLOAD_FILE_FOR_USER = 'DOWNLOAD_FILE_FOR_USER'
export const DOWNLOAD_FILE_FOR_ADMIN = 'DOWNLOAD_FILE_FOR_ADMIN'
export const DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES = 'DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES'
export const DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES_FOR_ADMIN = 'DOWNLOAD_FILE_WITHIN_SPECIFIC_DATES_FOR_ADMIN'
export const DOWNLOAD_FILE_FOR_USER_FAILED = 'DOWNLOAD_FILE_FOR_USER_FAILED'
export const DOWNLOAD_FILE_FOR_ADMIN2 = 'DOWNLOAD_FILE_FOR_ADMIN2'
export const REMOVE_METER = 'REMOVE_METER'

// MESSAGES
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ACKNOWLEDGE_MESSAGE = 'ACKNOWLEDGE_MESSAGE'

// REPORTS
export const DOWNLOAD_ACTIVE_METERS_REPORT = 'DOWNLOAD_ACTIVE_METERS_REPORT'
export const DOWNLOAD_ACTIVE_METERS_REPORT_FAILED = 'DOWNLOAD_ACTIVE_METERS_FAILED'
