import React from 'react'
import { connect } from 'react-redux'

import {
  closeActivateDeviceForProvisionDialog,
  deviceActivationDataValuesChanged,
  activateDeviceForProvision
} from '../../../redux/devices-for-provision/devices-for-provision-actions'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Grid } from '@mui/material'
import { FetchCompaniesAction } from '../../../redux/admin/admin-actions'

class ActivateDeviceForProvisionDialog extends React.Component {
  componentDidMount () {
    if (!this.props.companiesFetching) {
      this.props.dispatch(FetchCompaniesAction())
    }
  }

  closeDialog () {
    this.props.dispatch(closeActivateDeviceForProvisionDialog())
  }

  disabled () {
    return false
  }

  activateDeviceForProvision () {
    this.props.dispatch(activateDeviceForProvision(this.props.deviceActivationData))
  }

  getActions () {
    return [
      <Button key='cancel' color='primary' onClick={() => { this.closeDialog() }}>Peruuta</Button>,
      <Button
        key='activate'
        disabled={this.disabled()}
        onClick={() => { this.activateDeviceForProvision() }}
        color='secondary'
      >Aktivoi
      </Button>
    ]
  }

  valueChanged = (field, value) => {
    this.props.dispatch(deviceActivationDataValuesChanged({ ...this.props.deviceActivationData, [field]: value }))
  }

  render () {
    return (
      <Dialog
        open={this.props.activateDeviceForProvisionDialogOpen}
      >
        <DialogTitle>{'Aktivoi mittari sarjanumerolla: ' + this.props.deviceActivationData.deviceForProvision.serialNumber}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                fullWidth
                id='activationdata-location'
                value={this.props.deviceActivationData.location}
                onChange={(event) => { this.valueChanged('location', event.target.value) }}
                label='Käyttöpaikka'
                variant='standard'
              />
            </Grid>
            <Grid item xs>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>
                  Asiakas
                </InputLabel>
                <Select
                  fullWidth
                  value={this.props.deviceActivationData.company}
                  id='activationdata-company'
                  onChange={(event) => { this.valueChanged('company', event.target.value) }}
                  variant='standard'
                >
                  <MenuItem key='no-selection' value={null} />
                  {this.props.companies.map(c => <MenuItem key={c.id} value={c}>{c.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps (state) {
  const { devicesForProvisionReducer } = state.vesimittari
  const { companies, companiesFetching } = state.vesimittari.admin
  const {
    deviceActivationData,
    activateDeviceForProvisionDialogOpen
  } = devicesForProvisionReducer
  return {
    deviceActivationData,
    activateDeviceForProvisionDialogOpen,
    companies,
    companiesFetching
  }
}

export default connect(mapStateToProps)(ActivateDeviceForProvisionDialog)
