import React from 'react'
import { withStyles } from 'tss-react/mui'
import { blue } from '@mui/material/colors'
import Switch from '@mui/material/Switch'
import { Dialog, DialogTitle, DialogActions, Typography, Stack } from '@mui/material'
import { type AddDownlinkMessage, DownlinkMessageType } from '../../../redux/downlink-messages/downlink-messages-types'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { ADD_DOWNLINK_MESSAGE } from '../../../constants/action-types'
import {
  SettingsGeneralButton,
  SettingsSaveButton,
  StyledFieldsText
} from './MeterConfiguration.style'
import { meterSupportsDownlinkMessage } from '../../../utils/meter-utils'
import { DeviceType } from '../../../redux/meters/meter-types'
import { useTranslation } from 'react-i18next'

const CustomSwitch = withStyles(
  Switch,
  () => ({
    switchBase: {
      color: blue[500],
      '&$checked': {
        color: blue[500]
      },
      '&$checked + $track': {
        backgroundColor: '#2c7be5'
      }
    },
    checked: {},
    track: {}
  })
)

export function ToggleSendTemperature({ dispatch, newMeterValue, enabled }) {
  const t = useTranslation().t

  const [sendTemperature, setSendTemperature] = React.useState(
    !!newMeterValue.sendTemperature
  )

  const [openConfirmation, setOpenConfirmation] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendTemperature(event.target.checked)
    setOpenConfirmation(true)
  }
  const confirmationDialogClose = () => {
    setOpenConfirmation(false)
  }

  const confirmationCancelHandler = () => {
    setOpenConfirmation(false)
    setSendTemperature(!sendTemperature)
  }
  const sendSetSendTemperature = (meterId: string) => {
    const sendTemperatureMsg: AddDownlinkMessage = {
      type: ADD_DOWNLINK_MESSAGE,
      payload: {
        type: DownlinkMessageType.SET_SEND_TEMPERATURE,
        deviceId: meterId,
        sendTemperature
      }
    }

    dispatch(sendTemperatureMsg)
    setOpenConfirmation(false)
  }
  return (
    <div>
      <StyledFieldsText>
        {`${t('settings_dialog.downlink_messages.set_send_temperature')} ${
          !enabled
            ? t('settings_dialog.downlink_messages.unsupported_type')
            : ''
        }`}
      </StyledFieldsText>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>
          {`${t('settings_dialog.downlink_messages.send_temperature_off')}`}
        </Typography>
        <CustomSwitch checked={sendTemperature} onChange={handleChange} name="sendTemperature" disabled={!enabled} />
        <Typography>
          {`${t('settings_dialog.downlink_messages.send_temperature_on')}`}
        </Typography>
      </Stack>
      <Dialog
        open={openConfirmation}
        onClose={confirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('settings_dialog.set_send_temperature_confirmation')}
        </DialogTitle>
        <DialogActions>
          <SettingsGeneralButton onClick={confirmationCancelHandler}>
            {t('settings_dialog.cancel')}
          </SettingsGeneralButton>
          <SettingsSaveButton
            onClick={() => { sendSetSendTemperature(newMeterValue.id) }}
            autoFocus
          >
            {t('settings_dialog.yes')}
          </SettingsSaveButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function ConnectedToggleSendTemperature() {
  const dispatch = useDispatch()
  const newMeterValues = useSelector(
    (state: RootState): any => state.vesimittari.meterReducer.newMeterValues
  )
  const enabled = meterSupportsDownlinkMessage(
    DeviceType[newMeterValues.type],
    DownlinkMessageType.SET_SEND_TEMPERATURE
  )

  return (
    <ToggleSendTemperature
      dispatch={dispatch}
      newMeterValue={newMeterValues}
      enabled={enabled}
    />
  )
}
