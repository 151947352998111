import React from 'react'
import { connect } from 'react-redux'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

import DeviceForProvisionFields from './DeviceForProvisionFields'

import {
  closeCreateDeviceForProvisionDialog,
  createDeviceForProvision,
  newDeviceForProvisionValuesChanged
} from '../../../redux/devices-for-provision/devices-for-provision-actions'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'

class CreateDeviceForProvisionDialog extends React.Component {
  closeDialog () {
    this.props.dispatch(closeCreateDeviceForProvisionDialog())
  }

  disabled () {
    return !this.props.newDeviceForProvision.serialNumber ||
      !this.props.newDeviceForProvision.devEUI ||
      !this.props.newDeviceForProvision.appEUI ||
      !this.props.newDeviceForProvision.appKey ||
      !this.props.newDeviceForProvision.deviceProfileId
  }

  createDeviceForProvision () {
    this.props.dispatch(createDeviceForProvision(this.props.newDeviceForProvision))
  }

  getActions () {
    return [
      <Button key='cancel' color='primary' onClick={() => { this.closeDialog() }}>Peruuta</Button>,
      <Button
        key='add'
        disabled={this.disabled()}
        onClick={() => { this.createDeviceForProvision() }}
        color='secondary'
      >Lisää
      </Button>
    ]
  }

  valueChanged = (field, value) => {
    this.props.dispatch(newDeviceForProvisionValuesChanged({ ...this.props.newDeviceForProvision, [field]: value }))
  }

  render () {
    return (
      <Dialog
        open={this.props.createDeviceForProvisionDialogOpen}
      >
        <DialogTitle>Lisää aktivoitava mittari</DialogTitle>
        <DialogContent>
          <DeviceForProvisionFields
            deviceForProvision={this.props.newDeviceForProvision}
            valueChanged={this.valueChanged}
            deviceProfiles={this.props.deviceProfiles}
            loadingDeviceProfiles={this.props.loadingDeviceProfiles}
          />
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps (state) {
  const { devicesForProvisionReducer } = state.vesimittari
  const {
    newDeviceForProvision,
    createDeviceForProvisionDialogOpen,
    deviceProfiles,
    loadingDeviceProfiles
  } = devicesForProvisionReducer
  return {
    newDeviceForProvision,
    createDeviceForProvisionDialogOpen,
    deviceProfiles,
    loadingDeviceProfiles
  }
}

export default connect(mapStateToProps)(CreateDeviceForProvisionDialog)
