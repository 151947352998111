import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { type RootState } from '../../interfaces/RootState'
import { dateToLocaleOrDefault, getLanguageAsDateFnsLocaleObject } from '../../utils/utils'
import { DownloadFileForAdminAction, DownloadFileForUserAction, DownloadFileWithinSpecificDatesAction, DownloadFileWithinSpecificDatesForAdminAction } from '../../redux/companies/companies-actions'
import { DialogTitle, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Grid } from '@mui/material'
import { type VesimittariDispatch } from '../../configureStore'
import { ModalCancelButton, ModalConfirmButton } from '../common/ui-components/Buttons.style'
import { CsvType } from '../../redux/companies/companies-types'
import { ToggleDownloadDialogAction } from '../../redux/meter-table/meter-table-actions'
import { useTranslation } from 'react-i18next'
import { type TFunction } from 'i18next'

interface GenerateTransferFileDialogProps {
  downloadDialogOpen: boolean
  isAdmin: boolean
  customerId: string
  dispatch: VesimittariDispatch
}

const formErrors = (downloadType: CsvType, rangeStart: Date | undefined, rangeEnd: Date | undefined, t: TFunction): string[] => {
  const errors: string[] = []
  if (downloadType === CsvType.DATE_SPECIFIC) {
    if (rangeStart === undefined) {
      errors.push(t('errors.select_start_date'))
    }
    if (rangeEnd === undefined) {
      errors.push(t('errors.select_start_date'))
    }
    if (rangeStart !== undefined && rangeEnd !== undefined && rangeStart > rangeEnd) {
      errors.push(t('date_range_invalid'))
    }
  }
  return errors
}

function GenerateTransferFileDialog(props: GenerateTransferFileDialogProps) {
  const [downloadDateSelected, setDownloadDateSelected] = useState<Date>(new Date(Date.now()))
  const [rangeStart, setRangeStart] = useState<Date | undefined>()
  const [rangeEnd, setRangeEnd] = useState<Date | undefined>()
  const [downloadType, setDownloadType] = useState<CsvType>(CsvType.CSV)
  const t = useTranslation().t
  const errors = formErrors(downloadType, rangeStart, rangeEnd, t)
  const locale = getLanguageAsDateFnsLocaleObject()

  const generateFileForUser = () => {
    if (downloadType === CsvType.DATE_SPECIFIC) {
      props.dispatch(DownloadFileWithinSpecificDatesAction({ startDateSelected: rangeStart, endDateSelected: rangeEnd, downloadType }))
    } else {
      props.dispatch(DownloadFileForUserAction({ downloadDateSelected, downloadType }))
    }
  }

  const generateFileForAdmin = () => {
    if (downloadType === CsvType.DATE_SPECIFIC) {
      props.dispatch(DownloadFileWithinSpecificDatesForAdminAction({ startDateSelected: rangeStart, endDateSelected: rangeEnd, downloadType, companyId: props.customerId }))
    } else {
      props.dispatch(DownloadFileForAdminAction({ downloadDateSelected, downloadType, companyId: props.customerId }))
    }
  }

  let showSelectedDownloadDates: string
  if (downloadType === CsvType.DATE_SPECIFIC) {
    showSelectedDownloadDates = t('transferfile_dialog.download_transferfile')
  } else {
    showSelectedDownloadDates = t('transferfile_dialog.download_transferfile') + ` - ${dateToLocaleOrDefault(downloadDateSelected)}`
  }
  return (
    <Dialog
      data-testid="dataDialog"
      open={props.downloadDialogOpen}
      onClose={() => props.dispatch(ToggleDownloadDialogAction())}
      maxWidth={downloadType === CsvType.DATE_SPECIFIC ? 'md' : 'xs'}
      fullWidth>
      <DialogTitle>{`${showSelectedDownloadDates}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {
            downloadType === CsvType.DATE_SPECIFIC
              ? (
              <Grid item xs={12} md={12} style={{ marginLeft: '-1.5em', display: 'flex' }}>
                <Grid item xs={6}>
                  <label style={{ margin: '1.5em' }}>{t('transferfile_dialog.date_range_start')}</label>
                  <DayPicker data-testid="startDatePicker" selected={rangeStart} onDayClick={setRangeStart} defaultMonth={downloadDateSelected} locale={locale} />
                </Grid>
                <Grid item xs={6}>
                  <label style={{ margin: '1.5em' }}>{t('transferfile_dialog.date_range_end')}</label>
                  <DayPicker data-testid="endDatePicker" selected={rangeEnd} onDayClick={setRangeEnd} defaultMonth={downloadDateSelected} locale={locale} />
                </Grid>
              </Grid>
                )
              : (
              <Grid item xs={12} style={{ marginLeft: '-1.5em' }}>
                <DayPicker selected={downloadDateSelected} onDayClick={setDownloadDateSelected} defaultMonth={downloadDateSelected} locale={locale} />
              </Grid>)
          }
          {
            errors.length !== 0 && (
              <Grid item xs={12}>
                <span style={{ padding: '1em', marginBottom: '1em', backgroundColor: '#d32f2f', borderRadius: '4px', display: 'inline-block' }}>{errors.join(' , ')}</span>
              </Grid>
            )
          }
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t('transferfile_dialog.type')}</InputLabel>
              <Select
                data-testid="selectModal"
                variant="standard"
                value={downloadType}
                onChange={(e) => {
                  setDownloadType(e.target.value as CsvType)
                }}
              >
                <MenuItem value={CsvType.CSV}>CSV</MenuItem>
                <MenuItem value={CsvType.CSV_FIN}>CSV (FIN)</MenuItem>
                <MenuItem value={CsvType.COMPANY_SPECIFIC}>{t('transferfile_dialog.transferfile')}</MenuItem>
                <MenuItem data-testid="menuItem" value={CsvType.DATE_SPECIFIC}>{t('transferfile_dialog.readings_for_range')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ModalCancelButton
          onClick={() => {
            props.dispatch(ToggleDownloadDialogAction())
          }}
        >
          {t('common.actions.cancel')}
        </ModalCancelButton>
        <ModalConfirmButton
          data-testid="modalConfirmButton"
          color='secondary' disabled={errors.length !== 0}
          onClick={() => { props.isAdmin && props.customerId && props.customerId !== 'all' ? generateFileForAdmin() : generateFileForUser() } }
        >
          {t('common.actions.download')}
        </ModalConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

export default function ConnectedGenerateTransferFileDialog() {
  const dispatch = useDispatch()
  const downloadDialogOpen = useSelector((state: RootState) => state.vesimittari.meterTable.downloadDialogOpen) ?? false
  const isAdmin = useSelector((state: RootState) => state.vesimittari.auth.isAdmin)
  const customerId = useSelector((state: RootState) => state.vesimittari.data.customerFilter)
  return (
    <GenerateTransferFileDialog

      dispatch={dispatch}
      downloadDialogOpen={downloadDialogOpen}
      isAdmin={isAdmin}
      customerId={customerId} />
  )
}
