import { createAction } from '@reduxjs/toolkit'
import {
  CHANGE_ALARM_SETTING,
  FETCH_ALARM_SETTINGS,
  RESPONSE_ALARM_SETTINGS
} from '../../constants/action-types'
import {
  type ChangeAlarmSettingPayload,
  type FetchAlarmSettingsForDevicePayload,
  type AlarmSettingsForDeviceResponsePayload
} from './alarm-settings-types'

export const ChangeAlarmSettingsAction = createAction<ChangeAlarmSettingPayload>(CHANGE_ALARM_SETTING)
export const FetchAlarmSettingsForDeviceAction = createAction<FetchAlarmSettingsForDevicePayload>(FETCH_ALARM_SETTINGS)
export const AlarmSettingsForDeviceResponseAction = createAction<AlarmSettingsForDeviceResponsePayload>(RESPONSE_ALARM_SETTINGS)
