import { createAction } from '@reduxjs/toolkit'
import { type Notice } from './notices-types'

export const FetchNoticesAction = createAction<void>('FETCH_NOTICES_ACTION')
export const FetchNoticesSuccessAction = createAction<Notice[]>('FETCH_NOTICES_SUCCESS_ACTION')
export const FetchNoticesFailureAction = createAction<any>('FETCH_NOTICES_FAILURE_ACTION')

export const CreateNewNoticeAction = createAction<string>('CREATE_NEW_NOTICE_ACTION')
export const CreateNewNoticeSuccessAction = createAction<Notice>('CREATE_NEW_NOTICE_SUCCESS_ACTION')
export const CreateNewNoticeFailureAction = createAction<any>('CREATE_NEW_NOTICE_FAILURE_ACTION')

export const DeleteNoticeAction = createAction<string>('DELETE_NOTICE_ACTION')
export const DeleteNoticeSuccessAction = createAction<string>('DELETE_NOTICE_SUCCESS_ACTION')
export const DeleteNoticeFailureAction = createAction<any>('DELETE_NOTICE_FAILURE_ACTION')

export const MarkNoticeAsReadAction = createAction<string>('MARK_NOTICE_AS_READ_ACTION')
export const MarkNoticeAsReadSuccessAction = createAction<Notice>('MARK_NOTICE_AS_READ_SUCCESS_ACTION')
export const MarkNoticeAsReadFailureAction = createAction<any>('MARK_NOTICE_AS_READ_FAILURE_ACTION')

export const ToggleCreateNoticeModalAction = createAction<void>('TOGGLE_CREATE_NOTICE_MODAL')
