import { createAction } from '@reduxjs/toolkit'
import {
  type FetchDataArrayPayload,
  type FetchDataArrayFailedPayload,
  type FetchDataArraySuccessPayload,
  type FetchDataPayload,
  type FetchDataSuccessPayload,
  type FetchDataFailedPayload,
  type FetchMeterHistoryValuesPayload,
  type FetchMeterHistoryValuesSuccessPayload,
  type FetchMeterHistoryValuesFailedPayload
} from './meter-data-types'
import { FETCH_DATA, FETCH_DATA_ARRAY, FETCH_DATA_ARRAY_FAILED, FETCH_DATA_ARRAY_SUCCESS, FETCH_DATA_FAILED, FETCH_DATA_SUCCESS, FETCH_METER_HISTORY_VALUES, FETCH_METER_HISTORY_VALUES_FAILED, FETCH_METER_HISTORY_VALUES_SUCCESS } from '../../constants/action-types'

export const FetchDataArrayAction = createAction<FetchDataArrayPayload>(FETCH_DATA_ARRAY)
export const FetchDataArraySuccessAction = createAction<FetchDataArraySuccessPayload>(FETCH_DATA_ARRAY_SUCCESS)
export const FetchDataArrayFailedAction = createAction<FetchDataArrayFailedPayload>(FETCH_DATA_ARRAY_FAILED)

export const FetchDataAction = createAction<FetchDataPayload>(FETCH_DATA)
export const FetchDataSuccessAction = createAction<FetchDataSuccessPayload>(FETCH_DATA_SUCCESS)
export const FetchDataFailedAction = createAction<FetchDataFailedPayload>(FETCH_DATA_FAILED)

export const FetchMeterHistoryValuesAction = createAction<FetchMeterHistoryValuesPayload>(FETCH_METER_HISTORY_VALUES)
export const FetchMeterHistoryValuesSuccessAction = createAction<FetchMeterHistoryValuesSuccessPayload>(FETCH_METER_HISTORY_VALUES_SUCCESS)
export const FetchMeterHistoryValuesFailedAction = createAction<FetchMeterHistoryValuesFailedPayload>(FETCH_METER_HISTORY_VALUES_FAILED)
