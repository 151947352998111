import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { CloseErrorToastAction } from '../../../redux/error/error-actions'
import { type RootState } from '../../../interfaces/RootState'
import { Alert } from '@mui/material'

const ErrorToast = () => {
  const errorMessage = useSelector((state: RootState) => state.vesimittari.error.message)
  const dispatch = useDispatch()
  return (
    <Snackbar open={!!errorMessage}
      autoHideDuration={6000}
      onClose={() => dispatch(CloseErrorToastAction())}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <Alert severity='error' onClose={() => dispatch(CloseErrorToastAction())}
        sx={{
          width: '100%',
          alignItems: 'center',
          fontSize: 'large',
          whiteSpace: 'pre-line'
        }}>
        <span>{errorMessage}</span>
      </Alert>
    </Snackbar>
  )
}

export default ErrorToast
