import { type User } from '../../interfaces/User'
import { type AlarmSettingsForDevice } from '../alarm-settings/alarm-settings-types'
import { type Group } from '../settings/settings-type'

export interface MeterState {
  newMeterValues: Meter | null
}

export type MeterRecord = Record<string, Meter>

export interface Meter {
  FPort?: number
  alarmSettings: AlarmSettingsForDevice
  assignedToCustomer: boolean
  baseValue: number
  company: Company
  dataTransferInterval: number
  deleted: boolean
  firstMeterDataEntry: number
  group: Group
  id: string
  isLora: boolean
  labels: string[]
  latestLoraStatus: LoraStatus
  leakageWarningActive: boolean
  flowLimitAlertActive: boolean
  location?: string
  loraId: string
  mainWaterMeter: boolean
  pulseInLiters: number
  sendTemperature: boolean
  serialNumber?: string
  sigfoxId: string
  updatedAt: number
  waterUsed: number
  temperatureAlertActive: boolean
  transmitterSerialNumber?: string
  type: DeviceType
}

/**
 * TODO: move to right file when we get there
 */
export interface Company {
  adminCompany: boolean
  alertRecipients?: string
  alertsEnabled: boolean
  asId?: string
  canUpdateInterval: boolean
  emailAlarmInterval: string // TODO: enumize
  emailAlertRecipients: string[]
  emailAlertsEnabled: boolean
  emailAlertsInterval: number // TODO: enumize?
  id: string
  integration: Integration
  lrcAsKey?: string
  name: string
  placeHolderCompany: boolean
  postalCode?: string
  postalDistrict?: string
  smsAlarmInterval: string // TODO: enumize
  smsAlertRecipients: string[]
  smsAlertsEnabled: boolean
  smsAlertsInterval: number // TODO: enumize?
  streetAddress?: string
  temperatureAlertLowerLimit?: number
  temperatureAlertUpperLimit?: number
  temperatureAlertsEnabled: boolean
  town?: string
  users: User[]
  vatId?: string
}

export interface Integration {
  cronString?: string
  enabled: boolean
  inPulseFormat?: string
  integrationType?: string // TODO: enumize
  tietoIdentifier?: string
  url?: string
  user?: string
}

export interface LoraStatus {
  backwardFlow: boolean
  batteryWarning: boolean
  hardwareError: boolean
  leakage: boolean
  magneticFraud: boolean
  moduleRemoved: boolean
  opticalFraud: boolean
  switchError: boolean
  tooBigFlow: boolean
  fraudDetected: boolean
  resetDetected: boolean
  emptyTubeDetected: boolean
  freezeDetected: boolean
}

export enum DeviceType {
  SIGFOX = 'SIGFOX',
  LORA = 'LORA',
  ADEUNIS = 'ADEUNIS',
  ADEUNIS_v3 = 'ADEUNIS_v3',
  BMETERS_v2 = 'BMETERS_v2',
  BMETERS_COMBO = 'BMETERS_COMBO',
  BMETERS_LORA_PULSE = 'BMETERS_LORA_PULSE',
  HYDROSONIC_M1 = 'HYDROSONIC_M1'
}

/**
 * * Function that checks whether the consumption of the meter is calculated from pulses and pulse size.
 */
export const isPulseDevice = (deviceType: DeviceType): boolean => {
  return deviceType === DeviceType.ADEUNIS ||
      deviceType === DeviceType.ADEUNIS_v3 ||
      deviceType === DeviceType.BMETERS_LORA_PULSE
}
