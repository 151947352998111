import { type AnyAction } from 'redux'
import { CloseErrorToastAction, OpenEmptyLabelErrorAction, OpenErrorAction, OpenSameLabelErrorAction } from './error-actions'
import { type ErrorState } from './error-types'

const initialState: ErrorState = {
  message: ''
}

export default function errorReducer(state: ErrorState = initialState, action: AnyAction): ErrorState {
  if (OpenErrorAction.match(action)) {
    return {
      ...state,
      message: action.payload
    }
  }
  if (CloseErrorToastAction.match(action)) {
    return {
      ...state,
      message: ''
    }
  }
  if (OpenEmptyLabelErrorAction.match(action)) {
    return {
      ...state,
      message: 'Merkintä ei voi olla tyhjä'
    }
  }
  if (OpenSameLabelErrorAction.match(action)) {
    return {
      ...state,
      message: 'Merkintä on jo lisätty'
    }
  }
  return state
}
