import {
  Select,
  type SelectChangeEvent,
  Button,
  Chip,
  MenuItem
} from '@mui/material'
import React, { useState } from 'react'
import { FormFieldTitle, FormInputContainer } from './CustomersView.style'
import { type User } from '../../../interfaces/User'
import {
  OpenEditCompanyDialogAction,
  UpdateCompanyAction
} from '../../../redux/companies/companies-actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function CustomerUsers({
  editedOrganization,
  notAssignedUsersCompany
}: {
  editedOrganization: any
  notAssignedUsersCompany: any
}): JSX.Element {
  const dispatch = useDispatch()

  const t = useTranslation().t

  const [userToAdd, setUserToAdd] = useState<string>('')

  const isCompanySelected =
    editedOrganization.id != null && editedOrganization.id !== ''

  function onSelectUserToAdd(e: SelectChangeEvent<string>) {
    e.preventDefault()

    if (!e.target.value || e.target.value === '') {
      return
    }

    setUserToAdd(e.target.value)
  }
  function onClickAddUserToCompany(company: any, user: string) {
    if (!isCompanySelected) {
      return
    }

    const newUsers = company.users.concat({ username: user })
    const newCompanyData = Object.assign({}, company, { users: newUsers })
    dispatch(UpdateCompanyAction({ id: company.id, data: newCompanyData }))

    // Remove the user from NOT_ASSIGNED_USERS company (if we aren't trying to add there)
    if (company.id !== notAssignedUsersCompany.id) {
      const updatedUsers = notAssignedUsersCompany.users.filter(
        (i: User) => i.username !== user
      )
      const newNotAssignedUsersCompanyData = Object.assign(
        {},
        notAssignedUsersCompany,
        { users: updatedUsers }
      )

      dispatch(
        UpdateCompanyAction({
          id: notAssignedUsersCompany.id,
          data: newNotAssignedUsersCompanyData
        })
      )
    }

    dispatch(OpenEditCompanyDialogAction({ company: newCompanyData })) // Refresh to show new user
    setUserToAdd('') // Clear input
  }

  function onClickDeleteUserFromCompany(
    company: any,
    user: string,
    refresh: boolean = true
  ) {
    if (!isCompanySelected) {
      return
    }

    const updatedUsers = company.users.filter((i: User) => i.username !== user)
    const newCompanyData = Object.assign({}, company, { users: updatedUsers })

    dispatch(UpdateCompanyAction({ id: company.id, data: newCompanyData }))

    // Do we assign the user to NOT_ASSIGNED_USERS?
    if (company.id !== notAssignedUsersCompany.id) {
      const newUsers = notAssignedUsersCompany.users.concat({ username: user })
      const newNotAssignedUsersCompanyData = Object.assign(
        {},
        notAssignedUsersCompany,
        { users: newUsers }
      )
      dispatch(
        UpdateCompanyAction({
          id: notAssignedUsersCompany.id,
          data: newNotAssignedUsersCompanyData
        })
      )
    }

    // Should we refresh the data to be shown? (Are we deleting via chips?)
    if (refresh) {
      dispatch(OpenEditCompanyDialogAction({ company: newCompanyData }))
    }
  }

  return (
    <div>
      <FormFieldTitle>{t('user')}</FormFieldTitle>
      <FormInputContainer>
        <Select
          id="user-email"
          variant="outlined"
          value={userToAdd}
          disabled={!isCompanySelected}
          onChange={onSelectUserToAdd}
        >
          {notAssignedUsersCompany?.users.map((i: User) => (
              <MenuItem key={i.username} id={i.username} value={i.username}>
                {i.username}
              </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          disabled={!isCompanySelected}
          onClick={() => { onClickAddUserToCompany(editedOrganization, userToAdd) }}
          color="inherit"
        >
          {t('customer_details.add_user')}
        </Button>
      </FormInputContainer>
      {editedOrganization.users?.map((i) => (
          <Chip
            label={i.username}
            key={i.username}
            onDelete={() => { onClickDeleteUserFromCompany(editedOrganization, i.username) }
            }
          />
      ))}
    </div>
  )
}
