import { takeEvery, put, call, select, all, takeLatest } from 'redux-saga/effects'
import {
  type CreateCompanyPayload,
  type DeleteCompanyPayload,
  type DownloadFileForAdminPayload,
  type DownloadFileForAdminPayload2,
  type DownloadFileForUserPayload,
  type DownloadFileWithinSpecificDatesForAdminPayload,
  type DownloadFileWithinSpecificDatesPayload,
  type UpdateCompanyAlarmPayload,
  type UpdateCompanyPayload
} from './companies-types'
import {
  CreateCompanyAction,
  DeleteCompanyAction,
  DisableInfoDialogSaveAction,
  DownloadFileForAdmin2Action,
  DownloadFileForAdminAction,
  DownloadFileForUserAction,
  DownloadFileForUserFailedAction,
  DownloadFileWithinSpecificDatesAction,
  DownloadFileWithinSpecificDatesForAdminAction,
  UpdateCompanyAction,
  UpdateCompanyAlarmAction
} from './companies-actions'
import Api from '../../api/api'
import selectIdToken from '../selectors/select-id-token'
import fileDownload from 'js-file-download'
import { OpenErrorAction } from '../error/error-actions'
import { ApiError, handleErrors } from '../../redux/error/error-utils'
import { API_URL } from '../../constants/urls'
import FileDownload from 'react-file-download'

import { FetchingUserInformationAction, LogoutSuccessAction } from '../auth/auth-actions'
import { FetchCompaniesAction } from '../admin/admin-actions'
import { AddMessageAction } from '../messages/messages-actions'
import { MessageTypes } from '../messages/messages-types'
import { SetCompanyAlarmAction } from '../meter-table/meter-table-actions'

export function * downloadFileForUser(action: { payload: DownloadFileForUserPayload }) {
  const date = action.payload.downloadDateSelected
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.CompaniesApi.downloadFileForUser, idToken, action.payload)
  if (response.error) {
    yield put(OpenErrorAction(response.error))
    yield put(DownloadFileForUserFailedAction({ error: response.error }))
  } else {
    const filename = `export${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}.csv`
    fileDownload(response, filename)
  }
}

const generateFileName = (startDate: Date, endDate: Date) => {
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0')
  const downloadStartDate = startDate.getDate().toString().padStart(2, '0')
  const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0')
  const downloadEndDate = endDate.getDate().toString().padStart(2, '0')
  return `export${startDate.getFullYear()}${startMonth}${downloadStartDate}-${endDate.getFullYear()}${endMonth}${downloadEndDate}.csv`
}

export function * downloadFileWithinSpecificDates(action: { payload: DownloadFileWithinSpecificDatesPayload }) {
  const startDate = action.payload.startDateSelected
  const endDate = action.payload.endDateSelected
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.CompaniesApi.downloadFileWithinSpecificDates, idToken, action.payload)
  if (response.error) {
    yield put(OpenErrorAction(response.error))
    yield put(DownloadFileForUserFailedAction({ error: response.error }))
  } else {
    (startDate !== undefined && endDate !== undefined) ? fileDownload(response, generateFileName(startDate, endDate)) : yield put(DownloadFileForUserFailedAction({ error: 'Start date or end date missing' }))
  }
}

export function * downloadFileWithinSpecificForAdminDates(action: { payload: DownloadFileWithinSpecificDatesForAdminPayload }) {
  const startDate = action.payload.startDateSelected
  const endDate = action.payload.endDateSelected
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.CompaniesApi.downloadFileWithinSpecificDatesForAdmin, idToken, action.payload)
  if (response.error) {
    yield put(OpenErrorAction(response.error))
    yield put(DownloadFileForUserFailedAction({ error: response.error }))
  } else {
    (startDate !== undefined && endDate !== undefined) ? fileDownload(response, generateFileName(startDate, endDate)) : yield put(DownloadFileForUserFailedAction({ error: 'Start date or end date missing' }))
  }
}

export function * downloadFileForAdmin(action: { payload: DownloadFileForAdminPayload }) {
  const date = action.payload.downloadDateSelected
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.CompaniesApi.downloadFileForAdminUser, idToken, action.payload)
  if (response.error) {
    yield put(OpenErrorAction(response.error))
    yield put(DownloadFileForUserFailedAction({ error: response.error }))
  } else {
    const filename = `export${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}.csv`
    fileDownload(response, filename)
  }
}

export function * downloadFileForAdmin2(action: { payload: DownloadFileForAdminPayload2 }) {
  const companyId: number = action.payload.companyId
  const useGeneric: any = action.payload.useGeneric
  try {
    yield call(async () => {
      await fetch(`${API_URL}/company/${companyId}/download?type=${useGeneric}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`
        }
      })
        .then(async response => await handleErrors(response))
        .then(async response => await response.text())
        .then(response => {
          const date = new Date()
          const month = parseInt(date.getMonth().toString(), 10) + 1
          const filename = 'export' + date.getFullYear() + '_' + month + '_' + date.getDate() + '.csv'
          FileDownload(response, filename)
        })
    })
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(OpenErrorAction('Tiedoston lataus epäonnistui'))
  }
}

export function * updateCompany(action: { payload: UpdateCompanyPayload }) {
  const companyId: number = action.payload.id
  const data: any = action.payload.data
  const notAssignedId: number | undefined = action.payload.notAssignedId
  const notAssignedData: any | undefined = action.payload.notAssignedData
  try {
    yield call(async () => {
      return await fetch(`${API_URL}/company/${companyId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(async response => await handleErrors(response))
        .then(async response => await response.json())
    })
    if (!action.payload.notAssignedId) {
      yield all([put(FetchCompaniesAction()),
        put(DisableInfoDialogSaveAction()),
        put(FetchingUserInformationAction())])
    } else {
      yield all([put(FetchCompaniesAction()),
        put(UpdateCompanyAction({ id: notAssignedId, data: notAssignedData }))])
    }
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(OpenErrorAction('Yrityksen päivitys epäonnistui'))
  }
}

export function * createCompany(action: { payload: CreateCompanyPayload }) {
  const data: any = action.payload.data
  try {
    yield call(async () => {
      return await fetch(`${API_URL}/company`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(async response => await handleErrors(response))
        .then(async response => await response.json())
    })
    yield all([put(FetchCompaniesAction())])
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(OpenErrorAction('Yrityksen luonti epäonnistui'))
  }
}

export function * deleteCompany(action: { payload: DeleteCompanyPayload }) {
  const companyId: number = action.payload.companyId
  try {
    yield call(async () => {
      return await fetch(`${API_URL}/company/${companyId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json'
        }
      })
        .then(async response => await handleErrors(response))
    })
    yield all([put(FetchCompaniesAction())])
  } catch (error: any) {
    if (error instanceof ApiError && error.forceLogout) {
      yield put(LogoutSuccessAction())
    }
    yield put(OpenErrorAction('Yrityksen poisto epäonnistui'))
  }
}

export function * setCompanyAlarm(action: { payload: UpdateCompanyAlarmPayload }) {
  const { companyAlarm, i18n } = action.payload
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(Api.CompaniesApi.setCompanyAlarm, idToken, companyAlarm)
    if (response.error) {
      yield put(OpenErrorAction(i18n.t('company_alarm_dialog.update_failed_toast')))
    } else {
      yield put(AddMessageAction(i18n.t('company_alarm_dialog.update_success_toast'), MessageTypes.success))
      yield put(SetCompanyAlarmAction({
        companyId: null,
        alarmType: null,
        state: false,
        maxFlowPerHour: undefined
      }))
    }
  } catch (error) {
    yield put(OpenErrorAction(i18n.t('company_alarm_dialog.update_failed_toast')))
  }
}

export default function * companiesSagas() {
  yield takeEvery(DownloadFileForUserAction, downloadFileForUser)
  yield takeEvery(DownloadFileForAdminAction, downloadFileForAdmin)
  yield takeLatest(UpdateCompanyAction, updateCompany)
  yield takeLatest(CreateCompanyAction, createCompany)
  yield takeLatest(DeleteCompanyAction, deleteCompany)
  yield takeLatest(DownloadFileForAdmin2Action, downloadFileForAdmin2)
  yield takeEvery(DownloadFileWithinSpecificDatesAction, downloadFileWithinSpecificDates)
  yield takeEvery(DownloadFileWithinSpecificDatesForAdminAction, downloadFileWithinSpecificForAdminDates)
  yield takeLatest(UpdateCompanyAlarmAction, setCompanyAlarm)
}
