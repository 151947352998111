import React from 'react'
import { TextField } from '@mui/material'
import { AddButtonContainer } from '../filters/Filters.style'
import Add from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

export default function LabelsInput({ labelValueChange, insertLabelHandler, labelValue }) {
  const t = useTranslation().t

  const tooltip = t('main_meters.add_label_tooltip')
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField variant="outlined" size="small" onChange={labelValueChange} placeholder={tooltip} value={labelValue} style={{ width: 'max-content', flex: 0.4, marginRight: '14px' }} inputProps={{ 'data-testid': 'label-input' }} />
      <AddButtonContainer aria-controls='add-labels' aria-haspopup={true} onClick={insertLabelHandler} data-testid="label-submit">
        <Add style={{ color: '#ffffff', alignSelf: 'center', margin: 'auto', cursor: 'pointer' }}/>
      </AddButtonContainer>
    </div>
  )
}
