import { type AnyAction } from 'redux'
import { AddDownlinkMessageSuccessAction } from '../downlink-messages/downlink-messages-actions'
import * as actions from './meter-actions'
import { type MeterState } from './meter-types'

const initialState: MeterState = {
  newMeterValues: null
}

export default function meterReducer(state: MeterState = initialState, action: AnyAction): MeterState {
  switch (action.type) {
    case actions.SetNewMeterValuesAction.type: {
      return { ...state, newMeterValues: action.payload }
    }
    case actions.ToggleMeterSettingsDialogAction.type: {
      return { ...state, newMeterValues: action.payload }
    }
    case AddDownlinkMessageSuccessAction.type: {
      if (state.newMeterValues === null) { return state }
      if (state.newMeterValues.id !== action.payload.deviceId) {
        return state
      } else {
        return {
          ...state,
          newMeterValues: {
            ...state.newMeterValues,
            pulseInLiters: action.payload.pulseWidth ?? state.newMeterValues.pulseInLiters,
            dataTransferInterval: action.payload.sendInterval ?? state.newMeterValues.dataTransferInterval,
            sendTemperature: action.payload.sendTemperature ?? state.newMeterValues.sendTemperature
          }
        }
      }
    }
    default: {
      return state
    }
  }
}
