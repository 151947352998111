import { type AnyAction } from 'redux'

import { type MeterTableState } from './meter-table-types'
import { compareTime, ON_TIME, stateWithLoadingFalse, stateWithLoadingTrue } from '../../utils/utils'

import moment from 'moment'

import { ToggleMeterSettingsDialogAction } from '../meters/meter-actions'
import {
  CloseRemoveMeterDialogAction,
  CustomerFilterChangedAction,
  MeterRemovedAction,
  NewMeterValuesUpdateSuccessAction,
  OpenRemoveMeterDialogAction,
  SetMeterActivePageAction,
  SetMeterPageSizeAction,
  ToggleDownloadDialogAction,
  SetCompanyAlarmDialogVisibilityAction,
  ToggleRemoveMeterConfirmedAction,
  UpdateLabelFilterChangedAction,
  UpdateOutOfDateMeterArrayAction, SetCompanyAlarmAction, FetchMetersAction, MetersReceivedAction, FetchMetersFailureAction
} from './meter-table-actions'

const initialState: MeterTableState = {
  loading: 0,
  outOfDateMeters: {},
  removeMeterDialogOpen: false,
  removeMeterConfirmed: false,
  meterSettingsDialogOpen: false,
  downloadDialogOpen: false,
  isSetCompanyAlarmDialogOpen: false,
  companyAlarm: { companyId: null, alarmType: null, state: false, maxFlowPerHour: undefined },
  meterActivePage: 1,
  meterPageSize: 10,
  newMeterValues: {},
  meterSaving: false,
  meterLocations: {}, // This is called in initial-state but not updated anywhere,
  expandedMeter: null // This is called in MetersTableRow but it is never set or updated
}

export default function meterTableReducer(state: MeterTableState = initialState, action: AnyAction): MeterTableState {
  if (ToggleDownloadDialogAction.match(action)) {
    return {
      ...state,
      downloadDialogOpen: !state.downloadDialogOpen
    }
  }
  if (SetMeterActivePageAction.match(action)) {
    const pageNumber = action.payload
    return {
      ...state,
      meterActivePage: pageNumber
    }
  }
  if (SetMeterPageSizeAction.match(action)) {
    const newPageSize = action.payload
    return {
      ...state,
      meterPageSize: newPageSize,
      meterActivePage: 1
    }
  }
  if (CustomerFilterChangedAction.match(action)) {
    return {
      ...state,
      meterActivePage: 1
    }
  }

  if (UpdateLabelFilterChangedAction.match(action)) {
    return {
      ...state,
      meterActivePage: 1
    }
  }
  if (UpdateOutOfDateMeterArrayAction.match(action)) {
    const outOfDatesMap = {}
    Object.keys(action.payload.values).forEach((key) => {
      if (action.payload.values[key] == null) {
        outOfDatesMap[key] = ON_TIME
      } else {
        outOfDatesMap[key] = compareTime(moment.unix(action.payload.values[key].time))
      }
    })
    return {
      ...state,
      outOfDateMeters: Object.assign({}, state.outOfDateMeters, outOfDatesMap)
    }
  }
  if (MeterRemovedAction.match(action)) {
    return {
      ...state,
      removeMeterConfirmed: false,
      removeMeterDialogOpen: false,
      meterSettingsDialogOpen: false
    }
  }
  if (ToggleRemoveMeterConfirmedAction.match(action)) {
    return {
      ...state,
      removeMeterConfirmed: !state.removeMeterConfirmed
    }
  }
  if (OpenRemoveMeterDialogAction.match(action)) {
    return {
      ...state,
      removeMeterConfirmed: false,
      removeMeterDialogOpen: true
    }
  }
  if (CloseRemoveMeterDialogAction.match(action)) {
    return {
      ...state,
      removeMeterConfirmed: false,
      removeMeterDialogOpen: false
    }
  }
  if (NewMeterValuesUpdateSuccessAction.match(action)) { // Remove this if this action is not used
    return {
      ...state,
      meterSettingsDialogOpen: false,
      meterSaving: false, // And if you remove this action, maybe meterSaving and newMeterValues are not needed in the state?
      newMeterValues: null
    }
  }
  if (ToggleMeterSettingsDialogAction.match(action)) {
    return {
      ...state,
      meterSettingsDialogOpen: !state.meterSettingsDialogOpen
    }
  }

  if (SetCompanyAlarmDialogVisibilityAction.match(action)) {
    return {
      ...state,
      isSetCompanyAlarmDialogOpen: action.payload
    }
  }

  if (SetCompanyAlarmAction.match(action)) {
    return {
      ...state,
      companyAlarm: action.payload
    }
  }

  if (FetchMetersAction.match(action)) {
    return stateWithLoadingTrue(state)
  }

  if (MetersReceivedAction.match(action)) {
    return stateWithLoadingFalse(state)
  }

  if (FetchMetersFailureAction.match(action)) {
    return stateWithLoadingFalse(state)
  }
  return state
}
