import { type AnyAction } from 'redux'
import { AlarmSettingsForDeviceResponseAction, ChangeAlarmSettingsAction, FetchAlarmSettingsForDeviceAction } from './alarm-settings-actions'
import { type AlarmSettingsState } from './alarm-settings-types'

export const initialState: AlarmSettingsState = {
  alarmSettings: {
    deviceId: '',
    tooBigFlowEnabled: false,
    backwardFlowEnabled: false,
    magneticFraudEnabled: false,
    opticalFraudEnabled: false,
    moduleRemovedEnabled: false,
    batteryWarningEnabled: false,
    leakageEnabled: false,
    hardwareErrorEnabled: false,
    switchErrorEnabled: false,
    resetDetectedEnabled: false,
    fraudDetectedEnabled: false,
    flowLimitEnabled: false,
    freezeDetectedEnabled: false,
    emptyTubeDetectedEnabled: false,
    maxFlowPerHour: 0
  }
}

export default function alarmSettingsReducer(state: AlarmSettingsState = initialState, action: AnyAction): AlarmSettingsState {
  if (ChangeAlarmSettingsAction.match(action)) {
    const newAlarmSettings = {
      ...state.alarmSettings,
      [action.payload.setting]: action.payload.value
    }
    return {
      ...state,
      alarmSettings: newAlarmSettings
    }
  }

  if (FetchAlarmSettingsForDeviceAction.match(action)) {
    return state
  }

  if (AlarmSettingsForDeviceResponseAction.match(action)) {
    return {
      ...state,
      alarmSettings: action.payload
    }
  }
  return state
}
