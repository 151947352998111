import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Add from '@mui/icons-material/Add'
import ActiveAlarmFilter from './ActiveAlarmFilter'
import { FiltersContainer, AddButtonContainer } from './Filters.style'
import { type RootState } from '../../../interfaces/RootState'
import type FilterOption from '../../../interfaces/FilterOption'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ListSubheader, Tooltip } from '@mui/material'
import ActiveLabelFilters from './ActiveLabelFilter'
import { UpdateLabelFilterChangedAction, AddActiveLabelAction, AddActiveFilterAction, SetAlarmsFilterAction } from '../../../redux/meter-table/meter-table-actions'
import { useTranslation } from 'react-i18next'

export const Filters = () => {
  const dispatch = useDispatch()

  const t = useTranslation().t

  const data = useSelector((state: RootState) => state.vesimittari.data)

  const [anchorEl, setAnchorEl] = React.useState<null | EventTarget & Element>(null)

  const handleMenuOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  /*
  NOTE - ADDING NEW FILTER TYPES
  The code for adding new filter types is fairly reusable, but adding new Filter type requires fixing of:
    - handleAlarmsFilterAdded() -> needs to be able to handle generic filters
    - ActiveAlarmFilter.tsx -> needs to be able to handle generic filters
  This requires some rewriting of the redux code, among other things
  */
  const filters: FilterOption[] = [
    {
      name: t('alarms_filtered')
    }
  ]

  const handleAlarmFilterAdded = (filterName: string) => {
    const elementToAdd = filters.find((i: FilterOption) => i.name === filterName) // Get the generic filter object
    handleMenuClose()
    dispatch(AddActiveFilterAction({ data: elementToAdd }))
    dispatch(SetAlarmsFilterAction({ data: true }))
  }

  // Get distinctive labels for filtering
  const labelArrays = data.meters.map(meter => meter.labels).filter(label => label.length)
  const labelOptions: any[] = []
  labelArrays.forEach(labelArray => labelOptions.push(...labelArray))

  const distinctLabels = labelOptions.filter((label, index, labelList) => labelList.indexOf(label) === index)
  const inactivelabelFilters = distinctLabels.filter((i) => !data.labelFilters.some((f) => f === i))

  const inactiveFilters: FilterOption[] = filters.filter((i: FilterOption) => !data.activeFilters.some((f: FilterOption) => f.name === i.name))

  const handleLabelFilterAdded = (filterName: string) => {
    const elementToAdd = distinctLabels.find((i) => i === filterName) // Get the filter object
    handleMenuClose()

    dispatch(AddActiveLabelAction({ data: elementToAdd }))
    dispatch(UpdateLabelFilterChangedAction({ filter: elementToAdd }))
  }

  const toolTipTitle = t('add_filter') ?? ''

  return (
    <FiltersContainer>
      <ActiveAlarmFilter />
      <ActiveLabelFilters/>
      <Tooltip title={toolTipTitle}>
        <AddButtonContainer aria-controls='filter-menu' aria-haspopup={true} onClick={handleMenuOpen} data-testid="add-filter-button">
          <Add style={{ color: '#ffffff', alignSelf: 'center', margin: 'auto', fontSize: '16px' }}/>
        </AddButtonContainer>
      </Tooltip>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {inactiveFilters.map((val) => (
          <MenuItem
            data-testid="handle-alarm-filter-added-menuitem"
            onClick={() => { handleAlarmFilterAdded(val.name) }}
            key={val.name}
            value={val.name}
          >
            {val.name}
          </MenuItem>
        ))}
        <ListSubheader>Merkintä</ListSubheader>
        {inactivelabelFilters.map((item, index) => (
          <MenuItem
            data-testid="handle-label-filter-added-menuitem"
            onClick={() => { handleLabelFilterAdded(item) }}
            key={index}
            value={item}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </FiltersContainer>
  )
}
