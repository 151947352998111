import { takeEvery, put, call, select, all } from 'redux-saga/effects'
import { AddDownlinkMessageSuccessAction, AddDownlinkMessageFailedAction, FetchDownlinkMessagesForDeviceFailAction, FetchDownlinkMessagesForDeviceSuccessAction, CancelDownlinkMessageFailedAction, CancelDownlinkMessageSuccessAction, FetchDownlinkMessagesForDeviceAction } from './downlink-messages-actions'
import { type AddDownlinkMessage, type CancelDownlinkMessage, type FetchDownlinkMessagesForDevice } from './downlink-messages-types'
import { ADD_DOWNLINK_MESSAGE, CANCEL_DOWNLINK_MESSAGE, FETCH_DOWNLINK_MESSAGES_FOR_DEVICE } from '../../constants/action-types'
import Api from '../../api/api'
import selectIdToken from '../../redux/selectors/select-id-token'
import { AddMessageAction } from '../messages/messages-actions'
import { MessageTypes } from '../messages/messages-types'
import createI18nInstance from '../../i18n'
import { retrieveLanguage } from '../../utils/languageStore'

export function * addDownlinkMessage(action: AddDownlinkMessage) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.DownlinkMessagesApi.addDownlinkMessage, idToken, action.payload)
  const i18n = createI18nInstance(retrieveLanguage())

  if (response.error) {
    yield put(AddDownlinkMessageFailedAction(response.error))
    yield put(AddMessageAction(response.error, MessageTypes.error))
  } else {
    yield put(AddDownlinkMessageSuccessAction(action.payload))
    yield put(FetchDownlinkMessagesForDeviceAction({ deviceId: action.payload.deviceId }))
    yield put(AddMessageAction(i18n.t('downlink_messages.queued'), MessageTypes.success))
  }
}

export function * cancelDownlinkMessage(action: CancelDownlinkMessage) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.DownlinkMessagesApi.cancelDownlinkMessage, idToken, action.payload)

  if (response.error) {
    yield put(CancelDownlinkMessageFailedAction(response.error))
    yield put(AddMessageAction(response.error, MessageTypes.error))
  } else {
    yield put(CancelDownlinkMessageSuccessAction(action.payload))
    yield put(FetchDownlinkMessagesForDeviceAction({ deviceId: action.payload.deviceId }))
  }
}

export function * fetchDownlinkMessagesForDevice(action: FetchDownlinkMessagesForDevice) {
  const idToken = yield select(selectIdToken)
  const response = yield call(Api.DownlinkMessagesApi.fetchDownlinkMessagesForDevice, idToken, action.payload)

  if (response.error) {
    yield put(FetchDownlinkMessagesForDeviceFailAction(response.error))
    yield put(AddMessageAction(response.error, MessageTypes.error))
  } else {
    yield put(FetchDownlinkMessagesForDeviceSuccessAction({
      deviceId: action.payload.deviceId,
      sentMessages: response.sentMessages,
      queuedMessages: response.queuedMessages
    }))
  }
}

export function * addDownlinkMessageStart() {
  yield takeEvery(ADD_DOWNLINK_MESSAGE, addDownlinkMessage)
}

export function * cancelDownlinkMessageStart() {
  yield takeEvery(CANCEL_DOWNLINK_MESSAGE, cancelDownlinkMessage)
}

export function * fetchDownlinkMessagesForDeviceStart() {
  yield takeEvery(FETCH_DOWNLINK_MESSAGES_FOR_DEVICE, fetchDownlinkMessagesForDevice)
}

export function * downlinkMessageSagas() {
  yield all([
    call(addDownlinkMessageStart),
    call(fetchDownlinkMessagesForDeviceStart),
    call(cancelDownlinkMessageStart)
  ])
}
