import React, { useCallback, useEffect } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'

import {
  CheckboxInputContainer,
  FormContainer,
  FormFieldsContainer,
  FormFieldTitle,
  FormHeader,
  FormInput,
  FormInputContainer
} from './CustomersView.style'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import {
  MenuItem,
  Menu,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material'

import SaveIcon from '@mui/icons-material/Save'
import { API_URL } from '../../../constants/urls'
import {
  CreateCompanyAction,
  DeleteCompanyAction,
  OpenEditCompanyDialogAction,
  UpdateCompanyAction
} from '../../../redux/companies/companies-actions'
import CustomerUsers from './CustomerUsers'
import { useTranslation } from 'react-i18next'

export function CustomersDetails({
  editedOrganization,
  notAssignedUsersCompany,
  dispatch
}: {
  editedOrganization: any
  notAssignedUsersCompany: any
  dispatch: any
}): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [editing, setEditing] = React.useState<boolean>(false)
  const [companyName, setCompanyName] = React.useState('')
  const [companyCity, setCompanyCity] = React.useState('')
  const [companyPostalCode, setCompanyPostalCode] = React.useState('')
  const [companyStreetAddress, setCompanyStreetAddress] = React.useState('')
  const [companyBusinessNumber, setCompanyBusinessNumber] = React.useState('')
  const [companyAsId, setCompanyAsId] = React.useState('')
  const [companyLrcAsKey, setCompanyLrcAsKey] = React.useState('')
  const [companyCanUpdateInterval, setCompanyCanUpdateInterval] =
    React.useState(false)
  const [companyPostalDistrict, setCompanyPostalDistrict] = React.useState('')

  const validHexRe = /[0-9a-fA-F]{32}/g

  const t = useTranslation().t

  const isCompanySelected = () =>
    editedOrganization.id != null && editedOrganization.id !== ''
  const isCreatingNewCompany = useCallback(() => {
    return editedOrganization.id === '' && editedOrganization.newCompany
  }, [editedOrganization])

  useEffect(() => {
    const eo = editedOrganization
    setCompanyName(eo.name ? eo.name : '')
    setCompanyCity(eo.town ? eo.town : '')
    setCompanyPostalCode(eo.postalCode ? eo.postalCode : '')
    setCompanyStreetAddress(eo.streetAddress ? eo.streetAddress : '')
    setCompanyBusinessNumber(eo.vatId ? eo.vatId : '')
    setCompanyAsId(eo.asId ? eo.asId : '')
    setCompanyLrcAsKey(eo.lrcAsKey ? eo.lrcAsKey : '')
    setCompanyCanUpdateInterval(eo.canUpdateInterval)
    setCompanyPostalDistrict(eo.postalDistrict ? eo.postalDistrict : '')

    // Are we making a new company?
    setEditing(isCreatingNewCompany())
  }, [editedOrganization, isCreatingNewCompany])

  const lrcAsKeyValid = () => {
    validHexRe.lastIndex = 0
    return (
      companyLrcAsKey.length === 0 ||
      (companyLrcAsKey.length === 32 && validHexRe.test(companyLrcAsKey))
    )
  }

  // Validation for company saving comes here
  const canSaveCompany = (): boolean => {
    const canSave = companyName != null && companyName !== ''
    if (isCreatingNewCompany()) {
      return canSave
    } else if (editing) {
      return canSave && lrcAsKeyValid()
    }
    return false
  }

  const handleDetailsFormOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDetailsFormClose = () => {
    setAnchorEl(null)
  }

  const formatLrcAsKey = (key: string) => {
    if (key === null || key.length === 0) return ''
    const chunks = key.match(/.{1,2}/g)
    return chunks === null ? '' : chunks.join('-')
  }

  const generateNewLrcAsKey = () => {
    const charSet = '0123456789abcdef'
    let generated = ''

    for (let i = 0; i < 32; i++) {
      generated += charSet.charAt(Math.floor(Math.random() * charSet.length))
    }

    setCompanyLrcAsKey(generated)
  }

  const deleteCompanyPopup = (company: any) => {
    // This typecast is weird, as t() definitely returns a string here,
    // but for some reason the compiler won't accept it in this specific situation
    if (
      window.confirm(t('settings_dialog.delete_confirmation_short') as string)
    ) {
      dispatch(DeleteCompanyAction({ companyId: company.id }))
    }
  }

  const saveCompany = () => {
    if (isCreatingNewCompany()) {
      dispatch(
        CreateCompanyAction({
          data: {
            name: companyName,
            town: companyCity,
            postalCode: companyPostalCode,
            streetAddress: companyStreetAddress,
            vatId: companyBusinessNumber,
            postalDistrict: companyPostalDistrict
          }
        })
      )
    } else {
      dispatch(
        UpdateCompanyAction({
          id: editedOrganization.id,
          data: {
            ...editedOrganization,
            name: companyName,
            town: companyCity,
            postalCode: companyPostalCode,
            streetAddress: companyStreetAddress,
            vatId: companyBusinessNumber,
            asId: companyAsId,
            lrcAsKey: companyLrcAsKey,
            canUpdateInterval: companyCanUpdateInterval,
            postalDistrict: companyPostalDistrict
          }
        })
      )
    }

    // Clear all fields
    dispatch(OpenEditCompanyDialogAction({ company: {} }))
    setEditing(false)
  }

  return (
    <FormContainer id={editedOrganization.id}>
      <FormHeader>
        <FormFieldTitle>
          {t('customer_details.company_details')}{' '}
          {editing ? `(${t('customer_details.not_saved')}!)` : ''}
        </FormFieldTitle>
        {(isCreatingNewCompany() || editing) && (
          <Button onClick={saveCompany} disabled={!canSaveCompany()}>
            <SaveIcon color="action" />
          </Button>
        )}
        <Button onClick={handleDetailsFormOpen} aria-label="edit">
          <SettingsIcon color="action" />
        </Button>
        <Menu
          id="customers-details-form-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleDetailsFormClose}
        >
          <MenuItem onClick={() => { setEditing(true) }}>{t('edit')}</MenuItem>
          <MenuItem onClick={() => { deleteCompanyPopup(editedOrganization) }}>
            {t('customer_details.delete_company')}
          </MenuItem>
        </Menu>
      </FormHeader>
      <FormFieldsContainer>
        <FormInputContainer>
          <label htmlFor="name"> {t('customer_details.company_name')}</label>
          <FormInput
            id="name"
            name="name"
            type="text"
            variant="outlined"
            value={companyName}
            InputProps={{ disabled: !editing }}
            error={isCreatingNewCompany() && !companyName}
            onChange={(e) => { setCompanyName(e.target.value) }}
          />
        </FormInputContainer>
        <FormInputContainer>
          <label htmlFor="business-number">
            {t('customer_details.business_number')}
          </label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="business-number"
            id="business-number"
            value={companyBusinessNumber}
            onChange={(e) => { setCompanyBusinessNumber(e.target.value) }}
          />
        </FormInputContainer>
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormInputContainer>
          <label htmlFor="postal-code">
            {t('customer_details.postal_code')}
          </label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="postal-code"
            id="postal-code"
            value={companyPostalCode}
            onChange={(e) => { setCompanyPostalCode(e.target.value) }}
          />
        </FormInputContainer>
        <FormInputContainer>
          <label htmlFor="postal-district">
            {t('customer_details.postal_district')}
          </label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="postal-district"
            id="postal-district"
            value={companyPostalDistrict}
            onChange={(e) => { setCompanyPostalDistrict(e.target.value) }}
          />
        </FormInputContainer>
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormInputContainer>
          <label htmlFor="street-address">
            {t('customer_details.street_address')}
          </label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="street-address"
            id="street-address"
            value={companyStreetAddress}
            onChange={(e) => { setCompanyStreetAddress(e.target.value) }}
          />
        </FormInputContainer>
        <FormInputContainer>
          <label htmlFor="city">{t('customer_details.city')}</label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="city"
            id="city"
            value={companyCity}
            onChange={(e) => { setCompanyCity(e.target.value) }}
          />
        </FormInputContainer>
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormInputContainer>
          <label htmlFor="email">{t('customer_details.email_address')}</label>
          <FormInput
            variant="outlined"
            InputProps={{ disabled: !editing }}
            type="text"
            name="email"
            id="email"
          />
        </FormInputContainer>
      </FormFieldsContainer>
      <Divider />
      <CustomerUsers
        editedOrganization={editedOrganization}
        notAssignedUsersCompany={notAssignedUsersCompany}
      />
      <Divider />
      {isCompanySelected() && (
        <div>
          <div>
            <FormFieldTitle>{t('customer_details.sigfox_info')}</FormFieldTitle>
            <FormInputContainer>
              <label htmlFor="sigfox_url">{t('customer_details.sigfox_url')}</label>
              <FormInput
                variant="outlined"
                InputProps={{ disabled: true }}
                type="text"
                name="sigfox_url"
                id="sigfox_url"
                value={`${API_URL}/incoming?company=${editedOrganization.id}`}
              />
            </FormInputContainer>
          </div>
          <div>
            <FormFieldTitle>{t('customer_details.lora_info')}</FormFieldTitle>
            <FormInputContainer>
              <label htmlFor="lora_url">{t('customer_details.lora_url')}</label>
              <FormInput
                variant="outlined"
                InputProps={{ disabled: true }}
                type="text"
                name="lora_url"
                id="lora_url"
                value={`${API_URL}/incoming/lora?company=${editedOrganization.id}`}
              />
            </FormInputContainer>
            <FormInputContainer>
              <label htmlFor="as_id">AS ID</label>
              <FormInput
                variant="outlined"
                InputProps={{ disabled: !editing }}
                type="text"
                name="as_id"
                id="as_id"
                value={companyAsId}
                onChange={(e) => { setCompanyAsId(e.target.value) }}
              />
            </FormInputContainer>
            <FormInputContainer>
              <label htmlFor="lrc_as_key">LRC-AS KEY</label>
              <FormInput
                variant="outlined"
                InputProps={{ disabled: !editing }}
                type="text"
                name="lrc_as_key"
                id="lrc_as_key"
                value={formatLrcAsKey(companyLrcAsKey)}
                onChange={(e) => { setCompanyLrcAsKey(e.target.value.replace(/-/g, '')) }
                }
                error={!lrcAsKeyValid()}
                helperText={!lrcAsKeyValid() ? 'Invalid key!' : ''}
              />
              <Button
                variant="contained"
                disabled={!editing}
                onClick={generateNewLrcAsKey}
              >
                Generoi uusi
              </Button>
            </FormInputContainer>
            <CheckboxInputContainer>
              <FormControlLabel
                label="Can change update interval"
                control={
                  <Checkbox
                    disabled={!editing}
                    checked={companyCanUpdateInterval || false}
                    onChange={(e, checked) => {
                      setCompanyCanUpdateInterval(checked)
                      e.target.checked = checked
                    }}
                  />
                }
              />
            </CheckboxInputContainer>
          </div>
        </div>
      )}
    </FormContainer>
  )
}

export default function ConnectedCustomerDetails(): JSX.Element {
  const editedOrganization = useSelector(
    (state: RootState): any => state.vesimittari.companies.editedOrganization
  )

  const companies = useSelector(
    (state: RootState) => state.vesimittari.admin.companies
  )
  const notAssignedUsersCompany = companies.find(
    (i) => i.name === 'NOT_ASSIGNED_USERS'
  )

  return (
    <CustomersDetails
      editedOrganization={editedOrganization}
      notAssignedUsersCompany={notAssignedUsersCompany}
      dispatch={useDispatch()}
    />
  )
}
