import { all, call } from 'redux-saga/effects'

import { authSagas } from './auth/auth-sagas'
import { downlinkMessageSagas } from './downlink-messages/downlink-messages-sagas'
import { mainMetersSagas } from './main-meters/main-meters-sagas'
import alarmSettingsSagas from './alarm-settings/alarm-settings-sagas'
import { meterSagas } from './meters/meter-saga'
import { meterDataSagas } from './meter-data/meter-data-sagas'
import companiesSagas from './companies/companies-sagas'
import adminSagas from './admin/admin-sagas'
import meterTableSagas from './meter-table/meter-table-sagas'
import noticesSagas from './notices/notices-saga'
import settingsSagas from './settings/settings-sagas'
import reportsSagas from './reports/reports-sagas'

export default function * rootSaga() {
  yield all([
    call(authSagas),
    call(downlinkMessageSagas),
    call(alarmSettingsSagas),
    call(mainMetersSagas),
    call(meterSagas),
    call(companiesSagas),
    call(meterDataSagas),
    call(adminSagas),
    call(meterTableSagas),
    call(noticesSagas),
    call(settingsSagas),
    call(reportsSagas)
  ])
}
