import React from 'react'
import { type AddDownlinkMessage, DownlinkMessageType } from '../../../redux/downlink-messages/downlink-messages-types'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { ADD_DOWNLINK_MESSAGE } from '../../../constants/action-types'
import { StyledFieldsText } from './MeterConfiguration.style'
import { meterSupportsDownlinkMessage } from '../../../utils/meter-utils'
import { DownlinkActionButtonAndDialog } from './DownlinkActionButtonAndDialog'
import { useTranslation } from 'react-i18next'

export function ResetMeterCounterButton({ newMeterValues, dispatch, enabled }) {
  const t = useTranslation().t
  const setSendResetCounter = (meterId: string) => {
    const resetCounterMsg: AddDownlinkMessage = {
      type: ADD_DOWNLINK_MESSAGE,
      payload: {
        type: DownlinkMessageType.RESET_READING,
        deviceId: meterId
      }
    }

    dispatch(resetCounterMsg)
  }

  return (
    <div>
      <StyledFieldsText>
        {`${t('settings_dialog.downlink_messages.reset_meter_counter')} ${!enabled ? t('settings_dialog.downlink_messages.unsupported_type') : ''}`}
      </StyledFieldsText>
      <DownlinkActionButtonAndDialog
        enabled={enabled}
        actionText={t('settings_dialog.downlink_messages.reset_alarms_action')}
        confirmDialogText={t('settings_dialog.reset_alarms_confirmation')}
        sendDownlinkMessage={() => { setSendResetCounter(newMeterValues.id) }}
      />
    </div>
  )
}

export default function ConnectedResetMeterCounterButton() {
  const dispatch = useDispatch()
  const newMeterValues = useSelector((state: RootState): any => state.vesimittari.meterReducer.newMeterValues)
  const enabled = meterSupportsDownlinkMessage(newMeterValues.type, DownlinkMessageType.RESET_READING)

  return (<ResetMeterCounterButton dispatch={dispatch} newMeterValues={newMeterValues} enabled={enabled} />)
}
