import React from 'react'
import { type AddDownlinkMessage, DownlinkMessageType } from '../../../redux/downlink-messages/downlink-messages-types'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { ADD_DOWNLINK_MESSAGE } from '../../../constants/action-types'
import { SetDownlinkExtraPayload } from './SetDownlinkExtraPayload'
import { meterSupportsDownlinkMessage } from '../../../utils/meter-utils'
import { StyledFieldsText } from './MeterConfiguration.style'
import { useTranslation } from 'react-i18next'

export function SetSendInterval({ dispatch, newMeterValue, enabled }) {
  const t = useTranslation().t

  const setSendInterval = (value: number) => {
    const sendIntervalMsg: AddDownlinkMessage = {
      type: ADD_DOWNLINK_MESSAGE,
      payload: {
        type: DownlinkMessageType.SET_SEND_INTERVAL,
        deviceId: newMeterValue.id,
        sendInterval: value
      }
    }

    dispatch(sendIntervalMsg)
  }
  return (
    <div>
      <StyledFieldsText>
        {`${t('settings_dialog.downlink_messages.set_send_interval')} ${!enabled ? t('settings_dialog.downlink_messages.unsupported_type') : ''}`}
      </StyledFieldsText>
      <div>
        <SetDownlinkExtraPayload
          enabled={enabled}
          currentValue={newMeterValue.dataTransferInterval}
          valueOptions={[
            { value: 6, label: '6h' },
            { value: 12, label: '12h' },
            { value: 24, label: '24h' }
          ]}
          valueText={''}
          setValueText={t('settings_dialog.downlink_messages.set_send_interval_action')}
          confirmText={t('settings_dialog.set_send_interval_confirmation')}
          setSendDownlinkAction={setSendInterval}
        />
      </div>
    </div>
  )
}

export default function ConnectedSetSendInterval() {
  const dispatch = useDispatch()
  const newMeterValues = useSelector((state: RootState): any => state.vesimittari.meterReducer.newMeterValues)
  const enabled = meterSupportsDownlinkMessage(newMeterValues.type, DownlinkMessageType.SET_SEND_INTERVAL)

  return (<SetSendInterval dispatch={dispatch} newMeterValue={newMeterValues} enabled={enabled} />)
}
