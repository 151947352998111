import React from 'react'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, type Theme } from '@mui/material'
import '../../assets/App.css'
import '../../assets/Mobile.css'
import '../../assets/index.css'
import { Provider } from 'react-redux'
import ErrorToast from '../common/error-toast/ErrorToast'
import { Route, Routes } from 'react-router-dom'
import Landing from '../pages/landing/Landing'
import Login from '../pages/login/Login'
import Authorized from '../common/authorized/Authorized'
import { type VesimittariStore } from '../../configureStore'
import Toasts from '../common/toast/Toasts'
import { I18nextProvider } from 'react-i18next'
import { type i18n } from 'i18next'
import MetersView from '../meter/meters-view/MetersView'
import Settings from '../common/settings/Settings'
import Integrations from '../pages/integration/Integrations'
import CustomersView from '../pages/customers/CustomersView'
import DevicesForProvision from '../pages/devices-for-provision/DevicesForProvision'
import ConnectedNoticesView from '../pages/notices'
import Spinner from '../common/spinner/Spinner'
import Dashboard from './Dashboard'

export default function Application({ store, theme, i18n }: { store: VesimittariStore, theme: Theme, i18n: i18n }): JSX.Element {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ErrorToast />
            <Toasts />
            <Spinner />
            <Routes>
              <Route path='/login' element={
                <Authorized props={{ requireIsAuthenticated: false, requireIsAssignedToCompany: false, requireIsAdmin: false }} child={<Login />} />
              }>
              </Route>
              <Route path='/landing' element={
                <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: false, requireIsAdmin: false }} child={<Landing />} />
              }>
              </Route>
              <Route path='/dashboard' element={
                <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<Dashboard />} />
              }>
                <Route path='meters' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<MetersView />} />
                } />
                <Route path='settings' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<Settings />} />
                } />
                <Route path='integrations' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<Integrations />} />
                } />
                <Route path='customers' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<CustomersView />} />
                } />
                <Route path='devices_for_provision' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<DevicesForProvision />} />
                } />
               <Route path='notices' element={
                  <Authorized props={{ requireIsAuthenticated: true, requireIsAssignedToCompany: true, requireIsAdmin: false }} child={<ConnectedNoticesView />} />
                } />
              </Route>
              <Route path='/*' element={
                <Authorized props={{ requireIsAuthenticated: false, requireIsAssignedToCompany: false, requireIsAdmin: false }} child={<Login />} />
              }>
              </Route>
            </Routes>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>
    </Provider>
  )
}
