import { API_URL } from '../constants/urls'
import { type FetchDataArrayPayload, type FetchDataPayload, type FetchMeterHistoryValuesPayload } from '../redux/meter-data/meter-data-types'

export default class MeterDataApi {
  static async fetchDataArray<T>(idToken: string, payload: FetchDataArrayPayload): Promise<T> {
    return await fetch(`${API_URL}/device/dataForIds`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async fetchData<T>(idToken: string, payload: FetchDataPayload): Promise<T> {
    return await fetch(`${API_URL}/device/${payload.id}/data?latest=true`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }

  static async fetchMeterHistoryValues<T>(idToken: string, payload: FetchMeterHistoryValuesPayload): Promise<T> {
    return await fetch(`${API_URL}/device/${payload.id}/getHistory?start=${payload.start.unix()}&end=${payload.end.unix()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(async response => await response.json())
      .catch(error => ({ error: error.message }))
  }
}
