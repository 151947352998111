import React, { useState } from 'react'
import {
  MetersHeader,
  MetersViewContainer,
  MainMetersActionButton,
  GenerateTransferFileDialogActionButton,
  DownloadActiveMetersReportButton,
  SetCompanyAlarmButton
} from './MetersView.style'
import MetersTable from '../meters-table/MetersTable'
import SearchBar from '../../common/search-bar/SearchBar'
import { Filters } from '../filters/Filters'
import ConnectedCustomerFilter from '../filters/CustomerFilter'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import MainMeters from '../main-meters/MainMeters'
import GenerateTransferFileDialog from '../../meter/GenerateTransferFileDialog'
import KokaLogo from '../../../assets/koka_logo.png'

import viewMainMetersIcon from '../../../assets/icon-view-main-meters.svg'
import generateTransferFileDialogIcon from '../../../assets/icon-save.svg'
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms'
import { ToggleMainMetersModalAction } from '../../../redux/main-meters/main-meters-actions'
import {
  SetMeterActivePageAction,
  SearchStringChangedAction,
  ToggleDownloadDialogAction,
  SetCompanyAlarmDialogVisibilityAction,
  SearchMetersDataAction
} from '../../../redux/meter-table/meter-table-actions'
import { useTranslation } from 'react-i18next'
import { DownloadActiveMetersAction } from '../../../redux/reports/reports-actions'
import SetCompanyAlarmDialog from '../set-company-alarm-dialog/SetCompanyAlarmDialog'

export default function MetersView() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isAdmin = useSelector((state: RootState) => state.vesimittari.auth.isAdmin)
  const isMainMetersModalOpen = useSelector((state: RootState) => state.vesimittari.mainMetersReducer.mainMetersModalOpen)
  const [searchString, setSearchString] = useState('')
  const [searchTextTimeout, setSearchTextTimeout] = useState<number | any>(null)

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value)
    clearTimeout(searchTextTimeout)
    setSearchTextTimeout(setTimeout(() => {
      dispatch(SearchStringChangedAction(e.target.value))
      dispatch(SetMeterActivePageAction(1))
      dispatch(SearchMetersDataAction(e.target.value))
    }, 500))
  }

  const mainMetersModalHandler = () => {
    dispatch(ToggleMainMetersModalAction(!isMainMetersModalOpen))
  }

  const setCompanyAlarmDialogHandler = () => {
    dispatch(SetCompanyAlarmDialogVisibilityAction(true))
  }

  return (
    <MetersViewContainer>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px' }}>
        <MetersHeader>
          <img style={{ height: '30px', marginBottom: '-5px' }} src={KokaLogo} alt="hydronet-logo" />
        </MetersHeader>
        <div>
          <SetCompanyAlarmButton
            startIcon={<AccessAlarmsIcon />}
            onClick={() => { setCompanyAlarmDialogHandler() }}
            data-testid='set-company-alarm-button'
          >
            {t('edit_alarms')}
          </SetCompanyAlarmButton>
          {isAdmin && (<DownloadActiveMetersReportButton
            startIcon={<img src={generateTransferFileDialogIcon} alt='download-active-meters-report' />}
            onClick={() => dispatch(DownloadActiveMetersAction())}
          >
            {t('download_active_meters_report')}
          </DownloadActiveMetersReportButton>)}
          <GenerateTransferFileDialogActionButton
            startIcon={<img src={generateTransferFileDialogIcon} alt='generate-transfer-file' />}
            onClick={() => dispatch(ToggleDownloadDialogAction())}
          >
            {t('generate_file')}
          </GenerateTransferFileDialogActionButton>
          <MainMetersActionButton
            startIcon={<img src={viewMainMetersIcon} alt="view-main-meters" />}
            onClick={() => { mainMetersModalHandler() }}
          >
            {t('main_meters.view_main_water_meters')}
          </MainMetersActionButton>
        </div>
      </div>
      <MainMeters />
      <GenerateTransferFileDialog />
      <SetCompanyAlarmDialog />

      <div style={{ display: 'flex', alignItems: 'left', borderTop: 'solid 1px #edf2f9', padding: '10px' }}>
        <SearchBar onChange={onSearchTextChange} searchValue={searchString} />
        <Filters />
        {isAdmin && <ConnectedCustomerFilter />}
      </div>
      <MetersTable />
    </MetersViewContainer>
  )
}
