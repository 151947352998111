import { DownlinkMessageType } from '../redux/downlink-messages/downlink-messages-types'
import { DeviceType } from '../redux/meters/meter-types'

export const supportedDownlinkMessagesByType = new Map<DeviceType, DownlinkMessageType[]>([
  [
    DeviceType.ADEUNIS,
    [
      DownlinkMessageType.SET_SEND_INTERVAL,
      DownlinkMessageType.SET_PULSE_WIDTH
    ]
  ],
  [
    DeviceType.ADEUNIS_v3,
    []
  ],
  [
    DeviceType.BMETERS_COMBO,
    [
      DownlinkMessageType.RESET_ALARMS,
      DownlinkMessageType.SET_SEND_INTERVAL,
      DownlinkMessageType.SET_SEND_TEMPERATURE
    ]
  ],
  [
    DeviceType.BMETERS_LORA_PULSE,
    [
      DownlinkMessageType.SET_SEND_INTERVAL,
      DownlinkMessageType.RESET_ALARMS,
      DownlinkMessageType.RESET_READING,
      DownlinkMessageType.SET_PULSE_WIDTH
    ]
  ],
  [
    DeviceType.HYDROSONIC_M1,
    [
      DownlinkMessageType.RESET_ALARMS
    ]
  ],
  [
    DeviceType.BMETERS_v2,
    [
      DownlinkMessageType.RESET_ALARMS,
      DownlinkMessageType.SET_SEND_TEMPERATURE
    ]
  ],
  [
    DeviceType.LORA,
    [
      DownlinkMessageType.SET_SEND_INTERVAL,
      DownlinkMessageType.RESET_ALARMS,
      DownlinkMessageType.RESET_READING
    ]
  ],
  [
    DeviceType.SIGFOX,
    []
  ]
])
