import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { type Notice } from '../../../redux/notices/notices-types'
import NoticeRow from './NoticeRow'
import { useTranslation } from 'react-i18next'

interface Props {
  isAdmin: boolean
  notices: Notice[]
}

function NoticesList({ isAdmin, notices }: Props): JSX.Element {
  const { t } = useTranslation()
  const NoticeListHeader = () =>
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography>{t('notices.created')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('notices.contents')}</Typography>
        </TableCell>
        <TableCell>{/* Expand button */}</TableCell>
        <TableCell>{/* Delete button for admins */}</TableCell>
      </TableRow>
    </TableHead>

  return <Table>
    <NoticeListHeader />
    <TableBody>
      {[...notices] // Create a copy of the array which we then sort and map in order to not mutate the original array
        .sort((a, b) => a.created - b.created)
        .map((notice) => <NoticeRow isAdmin={isAdmin} notice={notice} key={notice.id} />)
      }
    </TableBody>
  </Table>
}

export default function ConnectedNoticesList(): JSX.Element {
  const isAdmin = useSelector((state: RootState) => state.vesimittari.auth.isAdmin)
  const notices = useSelector((state: RootState) => state.vesimittari.notices.notices)

  return <NoticesList notices={notices} isAdmin={isAdmin} />
}
