import React from 'react'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  IconButton
} from '@mui/material'
import Clear from '@mui/icons-material/Clear'

import { type QueuedMessage, type SentMessage } from '../../../redux/downlink-messages/downlink-messages-types'
import { TableCellBordered, TableHeadCellBordered } from '../../meter/meters-table/meters-table-row/MetersTableRow.style'
import { CancelDownlinkMessageAction } from '../../../redux/downlink-messages/downlink-messages-actions'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { unixTimeToLocalDateTimeString } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

const DownlinkMessages = ({ meterId }: { meterId: string }): JSX.Element => {
  const dispatch = useDispatch()

  const t = useTranslation().t

  const downlinkMessages = useSelector((state: RootState) => state.vesimittari.downlinkMessagesReducer.downlinkMessagesByDeviceId[meterId])

  const cancelDownlink = (id: string, index: number) => {
    if (window.confirm('Peruutetaanko viestin lähetys?')) {
      dispatch(CancelDownlinkMessageAction({ deviceId: id, index }))
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCellBordered>{'#'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Viesti'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Tila'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Lisätiedot'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Laitettu jonoon'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Lähetetty'}</TableHeadCellBordered>
            <TableHeadCellBordered>{'Peruuta'}</TableHeadCellBordered>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            downlinkMessages?.queuedMessages.map((msg: QueuedMessage, index: number) => (
              <TableRow key={index}>
                <TableCellBordered>{index + 1}</TableCellBordered>
                <TableCellBordered>{t(`downlink_messages.types.${msg.type}`)}</TableCellBordered>
                <TableCellBordered>{'Jonossa'}</TableCellBordered>
                <TableCellBordered></TableCellBordered>
                <TableCellBordered>{unixTimeToLocalDateTimeString(msg.queued)}</TableCellBordered>
                <TableCellBordered></TableCellBordered>
                <TableCellBordered>
                  <IconButton
                    title={'Peruuta'}
                    onClick={() => { cancelDownlink(meterId, index) }}
                    size="large">
                    <Clear />
                  </IconButton>
                </TableCellBordered>
              </TableRow>
            ))
          }
          {
            downlinkMessages?.sentMessages.map((msg: SentMessage, index: number) => (
              <TableRow key={index}>
                <TableCellBordered>{index + 1}</TableCellBordered>
                <TableCellBordered>{t(`downlink_messages.types.${msg.type}`)}</TableCellBordered>
                <TableCellBordered>{msg.success ? 'Lähetetty' : 'Virhe'}</TableCellBordered>
                <TableCellBordered>{msg.error}</TableCellBordered>
                <TableCellBordered>{unixTimeToLocalDateTimeString(msg.queued)}</TableCellBordered>
                <TableCellBordered>{unixTimeToLocalDateTimeString(msg.created)}</TableCellBordered>
                <TableCellBordered>
                </TableCellBordered>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DownlinkMessages
