import Warning from '@mui/icons-material/Warning'
import React from 'react'
import { TableRow, Tooltip } from '@mui/material'
import { TableCellBordered } from './MetersTableRow.style'
import {
  getDescTranslationKey,
  getNameTranslationKey,
  TEMPERATURE
} from '../../../../redux/alarm-settings/alarm-types'
import { useTranslation } from 'react-i18next'
import Check from '@mui/icons-material/Check'
import { type Alarm } from './types'
import { type AlarmSettingsForDevice } from '../../../../redux/alarm-settings/alarm-settings-types'

export function AlarmStatusIcon({
  alarms,
  alarmSettings
}: {
  alarms: Alarm[]
  alarmSettings: AlarmSettingsForDevice
}): JSX.Element {
  const t = useTranslation().t

  const alarmIsEnabled = (alarm: Alarm, alarmSettings: AlarmSettingsForDevice): boolean => {
    if (alarm.type.alarmKey === TEMPERATURE.alarmKey) {
      return true
    }

    if (alarmSettings === null || alarmSettings === undefined) {
      return false
    }

    return alarmSettings[alarm.type.enabledKey]
  }

  const enabledAlarms: Alarm[] = []

  alarms.forEach((a) => {
    if (alarmIsEnabled(a, alarmSettings)) {
      enabledAlarms.push(a)
    }
  })

  const atLeastOneAlarmIsActive = (): boolean => {
    return enabledAlarms.some(a => a.active)
  }

  const createNewAlarmStatusLine = (a: Alarm): string => {
    return (
      t(getNameTranslationKey(a.type)) +
            ':\n' +
            t(getDescTranslationKey(a.type)) +
            '\n\n'
    )
  }

  const getAlarmStatusTooltip = (activeAlarms: boolean): JSX.Element => {
    if (!activeAlarms) return <span>{t('no_alarms')}</span>
    let alarmStatus = ''
    enabledAlarms.forEach(
      (a) =>
        a.active && a.supported && (alarmStatus += createNewAlarmStatusLine(a))
    )

    return <span style={{ whiteSpace: 'pre-line' }}>{alarmStatus}</span>
  }

  const activeAlarms = atLeastOneAlarmIsActive()

  return (
        <Tooltip
            style={{ fontSize: '12em' }}
            arrow
            title={getAlarmStatusTooltip(activeAlarms)}
        >
            {atLeastOneAlarmIsActive()
              ? (
                    <Warning style={{ marginBottom: '-5px', color: '#BE366C' }}/>
                )
              : (
                    <Check style={{ fontSize: 'large', color: '#04D08D' }}/>
                )}
        </Tooltip>
  )
}

const warningIcon = (): React.JSX.Element => {
  return <Warning style={{ marginBottom: '-5px' }}/>
}
const getAlarmsStatusText = (supported: boolean, active: boolean): string => {
  if (supported) {
    if (active) {
      return 'Voimassa'
    } else {
      return 'Kuitattu'
    }
  }
  return 'Mittari ei tue tätä hälytystä'
}

export function AlarmsDetails(
  { alarm, index }:
  {
    alarm: Alarm
    index: number
  }
): JSX.Element {
  const t = useTranslation().t

  return (
        <TableRow
            style={{
              backgroundColor: alarm.active ? '#F7E7EE' : '#FFFFFF',
              opacity: !alarm.supported ? '0.38' : ''
            }}
        >
            <TableCellBordered>{index + 1}</TableCellBordered>
            <TableCellBordered>
                {alarm.active ? warningIcon() : ''}{' '}
                <span>{t(getNameTranslationKey(alarm.type))}</span>
            </TableCellBordered>
            <TableCellBordered>
                {t(getDescTranslationKey(alarm.type))}
            </TableCellBordered>
            <TableCellBordered>
                {getAlarmsStatusText(alarm.supported, alarm.active)}
            </TableCellBordered>
        </TableRow>
  )
}
