import styled from '@emotion/styled'
import { Chip, Select, TableCell, TableRow, TextField } from '@mui/material'

export const StyledHeaderCol = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'gray'
}) <{ gray: boolean }>`
    font-size: 16px;
    color: ${props => (props.gray ? '#808080' : '#000000')};
`

export const StyledRow = styled(TableRow)`
    border: 1px solid lightgray;
    margin-bottom: -1px;
    padding: 1em;
`

export const StyledChip = styled(Chip)`
    background-color: gray;
    border-radius: 6px;
    margin: .6em .6em 0 0;
    color: white;
`

export const StyledSelect = styled(Select)`
    border: 1px solid rgba(0, 0, 0, 23%);
    border-radius: .4em;
    padding: 0em 1em;
    width: 100%;
`

export const StyledTextField = styled(TextField)`
    margin-top: 0;
    input {
        padding: .55em;
    }
`
export const GridContainer = styled(TableCell)`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    border: none;
`
