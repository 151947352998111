import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { type RootState } from '../../../interfaces/RootState'
import { FetchLabelsWithMainMetersAction, ToggleMainMetersModalAction } from '../../../redux/main-meters/main-meters-actions'

import CloseIcon from '@mui/icons-material/Close'

import { ButtonContainer, StyledHeadTableCell } from './MainMeters.style'
import { parseCubicMeters } from '../../../utils/utils'
import AssociateLabel from './AssociateLabel'
import MainMeterRow from './MainMeterRow'
import { Add, Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

function MainMeters({ dispatch, mainMetersList, values, meters, isMainMetersModalOpen }) {
  const t = useTranslation().t
  const [showAssociateField, setShowAssociateField] = React.useState(false)
  const handleClose = () => {
    dispatch(ToggleMainMetersModalAction(false))
  }

  const OpenAssociateFieldButton = () => {
    return (
      <Tooltip title="Lisää päävesimittari merkintään" arrow>
        <ButtonContainer data-testid="add-main-meter-button">
          <Add/>
        </ButtonContainer>
      </Tooltip>
    )
  }

  const CloseAssociateFieldButton = () => {
    return (
      <ButtonContainer>
        <Close/>
      </ButtonContainer>
    )
  }

  const expandAddField = () => {
    setShowAssociateField(!showAssociateField)
  }
  useEffect(() => {
    dispatch(FetchLabelsWithMainMetersAction())
  }, [dispatch])

  const valuesSummary = {}
  const usedLabels = new Set()

  const mainMetersWithReadings = (mainMetersList || []).filter((el) => el.device)

  mainMetersWithReadings.forEach((mainMeter) => {
    valuesSummary[mainMeter.value] = 0
  })

  meters.forEach((item) => {
    if (item.labels && item.labels.length > 0) {
      const waterValue = values[item.id] ? (values[item.id].waterUsed) : 0
      item.labels.forEach((label) => {
        usedLabels.add(label)
        if (Object.prototype.hasOwnProperty.call(valuesSummary, label)) valuesSummary[label] = valuesSummary[label] + waterValue
      })
    }
  })

  const usedLabelsWithoutMainMeters = (mainMetersList || []).filter((m) => !m.device && usedLabels.has(m.value))

  return (
    <Dialog
      open={isMainMetersModalOpen}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        <b>{t('main_meters.main_water_meters')}</b>
        <IconButton
          style={{ position: 'absolute', right: '0', top: '0', color: 'black' }}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeadTableCell>{'#'}</StyledHeadTableCell>
              <StyledHeadTableCell>{t('main_meters.label')}</StyledHeadTableCell>
              <StyledHeadTableCell>{t('main_meters.main_meter_id')}</StyledHeadTableCell>
              <StyledHeadTableCell>{t('main_meters.reading')}</StyledHeadTableCell>
              <StyledHeadTableCell>{t('main_meters.reading_other')}</StyledHeadTableCell>
              <StyledHeadTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {mainMetersWithReadings.map((i, index) => {
              const waterValue = values[i.device.id] ? (values[i.device.id].waterUsed) : 0
              const otherReadings = valuesSummary[i.value] - waterValue
              return (
                <MainMeterRow id={i.id} labelsWithMainMeters={i} index={index + 1} value={i.value} meterSigfoxId={i.meterSigfoxId} waterValue={`${parseCubicMeters(waterValue)}`} otherReadings={`${parseCubicMeters(otherReadings)}`}/>
              )
            })}
          </TableBody>
        </Table>
        <DialogActions>
          <div onClick={() => { expandAddField() }} style={{ padding: '10px 0' }}>
            {showAssociateField ? <CloseAssociateFieldButton/> : <OpenAssociateFieldButton/>}
          </div>
        </DialogActions>
        <Divider/>
        {showAssociateField && <AssociateLabel labels={usedLabelsWithoutMainMeters} meters={meters} mainMetersList={mainMetersWithReadings} />}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  )
}

export default function ConnectedMainMeters() {
  const dispatch = useDispatch()
  const isMainMetersModalOpen = useSelector((state: RootState) => state.vesimittari.mainMetersReducer.mainMetersModalOpen)
  const mainWaterMetersList = useSelector((state: RootState) => state.vesimittari.mainMetersReducer.mainWaterMeters)
  const values = useSelector((state: RootState) => state.vesimittari.meterDataReducer.values)
  const meters = useSelector((state: RootState) => state.vesimittari.data.meters)

  return (<MainMeters dispatch={dispatch} isMainMetersModalOpen={isMainMetersModalOpen} mainMetersList={mainWaterMetersList} values={values} meters={meters} />)
}
